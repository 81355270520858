var config = {
    style: 'mapbox://styles/momolarson/ck3iw6oki1f1f1cqf6py73lpp',
    accessToken: 'pk.eyJ1IjoibW9tb2xhcnNvbiIsImEiOiJjaXN4Y3M3MXEwOHMzMm9wZ2V3Z2UzamlkIn0.RuHOg6NXCfPh346GMKOUEQ',
    showMarkers: false,
    theme: 'light',
    alignment: 'left',
    title: '',
    subtitle: '',
    byline: '',
    footer: 'Huttrips.com',
    chapters: [
        {
            id: 'polarstarinn',
            title: 'Polar Star Inn and Seipel Hut (Carl\'s Cabin)',
            image: '/img/polarstar.jpg',
            huttriplink: 'https://www.huttrip.com/2008/03/polar-star-hut.html',
            hutorglink: 'https://www.huts.org/The_Huts/polar_star.php',
            gpx: 'gpx/polarstarhut-data.gpx',
            data: {
                route: [
                    [-106.67851826, 39.503777576],
                    [-106.677984566, 39.504628152],
                    [-106.677914574, 39.504817167],
                    [-106.676348487, 39.50425687],
                    [-106.674563674, 39.503824831],
                    [-106.672901348, 39.503264525],
                    [-106.672201421, 39.502764972],
                    [-106.671956447, 39.502737969],
                    [-106.670670331, 39.502251914],
                    [-106.669769176, 39.502170905],
                    [-106.668631795, 39.501374306],
                    [-106.667966864, 39.500010616],
                    [-106.666339534, 39.499531293],
                    [-106.662813653, 39.49663503],
                    [-106.662699915, 39.496722797],
                    [-106.663574824, 39.499031713],
                    [-106.664362241, 39.500213146],
                    [-106.664379739, 39.500840985],
                    [-106.663198613, 39.500118632],
                    [-106.661492541, 39.499504288],
                    [-106.659086543, 39.499213992],
                    [-106.657642944, 39.498937197],
                    [-106.656978014, 39.498923695],
                    [-106.657083003, 39.499274752],
                    [-106.657424217, 39.499781081],
                    [-106.658911561, 39.501198783],
                    [-106.658990303, 39.50238693],
                    [-106.658727831, 39.503581807],
                    [-106.65901655, 39.505168194],
                    [-106.658290376, 39.506990807],
                    [-106.658202886, 39.509657137],
                    [-106.657879169, 39.510831639],
                    [-106.659007801, 39.512289614],
                    [-106.657800428, 39.511958873],
                    [-106.655945622, 39.511034137],
                    [-106.653347144, 39.51058189],
                    [-106.652419741, 39.51018364],
                    [-106.651553581, 39.509589636],
                    [-106.649681277, 39.508003349],
                    [-106.648403911, 39.507578084],
                    [-106.647800224, 39.507578084],
                    [-106.646365374, 39.5080236],
                    [-106.645674196, 39.508624368],
                    [-106.645822931, 39.509508635],
                    [-106.646032909, 39.509846138],
                    [-106.648841365, 39.512174867],
                    [-106.64860514, 39.512937593],
                    [-106.648806369, 39.513207583],
                    [-106.649935, 39.513383076],
                    [-106.650162477, 39.513538319],
                    [-106.649917502, 39.513815056],
                    [-106.649602535, 39.513943299],
                    [-106.649620033, 39.514098541],
                    [-106.649550041, 39.514172787],
                    [-106.649252572, 39.514159288],
                    [-106.648666383, 39.514584513],
                    [-106.648937605, 39.514658759],
                    [-106.649628782, 39.514726255],
                    [-106.650136229, 39.515137978],
                    [-106.650267466, 39.515374211],
                    [-106.650241218, 39.515407959],
                    [-106.649733772, 39.515326965],
                    [-106.649261321, 39.515617193],
                    [-106.649042594, 39.515677939],
                    [-106.648867612, 39.515637442],
                    [-106.648780121, 39.515536199],
                    [-106.648360165, 39.515448456],
                    [-106.648167685, 39.515677939],
                    [-106.648001453, 39.515731935],
                    [-106.647957707, 39.515826427],
                    [-106.648106442, 39.515900671],
                    [-106.648386412, 39.516366383],
                    [-106.649033845, 39.516703853],
                    [-106.64907759, 39.516865838],
                    [-106.648587641, 39.517689256],
                    [-106.648736376, 39.517905233],
                    [-106.64870138, 39.517999723],
                    [-106.6485089, 39.518215699],
                    [-106.6485089, 39.518566659],
                    [-106.648666383, 39.518991857],
                    [-106.648683881, 39.519525039],
                    [-106.647625242, 39.518661148],
                    [-106.64745026, 39.518573408],
                    [-106.647109046, 39.518310188],
                    [-106.646514108, 39.518161705],
                    [-106.642340795, 39.520233692],
                    [-106.641500883, 39.520382171],
                    [-106.641457137, 39.520314681],
                    [-106.641177167, 39.520334928],
                    [-106.640967189, 39.520544148],
                    [-106.64094969, 39.520699375],
                    [-106.640792207, 39.520760116],
                    [-106.640328505, 39.520773614],
                    [-106.640136025, 39.52073312],
                    [-106.639821058, 39.520746618],
                    [-106.639497342, 39.520658881],
                    [-106.639129881, 39.520490156],
                    [-106.638814914, 39.520503654],
                    [-106.637975002, 39.520564395],
                    [-106.637213831, 39.520976083],
                    [-106.636671388, 39.521070569],
                    [-106.635988959, 39.521482254],
                    [-106.63435288, 39.521711717],
                    [-106.633889179, 39.521887188]
                ],
                camera:   [
                    [-106.732992064849753, 39.565914701344177,4000],
                    [-106.677900849212833, 39.500443981311903,5000],
                    [-106.647880128807785, 39.483198035547296,3000],
                    [-106.677262110480811, 39.521522359468634,4000],
                    [-106.639257155925492, 39.534137449426069,4000],
                    [-106.625843642553022, 39.529825962984923,4000],
                    [-106.627600174066089, 39.518488350491531,5000],
                    [-106.636223146948382, 39.509226638877209,5000],
                    [-106.654906254860038, 39.515933395563437,4000],
                    [-106.648838236905817, 39.523757945030709,4000]
                ],
            },
            visible:true,
            description: 'The Polar Star Inn and Carl\'s Cabin are located near New York Mountain, and offer some of the finest tree skiing I\'ve been in, as well as an awesome place to go on a night trek. One of the nicer aspects of the Polar Star hut is the fact that they have well water to drink from. The westerly view from Polar Star as the sun sets in the Utah Desert is dramatic. For these reasons, these huts are some of my most frequently visited.<br />My personal preference is to hike up to the cabins using the Newcomer Spring Trail, which keeps you away from the snowmobile traffic up fulford road.  On the way down, I like to use Fulford road on the way down.',
            location: {
                center: [-106.66842, 39.51916],
                zoom: 14.50,
                pitch: 75.00,
                bearing: 75.60
            },
            onChapterEnter: [{
                layer: 'polar-star-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'polar-star-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'peterestinhut',
            title: 'Peter Estin Hut',
            image: '/img/estinhut.jpg',
            huttriplink: 'https://www.huttrip.com/2010/02/peter-estin-hut.html',
            hutorglink: 'https://www.huts.org/The_Huts/estin.php',
            gpx: 'gpx/estin-data.gpx',
            data: {
                route:                [
                    [-106.678360777, 39.50392609, 2763.286],
                    [-106.677879577, 39.50485092, 2769.559000000000196],
                    [-106.676400982, 39.504290623, 2769.659999999999854],
                    [-106.674738656, 39.503878836, 2782.781],
                    [-106.672875101, 39.503257775, 2787.237999999999829],
                    [-106.672157676, 39.502751471, 2786.264999999999873],
                    [-106.671947698, 39.502758222, 2789.909000000000106],
                    [-106.670644084, 39.502251914, 2796.454000000000178],
                    [-106.669646688, 39.502123649, 2807.005999999999858],
                    [-106.668693038, 39.50140806, 2802.989],
                    [-106.667905621, 39.49997011, 2802.089],
                    [-106.666313287, 39.499511039, 2819.984],
                    [-106.662953639, 39.496648533, 2832.985000000000127],
                    [-106.66255993, 39.496344722, 2840.972000000000207],
                    [-106.661343807, 39.494636606, 2852.935],
                    [-106.661090084, 39.494508327, 2856.297],
                    [-106.660915102, 39.494508327, 2859.813999999999851],
                    [-106.660460149, 39.49421801, 2866.036999999999807],
                    [-106.6604514, 39.493887182, 2862.552000000000135],
                    [-106.660092688, 39.49338081, 2864.739999999999782],
                    [-106.659602739, 39.492989213, 2874.433],
                    [-106.659392761, 39.492374807, 2872.739999999999782],
                    [-106.659200281, 39.492199261, 2872.764999999999873],
                    [-106.658614093, 39.491848168, 2872.210999999999785],
                    [-106.65796666, 39.491787402, 2874.188000000000102],
                    [-106.65796666, 39.491497074, 2872.75],
                    [-106.658054151, 39.491206744, 2874.607],
                    [-106.658281627, 39.490842142, 2875.322000000000116],
                    [-106.658246631, 39.490518051, 2876.739999999999782],
                    [-106.658124144, 39.490268229, 2878.927999999999884],
                    [-106.658071649, 39.49006567, 2879.184000000000196],
                    [-106.658132893, 39.48986311, 2877.338000000000193],
                    [-106.658036653, 39.489208164, 2874.33],
                    [-106.657756682, 39.489086627, 2876.527999999999793],
                    [-106.657844173, 39.488776031, 2877.190999999999804],
                    [-106.657651693, 39.488559963, 2882.5300000000002],
                    [-106.657546704, 39.487837483, 2896.728999999999814],
                    [-106.657616697, 39.487709191, 2897.867000000000189],
                    [-106.657485461, 39.487358076, 2904.185],
                    [-106.657214239, 39.487162261, 2910.585999999999785],
                    [-106.657161744, 39.486844904, 2912.444],
                    [-106.656969265, 39.486372242, 2921.353999999999814],
                    [-106.657170494, 39.485886072, 2926.175000000000182],
                    [-106.657249235, 39.485656491, 2931.902],
                    [-106.657450464, 39.485433662, 2941.512000000000171],
                    [-106.658080398, 39.485291861, 2961.492000000000189],
                    [-106.657747933, 39.484940733, 2963.498999999999796],
                    [-106.657564202, 39.48493398, 2963.826],
                    [-106.65730173, 39.484684139, 2976.458999999999833],
                    [-106.657214239, 39.484616614, 2978.746999999999844],
                    [-106.656794283, 39.484542336, 2978.138],
                    [-106.656759287, 39.484441048, 2982.859],
                    [-106.655875629, 39.484393781, 2988.351000000000113],
                    [-106.655788138, 39.484468058, 2989.723],
                    [-106.655315688, 39.484353265, 3003.547],
                    [-106.655333186, 39.484110174, 3008.145],
                    [-106.655315688, 39.483799556, 3020.004],
                    [-106.655219448, 39.483678009, 3029.783],
                    [-106.655210699, 39.483603731, 3033.129],
                    [-106.655446924, 39.483441668, 3031.458999999999833],
                    [-106.655324437, 39.483110789, 3043.023000000000138],
                    [-106.655210699, 39.483016252, 3046.186999999999898],
                    [-106.655114459, 39.482725887, 3050.248999999999796],
                    [-106.655376931, 39.482455779, 3052.536999999999807],
                    [-106.655333186, 39.482367994, 3055.259],
                    [-106.655429426, 39.48225995, 3055.847000000000207],
                    [-106.655569411, 39.482246445, 3056.614],
                    [-106.655263193, 39.482104637, 3061.045999999999822],
                    [-106.655061964, 39.482084379, 3066.181999999999789],
                    [-106.65500947, 39.482043862, 3068.35],
                    [-106.655306939, 39.48198984, 3061.447999999999865],
                    [-106.655438175, 39.482023604, 3060.242000000000189],
                    [-106.655560662, 39.48198984, 3061.672],
                    [-106.655315688, 39.481638696, 3067.681999999999789],
                    [-106.655131957, 39.481463123, 3071.56],
                    [-106.655123208, 39.48128755, 3075.02599999999984],
                    [-106.655254444, 39.481159246, 3079.402999999999793],
                    [-106.65538568, 39.481118729, 3081.744999999999891],
                    [-106.655893127, 39.481220022, 3083.184000000000196],
                    [-106.656365578, 39.481152493, 3090.255000000000109],
                    [-106.656514312, 39.481084965, 3093.820000000000164],
                    [-106.65653181, 39.480916144, 3096.762000000000171],
                    [-106.656409323, 39.480402924, 3103.079000000000178],
                    [-106.656138102, 39.47990996, 3108.197000000000116],
                    [-106.656006865, 39.479801912, 3108.965000000000146],
                    [-106.65586688, 39.479788406, 3107.648000000000138],
                    [-106.655823135, 39.479700618, 3109.213000000000193],
                    [-106.655919374, 39.479572311, 3113.449999999999818],
                    [-106.656146851, 39.479444004, 3124.915],
                    [-106.656120603, 39.479329203, 3129.101999999999862],
                    [-106.656024363, 39.479160377, 3131.877],
                    [-106.655814385, 39.478971292, 3133.456000000000131],
                    [-106.655691898, 39.478809219, 3135.909999999999854],
                    [-106.655648153, 39.478599873, 3142.054],
                    [-106.655516917, 39.478491824, 3144.203],
                    [-106.655525666, 39.478390527, 3147.110000000000127],
                    [-106.655875629, 39.478255465, 3155.862999999999829],
                    [-106.655840633, 39.478133909, 3158.603],
                    [-106.655613156, 39.477978586, 3157.561999999999898],
                    [-106.655429426, 39.477944821, 3154.277],
                    [-106.654790743, 39.477053399, 3158.417],
                    [-106.654886982, 39.476965607, 3163.253000000000156],
                    [-106.655166953, 39.47697236, 3171.425000000000182],
                    [-106.655254444, 39.477046646, 3171.556],
                    [-106.655210699, 39.476607684, 3186.012000000000171],
                    [-106.655070713, 39.476472618, 3185.388],
                    [-106.6551932, 39.476378072, 3192.427999999999884],
                    [-106.654921979, 39.475999886, 3193.962],
                    [-106.655053215, 39.475871573, 3201.56],
                    [-106.655368182, 39.475858066, 3212.726000000000113],
                    [-106.655368182, 39.475682479, 3216.505000000000109],
                    [-106.65510571, 39.475459617, 3211.744999999999891],
                    [-106.654843237, 39.474872069, 3210.847000000000207],
                    [-106.654781993, 39.474433094, 3216.340000000000146],
                    [-106.654851986, 39.474142693, 3225.77599999999984],
                    [-106.654878233, 39.473507859, 3244.366],
                    [-106.654668255, 39.473305252, 3245.746],
                    [-106.654318292, 39.473109397, 3250.226000000000113],
                    [-106.654318292, 39.472771715, 3256.1],
                    [-106.654248299, 39.472366495, 3263.429999999999836],
                    [-106.654274547, 39.472184145, 3268.701],
                    [-106.654475775, 39.471947765, 3280.118],
                    [-106.654484525, 39.471805936, 3283.364],
                    [-106.654589514, 39.471677615, 3287.167],
                    [-106.654615761, 39.470948205, 3293.257999999999811],
                    [-106.654327041, 39.470718574, 3294.148999999999887],
                    [-106.654029572, 39.470144494, 3302.853],
                    [-106.654134561, 39.469962139, 3305.304999999999836],
                    [-106.653977078, 39.469833814, 3308.118],
                    [-106.653487129, 39.469070615, 3318.146000000000186],
                    [-106.65347838, 39.468922026, 3320.572999999999865],
                    [-106.653653361, 39.468537046, 3328.761],
                    [-106.653355893, 39.467976456, 3336.543999999999869],
                    [-106.653277151, 39.46759147, 3341.9],
                    [-106.653215907, 39.467422616, 3342.356000000000222],
                    [-106.653207158, 39.467091661, 3347.393],
                    [-106.652918438, 39.466713425, 3355.73],
                    [-106.652673464, 39.466639128, 3358.167],
                    [-106.652489733, 39.466632374, 3359.628000000000156],
                    [-106.652323501, 39.466585094, 3361.422999999999774],
                    [-106.651798556, 39.466368958, 3365.821],
                    [-106.65146609, 39.466382466, 3370.094],
                    [-106.651063632, 39.466585094, 3379.726999999999862],
                    [-106.650547436, 39.466240626, 3396.119000000000142],
                    [-106.650442447, 39.465983963, 3400.897],
                    [-106.650249968, 39.465835368, 3405.927999999999884],
                    [-106.650136229, 39.465835368, 3407.873],
                    [-106.649812513, 39.465463879, 3417.536]
                ],
                camera:                 [
                    [-106.693230578781368, 39.521841728834644],
                    [-106.638458732510458, 39.453177315142256]
                ],
            },
            visible:false,
            description: 'I am a big fan of the Peter Estin Hut. There is something about looking at the imposing climb from the parking lot at Yeoman Park that excites me. I also think it has great skiing around the hut. The mountains around the hut are big, and the panoramic view is enormous and humbling.<br /> I like taking the Hat Creek Trail, which starts by following the road to Fulford Cave, then winds up a forest till you get near the top, where it opens up. Soon you will soon emerge from the trees at the front door of the hut.',
            location: {
                center: [-106.66126, 39.49644],
                zoom: 13.77,
                pitch: 1.00,
                bearing: 101.70
            },
            onChapterEnter: [{
                layer: 'estin-gates-tracks',
                opacity: 1.00
            },
            {
                layer: 'polar-star-tracks',
                opacity: 0.00
            }],
            onChapterExit: [{
                layer: 'estin-gates-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'estintopolarstar',
            title: 'Estin to Polar Star hut to hut',
            image: '/img/estintopolar.jpg',
            huttriplink: 'https://www.huttrip.com/2008/03/peter-estin-to-polar-star-via-ironedge.html',
            hutorglink: '',
            gpx: 'gpx/estintopolarstar-data.gpx',
            data: {
                route:[
                    [-106.649834386, 39.465443616, 3417.46],
                    [-106.650088109, 39.465842122, 3408.619999999999891],
                    [-106.650280589, 39.465896157, 3404.714],
                    [-106.650508066, 39.466112295, 3398.293999999999869],
                    [-106.65056056, 39.466233872, 3395.925000000000182],
                    [-106.651006763, 39.466564831, 3381.172999999999774],
                    [-106.651496712, 39.466395975, 3369.199],
                    [-106.65199541, 39.466402729, 3365.226999999999862],
                    [-106.652581599, 39.466632374, 3358.882999999999811],
                    [-106.652905315, 39.466672899, 3356.536999999999807],
                    [-106.653255278, 39.467118678, 3347.45699999999988],
                    [-106.653202784, 39.467422616, 3342.20699999999988],
                    [-106.653447758, 39.468104784, 3333.83],
                    [-106.653683983, 39.468550554, 3328.679999999999836],
                    [-106.65343026, 39.468989567, 3319.226000000000113],
                    [-106.653946456, 39.469854076, 3307.833999999999833],
                    [-106.654121438, 39.469901353, 3306.351000000000113],
                    [-106.654156434, 39.469975647, 3304.965000000000146],
                    [-106.654077692, 39.470178264, 3301.84900000000016],
                    [-106.654331416, 39.47071182, 3294.251000000000204],
                    [-106.654620135, 39.471008989, 3292.967],
                    [-106.654585139, 39.471677615, 3287.090000000000146],
                    [-106.654471401, 39.471873473, 3281.623999999999796],
                    [-106.65448015, 39.471954518, 3280.083],
                    [-106.654235176, 39.472352987, 3263.402],
                    [-106.654322666, 39.473129658, 3249.83],
                    [-106.654751372, 39.473318759, 3247.036],
                    [-106.654873859, 39.473507859, 3244.195000000000164],
                    [-106.654856361, 39.474176461, 3224.791000000000167],
                    [-106.654803866, 39.474534396, 3214.0949999999998],
                    [-106.65486511, 39.47493285, 3210.839],
                    [-106.655066339, 39.475277275, 3213.371999999999844],
                    [-106.655101335, 39.475513644, 3210.665],
                    [-106.655372557, 39.475763519, 3214.936000000000149],
                    [-106.655346309, 39.475851313, 3212.109],
                    [-106.655031342, 39.475871573, 3200.844],
                    [-106.654908855, 39.476020146, 3192.978],
                    [-106.655223822, 39.476398332, 3192.947000000000116],
                    [-106.655066339, 39.476506385, 3184.243],
                    [-106.655110084, 39.476573918, 3183.607],
                    [-106.655232571, 39.476654957, 3185.199],
                    [-106.655258819, 39.477060152, 3171.176],
                    [-106.655162579, 39.476985867, 3170.764000000000124],
                    [-106.654873859, 39.476979114, 3162.438000000000102],
                    [-106.654812615, 39.477033139, 3159.425000000000182],
                    [-106.655276317, 39.477830017, 3152.440999999999804],
                    [-106.655442549, 39.47798534, 3153.963000000000193],
                    [-106.655635029, 39.47798534, 3157.880999999999858],
                    [-106.655862505, 39.478154168, 3158.456000000000131],
                    [-106.655836258, 39.478282477, 3154.491],
                    [-106.655547538, 39.478383774, 3147.570000000000164],
                    [-106.655503793, 39.47850533, 3143.570000000000164],
                    [-106.655643778, 39.478599873, 3141.998999999999796],
                    [-106.655678775, 39.478822725, 3135.380000000000109],
                    [-106.655827509, 39.47901181, 3132.514999999999873],
                    [-106.656054985, 39.479194142, 3131.695000000000164],
                    [-106.656046236, 39.479295438, 3128.728],
                    [-106.656168723, 39.47932245, 3130.493],
                    [-106.656177472, 39.479450757, 3125.472000000000207],
                    [-106.655923749, 39.479572311, 3113.498],
                    [-106.655792513, 39.479707371, 3108.743],
                    [-106.655871254, 39.479761395, 3108.313000000000102],
                    [-106.656063734, 39.479822171, 3109.313999999999851],
                    [-106.656151225, 39.47990996, 3108.364],
                    [-106.656404949, 39.480355654, 3103.962],
                    [-106.656544934, 39.480801345, 3098.647],
                    [-106.656544934, 39.480990425, 3095.902],
                    [-106.656448694, 39.481091718, 3092.66800000000012],
                    [-106.656326207, 39.481138988, 3089.858000000000175],
                    [-106.655836258, 39.481220022, 3082.715000000000146],
                    [-106.6554338, 39.481125482, 3081.967999999999847],
                    [-106.655267568, 39.481132235, 3080.27],
                    [-106.655162579, 39.481199763, 3077.467],
                    [-106.655118833, 39.481355078, 3073.699999999999818],
                    [-106.655136331, 39.48145637, 3071.676],
                    [-106.655390055, 39.481706224, 3065.92],
                    [-106.655521291, 39.48198984, 3061.364],
                    [-106.655416302, 39.482016851, 3060.188999999999851],
                    [-106.655258819, 39.48198984, 3061.844],
                    [-106.655005095, 39.482057368, 3068.518999999999778],
                    [-106.655118833, 39.482131648, 3063.175000000000182],
                    [-106.655302564, 39.48211139, 3060.371999999999844],
                    [-106.655573786, 39.482246445, 3056.630000000000109],
                    [-106.655398804, 39.482293714, 3055.45699999999988],
                    [-106.655355058, 39.482374746, 3054.688999999999851],
                    [-106.655407553, 39.482469284, 3051.822999999999865],
                    [-106.65514508, 39.482752898, 3049.141],
                    [-106.655206324, 39.48303651, 3045.958999999999833],
                    [-106.655355058, 39.48316481, 3041.513],
                    [-106.6554338, 39.483434916, 3031.90099999999984],
                    [-106.655372557, 39.483515947, 3030.482],
                    [-106.655197575, 39.483569968, 3035.0],
                    [-106.655206324, 39.483664504, 3030.918999999999869],
                    [-106.655320062, 39.483792803, 3020.094],
                    [-106.655372557, 39.484130432, 3006.913],
                    [-106.655320062, 39.48433976, 3003.674],
                    [-106.655678775, 39.484420791, 2994.353],
                    [-106.655853756, 39.484468058, 2987.205],
                    [-106.656037487, 39.484393781, 2985.35],
                    [-106.656746163, 39.484407286, 2984.409000000000106],
                    [-106.656798657, 39.484549088, 2977.822000000000116],
                    [-106.657201115, 39.484609861, 2978.886],
                    [-106.657341101, 39.484697644, 2976.219],
                    [-106.657489835, 39.484906971, 2965.300000000000182],
                    [-106.657664817, 39.48496099, 2962.225],
                    [-106.657761057, 39.484954238, 2962.764999999999873],
                    [-106.657971035, 39.485129802, 2958.608000000000175],
                    [-106.658067275, 39.485305365, 2960.378000000000156],
                    [-106.657507333, 39.485440414, 2940.953],
                    [-106.657288606, 39.485588967, 2934.893999999999778],
                    [-106.657192366, 39.485757777, 2928.983000000000175],
                    [-106.657192366, 39.485899577, 2925.784999999999854],
                    [-106.657043632, 39.486068387, 2923.583999999999833],
                    [-106.656982388, 39.486426261, 2920.554999999999836],
                    [-106.657183617, 39.486858408, 2912.340000000000146],
                    [-106.657201115, 39.487162261, 2910.848],
                    [-106.657454839, 39.487351323, 2904.268],
                    [-106.657664817, 39.487729448, 2897.041000000000167],
                    [-106.657533581, 39.487823978, 2897.288],
                    [-106.657647319, 39.488559963, 2882.565999999999804],
                    [-106.657813551, 39.488789535, 2877.297999999999774],
                    [-106.657831049, 39.488924577, 2876.398999999999887],
                    [-106.657743559, 39.489100131, 2876.69],
                    [-106.657953537, 39.489201412, 2875.313000000000102],
                    [-106.658076024, 39.489370213, 2873.554],
                    [-106.658146017, 39.48982935, 2876.516999999999825],
                    [-106.658049777, 39.490106182, 2879.681],
                    [-106.65811102, 39.49030874, 2879.231000000000222],
                    [-106.658277253, 39.490565314, 2875.775],
                    [-106.658294751, 39.490835391, 2874.835999999999785],
                    [-106.658032278, 39.491233751, 2874.436000000000149],
                    [-106.657953537, 39.491773898, 2874.186000000000149],
                    [-106.658259755, 39.491794154, 2872.645],
                    [-106.658583471, 39.49185492, 2872.404],
                    [-106.658714707, 39.491834664, 2871.683],
                    [-106.659397136, 39.492320792, 2871.913],
                    [-106.659598365, 39.492921696, 2874.009],
                    [-106.660202051, 39.493347052, 2862.130999999999858],
                    [-106.660552015, 39.493974953, 2861.791000000000167],
                    [-106.660578262, 39.494224761, 2863.77],
                    [-106.660648255, 39.494339538, 2863.380999999999858],
                    [-106.66088448, 39.494467817, 2860.033],
                    [-106.661339432, 39.494562339, 2851.376000000000204],
                    [-106.661488167, 39.494677115, 2849.991],
                    [-106.661864378, 39.495345513, 2854.215000000000146],
                    [-106.662214341, 39.495710091, 2848.126000000000204],
                    [-106.662468064, 39.496101672, 2841.217],
                    [-106.662905519, 39.496459495, 2826.616],
                    [-106.663010508, 39.496709295, 2833.033],
                    [-106.66280053, 39.496567517, 2837.681],
                    [-106.662739286, 39.496716046, 2847.659000000000106],
                    [-106.663622944, 39.499011459, 2889.228999999999814],
                    [-106.664375365, 39.500233399, 2905.699],
                    [-106.664366616, 39.500888241, 2924.346],
                    [-106.663194238, 39.500111881, 2950.547999999999774],
                    [-106.661514414, 39.499511039, 2984.820000000000164],
                    [-106.657603573, 39.498950699, 3046.237],
                    [-106.657104875, 39.498997957, 3061.098],
                    [-106.657069879, 39.499288254, 3064.181],
                    [-106.657411093, 39.499754077, 3066.59900000000016],
                    [-106.658898438, 39.501232537, 3059.196],
                    [-106.65897718, 39.50238693, 3073.610999999999876],
                    [-106.658723456, 39.503561555, 3087.35],
                    [-106.658994678, 39.505195196, 3100.996],
                    [-106.658277253, 39.506950305, 3107.206000000000131],
                    [-106.658198511, 39.509677387, 3103.616],
                    [-106.657883544, 39.510845139, 3106.232],
                    [-106.659029674, 39.512282864, 3102.754],
                    [-106.657717311, 39.511931873, 3100.71],
                    [-106.655976244, 39.511047637, 3103.502],
                    [-106.653351518, 39.51054814, 3105.862999999999829],
                    [-106.652467861, 39.51021064, 3106.391999999999825],
                    [-106.651566705, 39.509596386, 3106.174],
                    [-106.649694401, 39.507976349, 3110.527],
                    [-106.648417034, 39.507598334, 3092.578],
                    [-106.6477871, 39.507584834, 3091.857],
                    [-106.646728461, 39.507848094, 3099.911999999999807],
                    [-106.646360999, 39.5080101, 3105.007999999999811],
                    [-106.645652323, 39.508637868, 3114.2199999999998],
                    [-106.645818556, 39.509474884, 3098.326],
                    [-106.646124774, 39.509873138, 3102.373999999999796],
                    [-106.648819492, 39.512181617, 3071.989999999999782],
                    [-106.648539521, 39.512917344, 3079.244999999999891],
                    [-106.648793245, 39.513187334, 3072.15099999999984],
                    [-106.649965622, 39.513389825, 3061.092],
                    [-106.650166851, 39.51351132, 3059.163],
                    [-106.649878131, 39.513801556, 3066.880000000000109],
                    [-106.649624408, 39.51392305, 3073.070000000000164],
                    [-106.649633157, 39.514098541, 3076.705],
                    [-106.649466924, 39.514172787, 3080.958],
                    [-106.649291943, 39.514172787, 3083.4699999999998],
                    [-106.648705754, 39.514604762, 3104.090999999999894],
                    [-106.649003223, 39.514692507, 3102.494999999999891],
                    [-106.649659404, 39.514733004, 3093.554],
                    [-106.650105608, 39.515158226, 3099.797999999999774],
                    [-106.650254342, 39.515347213, 3103.315],
                    [-106.650201848, 39.515401209, 3106.534999999999854],
                    [-106.649720648, 39.515333714, 3111.913],
                    [-106.649169456, 39.515644191, 3129.373],
                    [-106.648845739, 39.515637442, 3135.641],
                    [-106.648679507, 39.515509201, 3136.5949999999998],
                    [-106.648329543, 39.515455205, 3144.563999999999851],
                    [-106.648233303, 39.515515951, 3148.838000000000193],
                    [-106.648110816, 39.515711686, 3156.705],
                    [-106.64797958, 39.515745434, 3159.697000000000116],
                    [-106.647953333, 39.515826427, 3162.636],
                    [-106.64807582, 39.515880423, 3162.744000000000142],
                    [-106.648373289, 39.516359634, 3175.154],
                    [-106.648845739, 39.516649858, 3178.371],
                    [-106.649011972, 39.516724101, 3177.9699999999998],
                    [-106.649081965, 39.516865838, 3179.158],
                    [-106.648976976, 39.517095316, 3183.661999999999807],
                    [-106.648828241, 39.517216805, 3187.409000000000106],
                    [-106.648662009, 39.51756102, 3192.481000000000222],
                    [-106.648574518, 39.51765551, 3195.063000000000102],
                    [-106.648749499, 39.517884986, 3190.469],
                    [-106.648539521, 39.518215699, 3197.967999999999847],
                    [-106.648487027, 39.518573408, 3204.748999999999796],
                    [-106.648697005, 39.519025603, 3206.879],
                    [-106.648627012, 39.519309067, 3210.56],
                    [-106.648732001, 39.519552036, 3209.761],
                    [-106.648032075, 39.518937864, 3219.926],
                    [-106.647629617, 39.518701643, 3228.531],
                    [-106.646999683, 39.518256195, 3233.527],
                    [-106.646588476, 39.518168454, 3238.813999999999851],
                    [-106.642353918, 39.520240441, 3286.619000000000142],
                    [-106.641505257, 39.520355175, 3296.45699999999988],
                    [-106.641435265, 39.520294434, 3297.853999999999814],
                    [-106.641181541, 39.520334928, 3300.277],
                    [-106.640971563, 39.520584642, 3299.973],
                    [-106.640980312, 39.520706124, 3298.728999999999814],
                    [-106.640840327, 39.520766865, 3299.449],
                    [-106.640376625, 39.520780363, 3303.1],
                    [-106.640079156, 39.520719622, 3306.02],
                    [-106.639895426, 39.520746618, 3307.172],
                    [-106.639527964, 39.520679128, 3311.309000000000196],
                    [-106.639212997, 39.520490156, 3317.184000000000196],
                    [-106.63794438, 39.520564395, 3331.069],
                    [-106.637244453, 39.520955836, 3334.092],
                    [-106.636640766, 39.521097565, 3340.721],
                    [-106.636588272, 39.521198799, 3340.364999999999782],
                    [-106.635949588, 39.521515999, 3344.880000000000109],
                    [-106.634366004, 39.521711717, 3362.677999999999884],
                    [-106.633849808, 39.521914184, 3367.821]
                ],
                camera:[
                    [-106.639736209974501, 39.477768756325112],
                    [-106.643408957683633, 39.457169432217391],
                    [-106.666084182670417, 39.460682495243518],
                    [-106.676144317699766, 39.511941278488301],
                    [-106.657620894471123, 39.524236999079726]
                ],
            },
            visible:false,
            description: 'Combining two of the best huts into a hut to hut trip makes for one of my favorite things to do.  We spend two nights at each hut, but I mostly love the time and solitude spent trekking between both huts.<br /> For this trip, I like to start with Estin Hut, and then head over to Polar Star via Hat Creek and the Newcomer trails.  You could always go back down to the car if you needed to drop off your trash or grab new supplies, but we\'ve been fortunate to have other people do this for us.',
            location: {
                center: [-106.66674, 39.50285],
                zoom: 13.29,
                pitch: 85.00,
                bearing: 39.20
            },
            onChapterEnter: [{
                layer: 'estin-gates-tracks',
                opacity: 1.00
            },
            {
                layer: 'polar-star-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'estin-gates-tracks',
                opacity: 0.25
            },
            {
                layer: 'polar-star-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'mcnamarahut',
            title: 'McNamara Hut',
            image: '/img/mcnamarahut.jpg',
            huttriplink: 'https://www.huttrip.com/2009/02/mcnamara-hut-tenth-mountain-hut-system.html',
            hutorglink: 'https://www.huts.org/The_Huts/mcnamara.php',
            gpx: 'gpx/MacNamara.gpx',
            data: {
                route:[[-106.797694377601147, 39.206168772652745, 2643.7626953125],
                [-106.79769454523921, 39.206168940290809, 2641.83984375],
                [-106.797694293782115, 39.206169527024031, 2645.205078125],
                [-106.797694629058242, 39.206163911148906, 2642.32080078125],
                [-106.797697059810162, 39.206158798187971, 2642.80126953125],
                [-106.79774634540081, 39.206179920583963, 2642.80126953125],
                [-106.79773754440248, 39.206204144284129, 2642.80126953125],
                [-106.797711309045553, 39.206284191459417, 2643.7626953125],
                [-106.797755816951394, 39.206370608881116, 2645.205078125],
                [-106.797756487503648, 39.206428444013, 2645.685546875],
                [-106.797533025965095, 39.20668643899262, 2648.56982421875],
                [-106.79731048643589, 39.20690881088376, 2650.01171875],
                [-106.797287603840232, 39.206940243020654, 2650.01171875],
                [-106.797200264409184, 39.207168985158205, 2653.3759765625],
                [-106.796974372118711, 39.207500908523798, 2657.7021484375],
                [-106.796746300533414, 39.207560755312443, 2658.1826171875],
                [-106.796716041862965, 39.207573914900422, 2657.7021484375],
                [-106.796696679666638, 39.207608113065362, 2659.14404296875],
                [-106.796809500083327, 39.207746582105756, 2660.5859375],
                [-106.797033632174134, 39.207804081961513, 2663.4697265625],
                [-106.797236390411854, 39.207809111103415, 2665.873046875],
                [-106.797323143109679, 39.207807686179876, 2666.83447265625],
                [-106.797406878322363, 39.207803076133132, 2668.2763671875],
                [-106.797715416178107, 39.207850601524115, 2671.16015625],
                [-106.797880791127682, 39.208001140505075, 2674.52490234375],
                [-106.797854220494628, 39.208227787166834, 2677.89013671875],
                [-106.79778104647994, 39.20833071693778, 2680.77392578125],
                [-106.797762522473931, 39.208374470472336, 2679.8125],
                [-106.797614078968763, 39.208599692210555, 2683.17724609375],
                [-106.797496397048235, 39.208770431578159, 2686.54150390625],
                [-106.79744141176343, 39.208846539258957, 2687.9833984375],
                [-106.797384414821863, 39.208929687738419, 2688.94482421875],
                [-106.797304200008512, 39.209059691056609, 2691.82861328125],
                [-106.797277294099331, 39.209095481783152, 2691.82861328125],
                [-106.797181908041239, 39.209256246685982, 2695.193359375],
                [-106.797072608023882, 39.209451209753752, 2698.55810546875],
                [-106.796976132318377, 39.209631001576781, 2701.44189453125],
                [-106.796872029080987, 39.209807608276606, 2704.80615234375],
                [-106.796824922785163, 39.209885392338037, 2705.767578125],
                [-106.796730542555451, 39.210042804479599, 2709.1328125],
                [-106.79667723365128, 39.210123606026173, 2710.09423828125],
                [-106.796598695218563, 39.210226032882929, 2711.5361328125],
                [-106.796505404636264, 39.210310857743025, 2715.38134765625],
                [-106.796369282528758, 39.210502132773399, 2716.8232421875],
                [-106.796287391334772, 39.210615120828152, 2719.2265625],
                [-106.796181444078684, 39.210729030892253, 2720.1875],
                [-106.796043561771512, 39.210868841037154, 2723.55224609375],
                [-106.795888831838965, 39.210996078327298, 2725.47509765625],
                [-106.795627316460013, 39.21120529063046, 2728.83935546875],
                [-106.795480214059353, 39.211294390261173, 2732.2041015625],
                [-106.795377116650343, 39.211366893723607, 2733.64599609375],
                [-106.795324813574553, 39.211419615894556, 2733.64599609375],
                [-106.795313078910112, 39.211419196799397, 2734.607421875],
                [-106.795101100578904, 39.211587505415082, 2735.568359375],
                [-106.794763980433345, 39.211844075471163, 2739.4140625],
                [-106.794629031792283, 39.211938288062811, 2742.77880859375],
                [-106.794443456456065, 39.212090754881501, 2745.18212890625],
                [-106.794400876387954, 39.212115984410048, 2745.66259765625],
                [-106.794308088719845, 39.212185721844435, 2747.10498046875],
                [-106.794095104560256, 39.212292172014713, 2750.94970703125],
                [-106.794002652168274, 39.212349839508533, 2752.39208984375],
                [-106.793961748480797, 39.212383283302188, 2752.39208984375],
                [-106.793800732120872, 39.212493170052767, 2756.2373046875],
                [-106.79373811930418, 39.212539857253432, 2757.67919921875],
                [-106.793658239766955, 39.212654856964946, 2759.6015625],
                [-106.79351968690753, 39.212869936600327, 2761.04345703125],
                [-106.793461516499519, 39.212956437841058, 2762.0048828125],
                [-106.793410470709205, 39.21303765848279, 2761.5244140625],
                [-106.793310726061463, 39.213041765615344, 2761.5244140625],
                [-106.793308546766639, 39.213041346520185, 2762.48583984375],
                [-106.793177286162972, 39.212959371507168, 2762.96630859375],
                [-106.793058263137937, 39.212809419259429, 2763.927734375],
                [-106.792767997831106, 39.212680924683809, 2764.888671875],
                [-106.792336413636804, 39.212696263566613, 2767.77294921875],
                [-106.792171038687229, 39.212738508358598, 2769.6953125],
                [-106.792003568261862, 39.212776897475123, 2771.6181640625],
                [-106.79173618555069, 39.212809838354588, 2774.98291015625],
                [-106.79154809564352, 39.212797181680799, 2775.4638671875],
                [-106.791054401546717, 39.212811682373285, 2778.34765625],
                [-106.790836723521352, 39.212843282148242, 2780.27001953125],
                [-106.790567748248577, 39.212896004319191, 2784.115234375],
                [-106.790508320555091, 39.212907068431377, 2784.59619140625],
                [-106.790225431323051, 39.212952246889472, 2788.921875],
                [-106.790121328085661, 39.21296238899231, 2789.88330078125],
                [-106.790075898170471, 39.212968423962593, 2790.36376953125],
                [-106.789948744699359, 39.212974710389972, 2791.8056640625],
                [-106.789577761664987, 39.213009160012007, 2794.208984375],
                [-106.789115248247981, 39.213056014850736, 2797.57373046875],
                [-106.788604371249676, 39.21310119330883, 2800.9384765625],
                [-106.788204135373235, 39.213149724528193, 2803.822265625],
                [-106.78776073269546, 39.213197249919176, 2806.22607421875],
                [-106.787253459915519, 39.213238321244717, 2810.0712890625],
                [-106.787016419693828, 39.21330101788044, 2812.955078125],
                [-106.786973755806684, 39.21330495737493, 2812.47412109375],
                [-106.786623895168304, 39.213414341211319, 2813.91650390625],
                [-106.786277890205383, 39.213578207418323, 2815.3583984375],
                [-106.786194490268826, 39.213616261258721, 2815.8388671875],
                [-106.786144366487861, 39.213643250986934, 2815.8388671875],
                [-106.78593029268086, 39.213779792189598, 2815.8388671875],
                [-106.785898860543966, 39.213809631764889, 2815.3583984375],
                [-106.785834236070514, 39.213901413604617, 2816.31982421875],
                [-106.785780424252152, 39.2139558121562, 2816.80029296875],
                [-106.785774305462837, 39.213965618982911, 2816.80029296875],
                [-106.7856933362782, 39.214153625071049, 2816.80029296875],
                [-106.785647654905915, 39.214347582310438, 2817.76171875],
                [-106.785648409277201, 39.214485883712769, 2817.76171875],
                [-106.785624856129289, 39.214578000828624, 2818.2421875],
                [-106.785468030720949, 39.214588394388556, 2819.68408203125],
                [-106.785218333825469, 39.214516142383218, 2822.56787109375],
                [-106.78489537909627, 39.214417655020952, 2825.9326171875],
                [-106.784675857052207, 39.214362166821957, 2828.3359375],
                [-106.784387771040201, 39.214295530691743, 2831.2197265625],
                [-106.784197585657239, 39.214256471022964, 2832.66162109375],
                [-106.783901201561093, 39.21418447047472, 2836.02685546875],
                [-106.783603560179472, 39.214104255661368, 2838.91064453125],
                [-106.783276749774814, 39.214091682806611, 2842.755859375],
                [-106.783007690683007, 39.214119426906109, 2846.12060546875],
                [-106.782741900533438, 39.214142560958862, 2848.52392578125],
                [-106.782423807308078, 39.214149350300431, 2850.4462890625],
                [-106.782351303845644, 39.214162593707442, 2850.4462890625],
                [-106.782200681045651, 39.214169466868043, 2852.369140625],
                [-106.78193480707705, 39.214231157675385, 2855.7333984375],
                [-106.781837912276387, 39.214268792420626, 2857.17529296875],
                [-106.78173003718257, 39.214322017505765, 2858.6171875],
                [-106.781617300584912, 39.214370548725128, 2859.57861328125],
                [-106.781483273953199, 39.214432071894407, 2861.50146484375],
                [-106.781247742474079, 39.214618150144815, 2865.3466796875],
                [-106.781156715005636, 39.214662155136466, 2866.78857421875],
                [-106.781091755256057, 39.214694509282708, 2868.23095703125],
                [-106.781072225421667, 39.214676907286048, 2868.23095703125],
                [-106.781063927337527, 39.214689647778869, 2867.75],
                [-106.781040038913488, 39.214690402150154, 2868.23095703125],
                [-106.780838873237371, 39.214728623628616, 2868.71142578125],
                [-106.780705433338881, 39.214796181768179, 2868.71142578125],
                [-106.780594456940889, 39.214833900332451, 2868.71142578125],
                [-106.780445007607341, 39.214926939457655, 2868.71142578125],
                [-106.7802450992167, 39.21504445374012, 2869.1923828125],
                [-106.780148036777973, 39.215113520622253, 2869.1923828125],
                [-106.779970927163959, 39.215207314118743, 2870.15380859375],
                [-106.779793314635754, 39.215259198099375, 2870.63427734375],
                [-106.779666244983673, 39.215311417356133, 2870.15380859375],
                [-106.779455607756972, 39.215400433167815, 2870.63427734375],
                [-106.779229547828436, 39.215498082339764, 2871.11474609375],
                [-106.778988484293222, 39.215597826987505, 2871.11474609375],
                [-106.778725711628795, 39.215707629919052, 2871.11474609375],
                [-106.778524378314614, 39.215785497799516, 2872.076171875],
                [-106.77818750962615, 39.215846434235573, 2873.03759765625],
                [-106.777893891558051, 39.215909047052264, 2874.9599609375],
                [-106.777539672330022, 39.216014742851257, 2875.92138671875],
                [-106.777178077027202, 39.21612337231636, 2876.40185546875],
                [-106.77685646340251, 39.216329399496317, 2879.2861328125],
                [-106.776678012683988, 39.216468371450901, 2879.2861328125],
                [-106.776374503970146, 39.216790068894625, 2882.650390625],
                [-106.776292696595192, 39.216813873499632, 2886.01513671875],
                [-106.77625154145062, 39.216845305636525, 2886.9765625],
                [-106.77611768245697, 39.216921916231513, 2890.3408203125],
                [-106.775987595319748, 39.217001041397452, 2894.18603515625],
                [-106.775926491245627, 39.217102043330669, 2896.10888671875],
                [-106.775930263102055, 39.217155436053872, 2896.10888671875],
                [-106.775921545922756, 39.21723984181881, 2895.6279296875],
                [-106.775917522609234, 39.217261131852865, 2895.6279296875],
                [-106.775864046066999, 39.217477971687913, 2899.47314453125],
                [-106.775904949754477, 39.217546284198761, 2901.396484375],
                [-106.775922467932105, 39.217629013583064, 2903.7998046875],
                [-106.775932190939784, 39.217659356072545, 2904.2802734375],
                [-106.775923054665327, 39.217697493731976, 2906.203125],
                [-106.775895142927766, 39.217779468744993, 2908.12548828125],
                [-106.775835128501058, 39.217951549217105, 2908.12548828125],
                [-106.775765139609575, 39.218108542263508, 2908.12548828125],
                [-106.775716859847307, 39.218244915828109, 2909.5673828125],
                [-106.775691378861666, 39.218318928033113, 2909.0869140625],
                [-106.775613762438297, 39.218447841703892, 2909.5673828125],
                [-106.775515610352159, 39.21856451779604, 2910.52880859375],
                [-106.775427516549826, 39.218624699860811, 2910.52880859375],
                [-106.775260716676712, 39.218747075647116, 2910.04833984375],
                [-106.775158541277051, 39.218798624351621, 2909.5673828125],
                [-106.775086456909776, 39.218890154734254, 2909.0869140625],
                [-106.774946982041001, 39.219012865796685, 2909.0869140625],
                [-106.774854781106114, 39.219122584909201, 2909.0869140625],
                [-106.774743553251028, 39.219243200495839, 2909.0869140625],
                [-106.774602234363556, 39.219387704506516, 2908.6064453125],
                [-106.774487150833011, 39.219506308436394, 2909.5673828125],
                [-106.774357482790947, 39.219673946499825, 2909.5673828125],
                [-106.774261426180601, 39.219779809936881, 2910.04833984375],
                [-106.774149527773261, 39.219906125217676, 2910.52880859375],
                [-106.774024805054069, 39.220044510439038, 2911.00927734375],
                [-106.773904608562589, 39.22017409466207, 2910.52880859375],
                [-106.773717692121863, 39.220274928957224, 2911.490234375],
                [-106.773599674925208, 39.22038221731782, 2911.490234375],
                [-106.773461122065783, 39.220544407144189, 2911.970703125],
                [-106.773366909474134, 39.220693269744515, 2912.451171875],
                [-106.773203462362289, 39.220870966091752, 2912.451171875],
                [-106.773060886189342, 39.220989737659693, 2913.8935546875],
                [-106.773054013028741, 39.220998790115118, 2913.8935546875],
                [-106.772972205653787, 39.221117896959186, 2913.8935546875],
                [-106.772818062454462, 39.221277991309762, 2913.8935546875],
                [-106.772635839879513, 39.221423668786883, 2914.3740234375],
                [-106.772446576505899, 39.221573201939464, 2915.33544921875],
                [-106.772227473556995, 39.221753580495715, 2916.77734375],
                [-106.772196292877197, 39.22178216278553, 2917.73876953125],
                [-106.771893119439483, 39.22206312417984, 2920.1416015625],
                [-106.771843330934644, 39.222109140828252, 2922.064453125],
                [-106.771656833589077, 39.222335368394852, 2922.064453125],
                [-106.771367490291595, 39.222662346437573, 2925.90966796875],
                [-106.771228769794106, 39.222859907895327, 2926.39013671875],
                [-106.771137826144695, 39.223001729696989, 2929.2744140625],
                [-106.77100564353168, 39.223244218155742, 2932.63916015625],
                [-106.770902881398797, 39.22336139716208, 2936.00390625],
                [-106.770843202248216, 39.223422417417169, 2939.36865234375],
                [-106.770755108445883, 39.223553761839867, 2941.291015625],
                [-106.770676570013165, 39.223648058250546, 2942.25244140625],
                [-106.770654609426856, 39.223670437932014, 2942.73291015625],
                [-106.770487809553742, 39.22381142154336, 2946.578125],
                [-106.770469453185797, 39.223862215876579, 2948.5009765625],
                [-106.77043835632503, 39.223909322172403, 2951.384765625],
                [-106.770326793193817, 39.224040834233165, 2954.74951171875],
                [-106.770310197025537, 39.224066650494933, 2954.74951171875],
                [-106.770196035504341, 39.224154576659203, 2958.59423828125],
                [-106.770076090469956, 39.224204113706946, 2961.478515625],
                [-106.770052453503013, 39.22420771792531, 2960.99755859375],
                [-106.769783981144428, 39.224313916638494, 2964.84326171875],
                [-106.769473096355796, 39.224502509459853, 2967.24658203125],
                [-106.76931694149971, 39.224598230794072, 2970.611328125],
                [-106.769284754991531, 39.224607283249497, 2972.05322265625],
                [-106.769268410280347, 39.22461130656302, 2972.53369140625],
                [-106.769163301214576, 39.22467827796936, 2972.05322265625],
                [-106.769058024510741, 39.224753379821777, 2974.93701171875],
                [-106.768899606540799, 39.224860668182373, 2978.7822265625],
                [-106.768668098375201, 39.225004250183702, 2984.0693359375],
                [-106.76858788356185, 39.225048338994384, 2985.51171875],
                [-106.768354279920459, 39.225138779729605, 2989.3564453125],
                [-106.768289739266038, 39.225166859105229, 2990.798828125],
                [-106.768252188339829, 39.225172307342291, 2994.64404296875],
                [-106.768237855285406, 39.225180605426431, 2994.1630859375],
                [-106.768183037638664, 39.225214719772339, 2995.12451171875],
                [-106.768103409558535, 39.225255455821753, 2995.60498046875],
                [-106.768013974651694, 39.225362995639443, 2996.08642578125],
                [-106.767883468419313, 39.225453352555633, 2996.08642578125],
                [-106.767685152590275, 39.225606406107545, 2996.56689453125],
                [-106.767527488991618, 39.225791813805699, 2997.04736328125],
                [-106.767418524250388, 39.225929612293839, 2998.0087890625],
                [-106.767263039946556, 39.226013850420713, 2998.97021484375],
                [-106.767040081322193, 39.226172184571624, 3000.89306640625],
                [-106.7669378221035, 39.226280311122537, 3001.85400390625],
                [-106.766867665573955, 39.226417019963264, 3004.25732421875],
                [-106.76675945520401, 39.226516596972942, 3004.25732421875],
                [-106.766660967841744, 39.22668507322669, 3005.69921875],
                [-106.76652786321938, 39.226902667433023, 3007.6220703125],
                [-106.766411438584328, 39.227158315479755, 3010.505859375],
                [-106.766399703919888, 39.227184969931841, 3010.505859375],
                [-106.766288056969643, 39.227401642128825, 3011.46728515625],
                [-106.76628403365612, 39.227457214146852, 3012.42822265625],
                [-106.766213122755289, 39.227579003199935, 3013.3896484375],
                [-106.766204740852118, 39.22762418165803, 3014.35107421875],
                [-106.766179678961635, 39.227679250761867, 3014.35107421875],
                [-106.766120502725244, 39.227805566042662, 3015.79296875],
                [-106.766120251268148, 39.227837836369872, 3015.79296875],
                [-106.766108265146613, 39.227920649573207, 3016.27392578125],
                [-106.766084209084511, 39.228051155805588, 3017.23486328125],
                [-106.766057135537267, 39.228195073083043, 3017.7158203125],
                [-106.765971891582012, 39.228346953168511, 3019.63818359375],
                [-106.765977088361979, 39.228371260687709, 3020.11865234375],
                [-106.765785645693541, 39.228566223755479, 3021.56103515625],
                [-106.765648266300559, 39.22868549823761, 3023.4833984375],
                [-106.7655114736408, 39.228819273412228, 3025.88671875],
                [-106.765435617417097, 39.228889849036932, 3027.32861328125],
                [-106.765398234128952, 39.228980792686343, 3026.3671875],
                [-106.765394797548652, 39.228978864848614, 3029.251953125],
                [-106.765392450615764, 39.228978613391519, 3021.080078125],
                [-106.765392115339637, 39.228979116305709, 3027.80908203125],
                [-106.765394462272525, 39.228979535400867, 3031.17431640625],
                [-106.765404101461172, 39.22896939329803, 3027.32861328125],
                [-106.765316426753998, 39.229057068005204, 3027.80908203125],
                [-106.765125822275877, 39.22920978628099, 3029.732421875],
                [-106.764936223626137, 39.229293437674642, 3032.1357421875],
                [-106.764865480363369, 39.229335598647594, 3033.09716796875],
                [-106.764815608039498, 39.229372059926391, 3033.57763671875],
                [-106.764746038243175, 39.229395110160112, 3034.5390625],
                [-106.76462709903717, 39.229448921978474, 3035.5],
                [-106.764465747401118, 39.229470631107688, 3036.46142578125],
                [-106.764445966109633, 39.229475157335401, 3036.9423828125],
                [-106.764223007485271, 39.229545900598168, 3037.4228515625],
                [-106.764018321409822, 39.229662995785475, 3039.826171875],
                [-106.763820676133037, 39.229843206703663, 3042.2294921875],
                [-106.763783460482955, 39.229880422353745, 3043.1904296875],
                [-106.763658570125699, 39.229986788704991, 3047.5166015625],
                [-106.763574918732047, 39.230014868080616, 3050.400390625],
                [-106.763568548485637, 39.230019981041551, 3050.880859375],
                [-106.763515742495656, 39.230041271075606, 3052.8037109375],
                [-106.763477688655257, 39.230060297995806, 3053.76513671875],
                [-106.763425888493657, 39.230104386806488, 3056.64892578125],
                [-106.763392863795161, 39.230113690719008, 3058.0908203125],
                [-106.763300327584147, 39.230157025158405, 3060.01416015625],
                [-106.763243330642581, 39.230204550549388, 3060.49462890625],
                [-106.763172168284655, 39.23021930269897, 3061.4560546875],
                [-106.763074435293674, 39.230208238586783, 3063.37841796875],
                [-106.763058342039585, 39.230205807834864, 3063.37841796875],
                [-106.762995477765799, 39.230214525014162, 3063.37841796875],
                [-106.762969410046935, 39.230224164202809, 3063.859375],
                [-106.762796156108379, 39.230221901088953, 3064.33984375],
                [-106.76267989911139, 39.230212345719337, 3066.26220703125],
                [-106.762625835835934, 39.230198683217168, 3066.26220703125],
                [-106.762487031519413, 39.230169095098972, 3065.78173828125],
                [-106.762203639373183, 39.230192229151726, 3065.30126953125],
                [-106.762178242206573, 39.230187619104981, 3065.30126953125],
                [-106.762041114270687, 39.230233049020171, 3066.26220703125],
                [-106.761796278879046, 39.230465982109308, 3067.2236328125],
                [-106.761700389906764, 39.230553656816483, 3070.10791015625],
                [-106.761569464579225, 39.230668824166059, 3073.47216796875],
                [-106.761555969715118, 39.230677457526326, 3073.47216796875],
                [-106.761448932811618, 39.230767479166389, 3075.39501953125],
                [-106.761327059939504, 39.230871247127652, 3076.35595703125],
                [-106.761302584782243, 39.23090485855937, 3075.87548828125],
                [-106.761219436302781, 39.230961101129651, 3076.8369140625],
                [-106.761164367198944, 39.231028240174055, 3076.35595703125],
                [-106.760878628119826, 39.231108790263534, 3078.27880859375],
                [-106.760694561526179, 39.231171403080225, 3078.27880859375],
                [-106.760583333671093, 39.231193531304598, 3079.720703125],
                [-106.760439584031701, 39.231152040883899, 3083.56591796875],
                [-106.760372864082456, 39.231157405301929, 3084.52734375],
                [-106.760253338143229, 39.231221275404096, 3085.48876953125],
                [-106.760253170505166, 39.231222532689571, 3085.96923828125],
                [-106.760253673419356, 39.231223957613111, 3082.6044921875],
                [-106.76025191321969, 39.231221862137318, 3079.240234375],
                [-106.76020891405642, 39.231221694499254, 3085.0078125],
                [-106.760023925453424, 39.231275590136647, 3085.0078125],
                [-106.759866932407022, 39.231315068900585, 3085.48876953125],
                [-106.759834159165621, 39.231316493824124, 3086.9306640625],
                [-106.759780263528228, 39.231332670897245, 3086.9306640625],
                [-106.759506091475487, 39.231432499364018, 3088.85302734375],
                [-106.759327137842774, 39.231504919007421, 3093.1796875],
                [-106.759189926087856, 39.231596197932959, 3095.58251953125],
                [-106.759173832833767, 39.231617068871856, 3096.0634765625],
                [-106.759174084290862, 39.231623103842139, 3096.5439453125],
                [-106.759212724864483, 39.231722177937627, 3097.0244140625],
                [-106.759058581665158, 39.231878248974681, 3098.947265625],
                [-106.758923633024096, 39.23197447322309, 3101.8310546875],
                [-106.758882394060493, 39.232007917016745, 3102.79248046875],
                [-106.758723556995392, 39.23211419954896, 3106.6376953125],
                [-106.758634792640805, 39.23215770162642, 3107.59912109375],
                [-106.758489282801747, 39.232176980003715, 3108.07958984375],
                [-106.758391968905926, 39.232191564515233, 3108.560546875],
                [-106.758223827928305, 39.232198689132929, 3108.560546875],
                [-106.75807605497539, 39.232243867591023, 3109.041015625],
                [-106.757932808250189, 39.23224369995296, 3110.00244140625],
                [-106.757742455229163, 39.232270270586014, 3110.48291015625],
                [-106.757531482726336, 39.232303965836763, 3112.4052734375],
                [-106.757370885461569, 39.23236339353025, 3113.36669921875],
                [-106.757341045886278, 39.232377475127578, 3113.36669921875],
                [-106.75722043029964, 39.23241812735796, 3113.36669921875],
                [-106.757137952372432, 39.232399854809046, 3114.328125],
                [-106.757089924067259, 39.232353754341602, 3114.80859375],
                [-106.757026221603155, 39.232353754341602, 3114.80859375],
                [-106.756925387308002, 39.232344031333923, 3114.80859375],
                [-106.756745176389813, 39.23234730027616, 3114.328125],
                [-106.756717013195157, 39.232332296669483, 3114.80859375],
                [-106.756679378449917, 39.232323579490185, 3115.28955078125],
                [-106.756444098427892, 39.232357023283839, 3115.77001953125],
                [-106.756298337131739, 39.232424162328243, 3116.2509765625],
                [-106.756153162568808, 39.232466658577323, 3117.2119140625],
                [-106.756128603592515, 39.232476884499192, 3117.2119140625],
                [-106.756012346595526, 39.232447212561965, 3117.69287109375],
                [-106.755905309692025, 39.232381163164973, 3118.65380859375],
                [-106.755692409351468, 39.232293237000704, 3119.615234375],
                [-106.755594424903393, 39.232229283079505, 3119.615234375],
                [-106.755391582846642, 39.232237581163645, 3121.05712890625],
                [-106.755183208733797, 39.232266331091523, 3122.4990234375],
                [-106.755158985033631, 39.232267504557967, 3122.4990234375],
                [-106.755027640610933, 39.232268510386348, 3122.0185546875],
                [-106.754908282309771, 39.232303127646446, 3121.5380859375],
                [-106.754746260121465, 39.232403123751283, 3121.5380859375],
                [-106.754578202962875, 39.232522817328572, 3121.5380859375],
                [-106.754445098340511, 39.232612000778317, 3122.4990234375],
                [-106.754330601543188, 39.232726665213704, 3123.46044921875],
                [-106.754280729219317, 39.232757426798344, 3123.46044921875],
                [-106.75417578779161, 39.232866056263447, 3122.97998046875],
                [-106.754138320684433, 39.23308708705008, 3121.5380859375],
                [-106.754065230488777, 39.233243493363261, 3120.57666015625],
                [-106.753987614065409, 39.233400486409664, 3121.05712890625],
                [-106.753967581316829, 39.233592683449388, 3119.134765625],
                [-106.753959953784943, 39.233794771134853, 3118.65380859375],
                [-106.753907650709152, 39.233918068930507, 3119.134765625],
                [-106.753859454765916, 39.234069362282753, 3118.65380859375],
                [-106.753850569948554, 39.23417815938592, 3116.7314453125],
                [-106.753830956295133, 39.234274383634329, 3116.2509765625],
                [-106.75381863489747, 39.234426598995924, 3115.77001953125],
                [-106.753759793937206, 39.234549980610609, 3114.80859375],
                [-106.753727691248059, 39.234614353626966, 3113.36669921875],
                [-106.753652673214674, 39.234726168215275, 3112.4052734375],
                [-106.7536426987499, 39.234838569536805, 3111.9248046875],
                [-106.753595089539886, 39.235018780454993, 3108.560546875],
                [-106.753559717908502, 39.235131684690714, 3107.1181640625],
                [-106.753512443974614, 39.23525707796216, 3106.1572265625],
                [-106.753454273566604, 39.23536503687501, 3105.19580078125],
                [-106.753402724862099, 39.235488502308726, 3104.71484375],
                [-106.7533353343606, 39.235614817589521, 3103.27294921875],
                [-106.753226788714528, 39.235714226961136, 3102.79248046875],
                [-106.753044482320547, 39.235801566392183, 3103.27294921875],
                [-106.7529312428087, 39.235836854204535, 3102.79248046875],
                [-106.752843065187335, 39.235827717930079, 3102.79248046875],
                [-106.75279788672924, 39.235849007964134, 3102.79248046875],
                [-106.752626057714224, 39.235891168937087, 3103.75390625],
                [-106.752430172637105, 39.235960990190506, 3104.234375],
                [-106.752260774374008, 39.235969707369804, 3104.234375],
                [-106.752227079123259, 39.23597146756947, 3104.234375],
                [-106.75206539221108, 39.235943052917719, 3104.71484375],
                [-106.751837823539972, 39.235849175602198, 3104.71484375],
                [-106.751770097762346, 39.23581313341856, 3106.1572265625],
                [-106.751692481338978, 39.235789915546775, 3106.1572265625],
                [-106.75152987241745, 39.235677095130086, 3106.6376953125],
                [-106.751390062272549, 39.235534770414233, 3107.59912109375],
                [-106.751193506643176, 39.235385991632938, 3109.521484375],
                [-106.751004410907626, 39.235318265855312, 3109.521484375],
                [-106.750903325155377, 39.235243247821927, 3109.521484375],
                [-106.75062051974237, 39.23510268330574, 3110.00244140625],
                [-106.750406613573432, 39.234978714957833, 3111.9248046875],
                [-106.750367218628526, 39.234941499307752, 3112.4052734375],
                [-106.750184409320354, 39.234745614230633, 3115.28955078125],
                [-106.750156916677952, 39.234723569825292, 3115.28955078125],
                [-106.750043090432882, 39.234696831554174, 3115.77001953125],
                [-106.74991836771369, 39.234654000028968, 3116.2509765625],
                [-106.74988224171102, 39.234654000028968, 3116.7314453125],
                [-106.749833459034562, 39.234600104391575, 3117.2119140625],
                [-106.74974980764091, 39.234491055831313, 3118.17333984375],
                [-106.74973220564425, 39.234465574845672, 3118.17333984375],
                [-106.749691050499678, 39.234407152980566, 3118.17333984375],
                [-106.749624833464622, 39.234338589012623, 3118.17333984375],
                [-106.749547133222222, 39.234267761930823, 3118.65380859375],
                [-106.74948837608099, 39.23417086713016, 3118.65380859375],
                [-106.749400617554784, 39.234174806624651, 3118.17333984375],
                [-106.749190147966146, 39.234174219891429, 3119.615234375],
                [-106.749173887073994, 39.234177488833666, 3119.615234375],
                [-106.749087804928422, 39.23419970087707, 3119.134765625],
                [-106.748959310352802, 39.234213279560208, 3118.17333984375],
                [-106.74890105612576, 39.234220320358872, 3117.69287109375],
                [-106.74858002923429, 39.23431302420795, 3118.17333984375],
                [-106.748376097530127, 39.234488792717457, 3120.57666015625],
                [-106.748252715915442, 39.234544113278389, 3122.0185546875],
                [-106.748217847198248, 39.234584262594581, 3122.0185546875],
                [-106.74816763959825, 39.234590465202928, 3123.46044921875],
                [-106.748038977384567, 39.234667243435979, 3123.94140625],
                [-106.747853234410286, 39.234724994748831, 3124.90283203125],
                [-106.747633796185255, 39.234848544001579, 3125.3837890625],
                [-106.747416872531176, 39.234917527064681, 3124.90283203125],
                [-106.747355349361897, 39.2349355481565, 3125.3837890625],
                [-106.747343111783266, 39.23495021648705, 3125.8642578125],
                [-106.74708972685039, 39.235073681920767, 3126.82568359375],
                [-106.746843215078115, 39.235207121819258, 3126.82568359375],
                [-106.746658980846405, 39.235331425443292, 3127.30615234375],
                [-106.746472232043743, 39.235479198396206, 3126.82568359375],
                [-106.74633719958365, 39.235529154539108, 3130.18994140625],
                [-106.74624977633357, 39.235469391569495, 3133.07421875],
                [-106.746140224859118, 39.235432427376509, 3134.51611328125],
                [-106.746066212654114, 39.235413568094373, 3134.99658203125],
                [-106.745815509930253, 39.235458495095372, 3134.99658203125],
                [-106.745637394487858, 39.235422788187861, 3135.47705078125],
                [-106.745616607367992, 39.235394708812237, 3134.99658203125],
                [-106.745508313179016, 39.235297814011574, 3138.361328125],
                [-106.745460201054811, 39.235268477350473, 3139.32275390625],
                [-106.745441006496549, 39.23524240963161, 3139.80322265625],
                [-106.745300441980362, 39.235108885914087, 3143.16748046875],
                [-106.74505309201777, 39.23508265055716, 3143.16748046875],
                [-106.745000034570694, 39.235066389665008, 3143.6484375],
                [-106.744812615215778, 39.235028252005577, 3143.16748046875],
                [-106.744577083736658, 39.235005453228951, 3144.60986328125],
                [-106.744440123438835, 39.23500687815249, 3144.60986328125],
                [-106.744389664381742, 39.234985671937466, 3145.57080078125],
                [-106.744320346042514, 39.234968153759837, 3147.49365234375],
                [-106.744182128459215, 39.234894728288054, 3150.37744140625],
                [-106.744073247537017, 39.23482708632946, 3153.26123046875],
                [-106.744015412405133, 39.234791044145823, 3154.70361328125],
                [-106.743979621678591, 39.234805041924119, 3153.7421875],
                [-106.74376311711967, 39.23466288484633, 3157.587890625],
                [-106.743737971410155, 39.234622064977884, 3158.548828125],
                [-106.743621379137039, 39.234583340585232, 3159.9912109375],
                [-106.74350026063621, 39.234482757747173, 3162.875],
                [-106.743486430495977, 39.234470184892416, 3162.875],
                [-106.743472181260586, 39.234327021986246, 3163.35546875],
                [-106.743441419675946, 39.234181763604283, 3162.875],
                [-106.743282666429877, 39.234040947631001, 3163.35546875],
                [-106.743222568184137, 39.233995769172907, 3163.35546875],
                [-106.743026347830892, 39.233896275982261, 3164.31689453125],
                [-106.742964321747422, 39.233815809711814, 3164.79736328125],
                [-106.742825014516711, 39.233750011771917, 3166.72021484375],
                [-106.742732059210539, 39.23372084274888, 3167.681640625],
                [-106.742570959031582, 39.233686225488782, 3167.20068359375],
                [-106.742493007332087, 39.233709694817662, 3168.162109375],
                [-106.742429137229919, 39.233724027872086, 3169.12353515625],
                [-106.742214141413569, 39.233685722574592, 3169.12353515625],
                [-106.742073660716414, 39.233650099486113, 3169.60400390625],
                [-106.741998642683029, 39.233655212447047, 3170.0849609375],
                [-106.741938460618258, 39.233658984303474, 3170.5654296875],
                [-106.741805104538798, 39.233679519966245, 3169.60400390625],
                [-106.741592288017273, 39.233723608776927, 3170.0849609375],
                [-106.741402018815279, 39.23382394015789, 3170.5654296875],
                [-106.74117973074317, 39.233986046165228, 3171.0458984375],
                [-106.740954592823982, 39.234131807461381, 3172.96875],
                [-106.74077220261097, 39.234228366985917, 3172.48779296875],
                [-106.740629123523831, 39.234268432483077, 3172.00732421875],
                [-106.740610850974917, 39.234292740002275, 3172.00732421875],
                [-106.740440363064408, 39.234346300363541, 3172.48779296875],
                [-106.740273563191295, 39.234349904581904, 3172.00732421875],
                [-106.740142637863755, 39.234220823273063, 3171.52685546875],
                [-106.740015149116516, 39.234129041433334, 3171.0458984375],
                [-106.739920265972614, 39.233961068093777, 3171.0458984375],
                [-106.739900568500161, 39.233842296525836, 3171.52685546875],
                [-106.739871064200997, 39.233642974868417, 3171.0458984375],
                [-106.739775175228715, 39.233472906053066, 3171.52685546875],
                [-106.73973192460835, 39.233333766460419, 3171.0458984375],
                [-106.739648273214698, 39.233174761757255, 3170.5654296875],
                [-106.739429589360952, 39.233038723468781, 3169.60400390625],
                [-106.739242672920227, 39.233036460354924, 3170.0849609375],
                [-106.739251054823399, 39.233032604679465, 3170.0849609375],
                [-106.739089116454124, 39.233067389577627, 3169.60400390625],
                [-106.739077633246779, 39.233067976310849, 3170.0849609375],
                [-106.73901904374361, 39.233109634369612, 3168.642578125],
                [-106.739017618820071, 39.233108293265104, 3169.12353515625],
                [-106.738961124792695, 39.233155818656087, 3169.60400390625],
                [-106.738959867507219, 39.233157243579626, 3167.681640625],
                [-106.738962549716234, 39.233159171417356, 3169.12353515625],
                [-106.738962801173329, 39.233159003779292, 3170.5654296875],
                [-106.738962801173329, 39.233159003779292, 3171.0458984375],
                [-106.738962801173329, 39.23315891996026, 3154.22265625],
                [-106.738964393734932, 39.233161434531212, 3168.642578125],
                [-106.738964561372995, 39.233162021264434, 3168.162109375],
                [-106.738971015438437, 39.233173923566937, 3169.12353515625],
                [-106.738952407613397, 39.233189430087805, 3168.642578125],
                [-106.738911587744951, 39.233217425644398, 3169.12353515625],
                [-106.738910917192698, 39.23321608453989, 3169.12353515625],
                [-106.738907229155302, 39.233213821426034, 3165.7587890625],
                [-106.738904798403382, 39.233214743435383, 3165.2783203125],
                [-106.738935727626085, 39.233206529170275, 3169.12353515625],
                [-106.738931620493531, 39.233189597725868, 3168.642578125],
                [-106.738907732069492, 39.233190352097154, 3167.20068359375],
                [-106.738906558603048, 39.233189010992646, 3170.5654296875],
                [-106.738970512524247, 39.233145173639059, 3169.60400390625],
                [-106.738972440361977, 39.233145928010345, 3169.60400390625],
                [-106.738976463675499, 39.233145844191313, 3166.2392578125],
                [-106.738963555544615, 39.233167888596654, 3170.0849609375],
                [-106.738960957154632, 39.233168140053749, 3165.7587890625],
                [-106.738967327401042, 39.233162440359592, 3169.12353515625],
                [-106.738943019881845, 39.233199320733547, 3169.12353515625],
                [-106.738932877779007, 39.233186580240726, 3169.12353515625],
                [-106.738933715969324, 39.233185825869441, 3165.7587890625],
                [-106.738933715969324, 39.233183562755585, 3169.12353515625],
                [-106.73893203958869, 39.233182054013014, 3165.7587890625],
                [-106.738929022103548, 39.233180126175284, 3170.0849609375],
                [-106.738929860293865, 39.233179120346904, 3173.9296875],
                [-106.738924579694867, 39.233182808384299, 3166.2392578125],
                [-106.73892030492425, 39.233171995729208, 3169.60400390625],
                [-106.738921813666821, 39.233170822262764, 3164.79736328125],
                [-106.738907983526587, 39.233208121731877, 3169.12353515625],
                [-106.738895326852798, 39.233246510848403, 3169.12353515625],
                [-106.738919382914901, 39.233198147267103, 3167.681640625],
                [-106.738969422876835, 39.233164200559258, 3168.162109375],
                [-106.738963471725583, 39.233156824484468, 3168.162109375],
                [-106.738955341279507, 39.23316671513021, 3169.60400390625],
                [-106.738970009610057, 39.233154812827706, 3168.642578125],
                [-106.738901445642114, 39.233186664059758, 3168.642578125],
                [-106.738900104537606, 39.233185574412346, 3167.20068359375],
                [-106.738899769261479, 39.233186161145568, 3166.2392578125],
                [-106.738913515582681, 39.233180461451411, 3170.5654296875],
                [-106.738895075395703, 39.233177863061428, 3167.20068359375],
                [-106.738895578309894, 39.233177863061428, 3163.83642578125],
                [-106.738897589966655, 39.233178365975618, 3159.02978515625],
                [-106.738905971869826, 39.233177695423365, 3162.875]],
                camera:          [
                    [-106.822894541381885, 39.196244660186316],
                    [-106.7744302400897, 39.202232835799023],
                    [-106.781695893166457, 39.244549276795496],
                    [-106.715666276743647, 39.243990380404973]
                  ],
            },
            visible:false,
            description: 'McNamara is one of the orignal huts, and is one of my favorites. To start, you can\'t beat the hike starting and ending in Aspen. The trail is well marked out and well hiked, as many local Aspen residents use it for snowshoing and backcountry skiing. Like benedict hut, one of the best parts of the hike is looking behind and watching the aspen ski resort fall behind, and the majestic Maroon Bells begin to appear. The skiing off of Bald Knob is top notch, and the 360 degree view from the top is one of the best.',
            location: {
                center: [-106.78804, 39.22111],
                zoom: 13.63,
                pitch: 40.50,
                bearing: 14.40
            },
            onChapterEnter: [{
                layer: 'margys-mac-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'margys-mac-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'fritzbenedicthut',
            title: 'Fritz Benedict Hut',
            image: '/img/benedict.jpg',
            huttriplink: '',
            hutorglink: 'https://www.huts.org/The_Huts/benedict.php',
            gpx: 'gpx/BenedictHuts.gpx',
            data: {
                route:[
                    [-106.811878988519311, 39.201868688687682, 2534.91796875],
                    [-106.811823081225157, 39.20163064263761, 2533.0078125],
                    [-106.811797935515642, 39.201412461698055, 2526.1640625],
                    [-106.811716295778751, 39.201198136433959, 2517.7265625],
                    [-106.811449248343706, 39.201106522232294, 2513.5859375],
                    [-106.811164012178779, 39.201104007661343, 2513.17578125],
                    [-106.811129730194807, 39.201108701527119, 2513.21875],
                    [-106.811101986095309, 39.201114987954497, 2513.375],
                    [-106.811071643605828, 39.201123621314764, 2513.67578125],
                    [-106.811043564230204, 39.20112781226635, 2513.75],
                    [-106.811015820130706, 39.201134098693728, 2513.8515625],
                    [-106.810987824574113, 39.201138205826283, 2513.7734375],
                    [-106.810960248112679, 39.201146671548486, 2513.93359375],
                    [-106.810932755470276, 39.201155137270689, 2514.09375],
                    [-106.810905011370778, 39.201161507517099, 2514.13671875],
                    [-106.810876680538058, 39.201163435354829, 2513.93359375],
                    [-106.810848684981465, 39.201167542487383, 2513.85546875],
                    [-106.810820689424872, 39.201171649619937, 2513.73046875],
                    [-106.810789844021201, 39.201175924390554, 2513.5703125],
                    [-106.810762016102672, 39.201182294636965, 2513.546875],
                    [-106.810734272003174, 39.201188581064343, 2513.51171875],
                    [-106.810706527903676, 39.201194867491722, 2513.46875],
                    [-106.810678951442242, 39.201203333213925, 2513.51953125],
                    [-106.810651710256934, 39.201213978230953, 2513.62109375],
                    [-106.809359220787883, 39.20219105668366, 2524.54296875],
                    [-106.809360645711422, 39.202201953157783, 2524.984375],
                    [-106.808565706014633, 39.202797403559089, 2526.3515625],
                    [-106.808547349646688, 39.202910810709, 2528.23828125],
                    [-106.808697301894426, 39.20297535136342, 2532.75390625],
                    [-106.808822695165873, 39.202975519001484, 2535.8203125],
                    [-106.808949345722795, 39.202911900356412, 2539.15625],
                    [-106.809346899390221, 39.202528344467282, 2537.53515625],
                    [-106.809450499713421, 39.202512502670288, 2539.9296875],
                    [-106.808929732069373, 39.203236531466246, 2548.9140625],
                    [-106.808927720412612, 39.203241309151053, 2548.93359375],
                    [-106.808800650760531, 39.203375335782766, 2544.97265625],
                    [-106.80873803794384, 39.203549008816481, 2544.05078125],
                    [-106.808764189481735, 39.20407279394567, 2551.6640625],
                    [-106.808678861707449, 39.204148985445499, 2552.06640625],
                    [-106.808579117059708, 39.20417346060276, 2549.9609375],
                    [-106.808220958337188, 39.204132054001093, 2551.0390625],
                    [-106.807370949536562, 39.204471772536635, 2570.625],
                    [-106.806805087253451, 39.20463178306818, 2573.0],
                    [-106.806570058688521, 39.204669920727611, 2575.859375],
                    [-106.805990533903241, 39.204586520791054, 2585.7265625],
                    [-106.805495079606771, 39.204466491937637, 2588.4921875],
                    [-106.805489296093583, 39.204466743394732, 2588.515625],
                    [-106.805369686335325, 39.204417793080211, 2590.93359375],
                    [-106.805128287523985, 39.204251831397414, 2596.11328125],
                    [-106.804299568757415, 39.204033734276891, 2602.90234375],
                    [-106.80372565984726, 39.204024681821465, 2607.609375],
                    [-106.803071955218911, 39.204212352633476, 2609.265625],
                    [-106.802850170060992, 39.204216627404094, 2609.34375],
                    [-106.802138462662697, 39.20404982753098, 2616.48046875],
                    [-106.801864877343178, 39.204024681821465, 2618.3671875],
                    [-106.801573354750872, 39.204066675156355, 2620.3203125],
                    [-106.800971115007997, 39.204380577430129, 2622.484375],
                    [-106.800872376188636, 39.204413769766688, 2622.33203125],
                    [-106.798802800476551, 39.204953564330935, 2624.9921875],
                    [-106.798779666423798, 39.204960018396378, 2625.23828125],
                    [-106.798771871253848, 39.204974938184023, 2625.1328125],
                    [-106.797776101157069, 39.205221198499203, 2633.0],
                    [-106.797783561050892, 39.205229496583343, 2633.0],
                    [-106.797475107014179, 39.205137966200709, 2634.58203125],
                    [-106.797229601070285, 39.205040484666824, 2637.15625],
                    [-106.797244269400835, 39.2050417419523, 2637.06640625],
                    [-106.796785276383162, 39.204852562397718, 2640.78515625],
                    [-106.796711850911379, 39.204614600166678, 2643.55859375],
                    [-106.797192469239235, 39.204416619613767, 2643.1015625],
                    [-106.797631261870265, 39.204366412013769, 2641.44921875],
                    [-106.797998221591115, 39.204125516116619, 2642.625],
                    [-106.798401474952698, 39.203695356845856, 2647.3046875],
                    [-106.798730213195086, 39.203644981607795, 2648.14453125],
                    [-106.79907001554966, 39.203170817345381, 2656.76953125],
                    [-106.799165904521942, 39.203029330819845, 2659.4375],
                    [-106.798749407753348, 39.202793883159757, 2666.64453125],
                    [-106.798621248453856, 39.20264988206327, 2669.1796875],
                    [-106.798815624788404, 39.202453410252929, 2673.3046875],
                    [-106.799056939780712, 39.202209413051605, 2676.96484375],
                    [-106.799037661403418, 39.202066920697689, 2679.37890625],
                    [-106.798741864040494, 39.201883608475327, 2684.98828125],
                    [-106.798071563243866, 39.201595019549131, 2695.03515625],
                    [-106.797827566042542, 39.201216073706746, 2705.0078125],
                    [-106.797993695363402, 39.200728079304099, 2712.7734375],
                    [-106.797763109207153, 39.200482992455363, 2717.24609375],
                    [-106.797536965459585, 39.200281323865056, 2720.515625],
                    [-106.797825219109654, 39.200008073821664, 2725.9375],
                    [-106.798340538516641, 39.1999785695225, 2729.4140625],
                    [-106.798341628164053, 39.200031710788608, 2728.2109375],
                    [-106.799125168472528, 39.200319545343518, 2729.8125],
                    [-106.800092188641429, 39.20047159306705, 2732.60546875],
                    [-106.800951166078448, 39.200698407366872, 2728.15625],
                    [-106.802300065755844, 39.200697401538491, 2718.14453125],
                    [-106.802881266921759, 39.200331699103117, 2715.125],
                    [-106.80366413667798, 39.199879411607981, 2703.8828125],
                    [-106.804085075855255, 39.199438355863094, 2692.375],
                    [-106.804017769172788, 39.19921338558197, 2689.89453125],
                    [-106.803793385624886, 39.198679206892848, 2702.203125],
                    [-106.803876366466284, 39.19806414283812, 2701.453125],
                    [-106.803419468924403, 39.197402056306601, 2711.296875],
                    [-106.802618242800236, 39.197154538705945, 2725.171875],
                    [-106.801752895116806, 39.196786321699619, 2739.6015625],
                    [-106.801290800794959, 39.196243677288294, 2743.78125],
                    [-106.800236692652106, 39.195668930187821, 2757.0390625],
                    [-106.799371261149645, 39.195856349542737, 2774.01953125],
                    [-106.798683106899261, 39.195394925773144, 2791.8984375],
                    [-106.798644131049514, 39.19506786391139, 2798.15625],
                    [-106.798613118007779, 39.194895196706057, 2800.6875],
                    [-106.798574225977063, 39.194857813417912, 2802.125],
                    [-106.798157310113311, 39.194850940257311, 2812.6484375],
                    [-106.797467395663261, 39.195090997964144, 2825.47265625],
                    [-106.797258602455258, 39.195030815899372, 2831.4609375],
                    [-106.797403944656253, 39.194497894495726, 2841.04296875],
                    [-106.797568649053574, 39.19371678493917, 2854.88671875],
                    [-106.797534702345729, 39.193393997848034, 2855.3828125],
                    [-106.797101693227887, 39.193509751930833, 2866.67578125],
                    [-106.796749737113714, 39.193898336961865, 2880.85546875],
                    [-106.796548068523407, 39.194347523152828, 2881.9921875],
                    [-106.795876510441303, 39.194401670247316, 2897.17578125],
                    [-106.795194642618299, 39.194129426032305, 2914.6796875],
                    [-106.794014973565936, 39.193658111616969, 2934.6171875],
                    [-106.79265920072794, 39.193284614011645, 2962.71875],
                    [-106.79157248698175, 39.19314119964838, 2983.9453125],
                    [-106.790376305580139, 39.193398272618651, 3002.21875],
                    [-106.789529817178845, 39.19354822486639, 3021.43359375],
                    [-106.789041319862008, 39.194138227030635, 3028.86328125],
                    [-106.788444360718131, 39.19458364136517, 3037.046875],
                    [-106.78714600391686, 39.194998545572162, 3057.4375],
                    [-106.786563713103533, 39.195604976266623, 3066.85546875],
                    [-106.786265652626753, 39.196160696446896, 3074.51171875],
                    [-106.78677124902606, 39.196472754701972, 3074.00390625],
                    [-106.786940898746252, 39.196633519604802, 3073.171875],
                    [-106.786230197176337, 39.196802079677582, 3082.71875],
                    [-106.785460235551, 39.19677265919745, 3091.05078125],
                    [-106.784685328602791, 39.196743071079254, 3100.33984375],
                    [-106.784216109663248, 39.196338895708323, 3110.125],
                    [-106.783811347559094, 39.19609447941184, 3118.6171875],
                    [-106.783353025093675, 39.195899600163102, 3126.4765625],
                    [-106.782744582742453, 39.195731962099671, 3133.01953125],
                    [-106.782036228105426, 39.195674546062946, 3138.87109375],
                    [-106.78137824870646, 39.195679072290659, 3142.97265625],
                    [-106.780790258198977, 39.195655519142747, 3145.703125],
                    [-106.780443834140897, 39.195671277120709, 3146.0],
                    [-106.780210733413696, 39.195719389244914, 3146.0],
                    [-106.779781328514218, 39.195914017036557, 3146.0],
                    [-106.779008349403739, 39.195985179394484, 3150.05859375],
                    [-106.778200082480907, 39.195958273485303, 3158.0],
                    [-106.777280503883958, 39.195913262665272, 3160.4765625],
                    [-106.776362685486674, 39.195845872163773, 3167.328125],
                    [-106.776015590876341, 39.195848638191819, 3170.0],
                    [-106.775927664712071, 39.195797005668283, 3170.0],
                    [-106.775758350268006, 39.195515457540751, 3170.15234375],
                    [-106.775749297812581, 39.195365337654948, 3170.7109375],
                    [-106.775176394730806, 39.195294342935085, 3172.78515625],
                    [-106.774574909359217, 39.195168446749449, 3175.26171875],
                    [-106.774052549153566, 39.194917073473334, 3180.6953125],
                    [-106.773730767890811, 39.194730659946799, 3184.59375],
                    [-106.773459194228053, 39.194519771263003, 3188.6640625],
                    [-106.773283341899514, 39.194256830960512, 3191.90234375],
                    [-106.773220896720886, 39.19401777908206, 3193.26171875],
                    [-106.773166414350271, 39.193632882088423, 3196.82421875],
                    [-106.773168677464128, 39.19326483272016, 3200.1796875],
                    [-106.773122325539589, 39.192928802222013, 3202.7421875],
                    [-106.772965332493186, 39.192542312666774, 3205.37109375],
                    [-106.772841364145279, 39.192277109250426, 3205.95703125],
                    [-106.772692333906889, 39.192052977159619, 3207.1640625],
                    [-106.772611448541284, 39.191753910854459, 3205.61328125],
                    [-106.771630682051182, 39.190983530133963, 3203.6640625],
                    [-106.771327340975404, 39.190893089398742, 3203.83984375],
                    [-106.7710823379457, 39.190881857648492, 3205.23046875],
                    [-106.770877065137029, 39.190916223451495, 3208.5859375],
                    [-106.770488061010838, 39.190808096900582, 3211.01953125],
                    [-106.770213134586811, 39.190570302307606, 3209.05859375],
                    [-106.769893113523722, 39.190410627052188, 3207.03515625],
                    [-106.769527411088347, 39.190116506069899, 3202.05078125],
                    [-106.769352816045284, 39.189328271895647, 3196.109375],
                    [-106.769459014758468, 39.188849329948425, 3194.7265625],
                    [-106.769638806581497, 39.188464181497693, 3194.0],
                    [-106.769600166007876, 39.188134437426925, 3195.13671875],
                    [-106.76841001957655, 39.187532616779208, 3207.234375],
                    [-106.767798559740186, 39.187539741396904, 3211.91015625],
                    [-106.7670379858464, 39.187552481889725, 3219.203125],
                    [-106.766488971188664, 39.187480732798576, 3223.69921875],
                    [-106.765844486653805, 39.186734827235341, 3241.32421875],
                    [-106.765383901074529, 39.186502816155553, 3245.921875],
                    [-106.764807980507612, 39.18638102710247, 3255.28125],
                    [-106.764483097940683, 39.185973331332207, 3258.20703125],
                    [-106.764256367459893, 39.18543110601604, 3260.26171875],
                    [-106.763996109366417, 39.185153832659125, 3257.73828125],
                    [-106.763707268983126, 39.184978818520904, 3255.58203125],
                    [-106.762819373980165, 39.185483828186989, 3249.98828125],
                    [-106.762269018217921, 39.186033764854074, 3245.51953125],
                    [-106.761875068768859, 39.186544809490442, 3243.0],
                    [-106.761433174833655, 39.186761397868395, 3243.0],
                    [-106.761075351387262, 39.186778329312801, 3243.0],
                    [-106.760820541530848, 39.186626952141523, 3243.0],
                    [-106.76049767062068, 39.186058407649398, 3243.0],
                    [-106.76047864370048, 39.186088247224689, 3243.0],
                    [-106.759344069287181, 39.184649409726262, 3258.91796875],
                    [-106.758652646094561, 39.18387140147388, 3266.296875],
                    [-106.75813808105886, 39.182972190901637, 3290.703125],
                    [-106.758148642256856, 39.182435413822532, 3292.76953125],
                    [-106.757754273712635, 39.181794449687004, 3291.625],
                    [-106.757243564352393, 39.180960869416595, 3318.19921875],
                    [-106.756557757034898, 39.180459547787905, 3336.96875],
                    [-106.756239663809538, 39.179874490946531, 3341.0],
                    [-106.756265312433243, 39.179922938346863, 3340.80078125],
                    [-106.755911093205214, 39.17925339192152, 3340.98046875],
                    [-106.755412705242634, 39.178985338658094, 3336.47265625]
                  ],
                camera: [
                    [-106.839262221389959, 39.210935651022822],
                    [-106.787444541754653, 39.225546799517836],
                    [-106.727722470310567, 39.194647813356262]
                  ],
            },
            visible:false,
            description: 'The Benedict Hut was a decievingly difficult climb for us.  It was made longer by the fact that we had to park at the lower lot and had new snow to deal with.  When you look at the topo, there doesn\'t seem to be anything extrordinary, but the climb keeps going and takes it\'s toll.  As you climb, you can look back and watch Aspen move further away. Our trip took so long we had to pull out our headlamps to finish the way, and we quite relieved when we made it to the hut.  The hut itself is welcoming and very nice.  It definitely has one of the coolest bathrooms in the wilderness, with a giant window for your viewing pleasure.  It also came with sleds and a luge course, which was really fun.  There are great treks to take around the hut.',
            location: {
                center: [-106.77473, 39.20311],
                zoom: 13.55,
                pitch: 32.50,
                bearing: 143.20
            },
            onChapterEnter: [{
                layer: 'benedicts-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'benedicts-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'janetscabin',
            title: 'Janet\'s Cabin',
            image: '/img/janets.jpg',
            huttriplink: 'https://www.huttrip.com/2008/03/janets-cabin.html',
            hutorglink: 'https://www.huts.org/The_Huts/JanetsCabin.php',
            gpx: 'gpx/JanetsCabin.gpx',
            data: {
                route:          [
                    [-106.180480029433966, 39.487867020070553, 3298.911],
                    [-106.180542977526784, 39.487825026735663, 3297.447999999999865],
                    [-106.180542977526784, 39.487825026735663, 3297.447999999999865],
                    [-106.180565021932125, 39.487846987321973, 3297.936000000000149],
                    [-106.180565021932125, 39.488038010895252, 3294.583],
                    [-106.180565021932125, 39.488059971481562, 3295.070000000000164],
                    [-106.180565021932125, 39.488082015886903, 3294.583],
                    [-106.180652026087046, 39.488189974799752, 3294.0949999999998],
                    [-106.180737018585205, 39.488274967297912, 3293.119999999999891],
                    [-106.180844977498055, 39.488340010866523, 3293.119999999999891],
                    [-106.180908009409904, 39.488425003364682, 3292.175000000000182],
                    [-106.180952014401555, 39.488532962277532, 3288.333999999999833],
                    [-106.180995013564825, 39.488597000017762, 3287.847000000000207],
                    [-106.181016974151134, 39.488639999181032, 3286.414000000000215],
                    [-106.181037006899714, 39.488704958930612, 3286.414000000000215],
                    [-106.181057961657643, 39.488747036084533, 3285.926],
                    [-106.181080006062984, 39.488811995834112, 3284.951],
                    [-106.181080006062984, 39.488854994997382, 3284.951],
                    [-106.181167010217905, 39.489005031064153, 3284.005999999999858],
                    [-106.181207997724414, 39.489220026880503, 3281.110999999999876],
                    [-106.181252002716064, 39.489390011876822, 3280.623],
                    [-106.181252002716064, 39.489411972463131, 3280.623],
                    [-106.181338001042604, 39.489562008529902, 3279.677999999999884],
                    [-106.181402038782835, 39.489712966606021, 3278.244999999999891],
                    [-106.181466998532414, 39.489884963259101, 3275.35],
                    [-106.181445037946105, 39.489904996007681, 3275.838000000000193],
                    [-106.181488037109375, 39.489970039576292, 3275.35],
                    [-106.181551991030574, 39.490056959912181, 3274.373999999999796],
                    [-106.181573029607534, 39.490119991824031, 3272.454000000000178],
                    [-106.181573029607534, 39.490185035392642, 3271.509],
                    [-106.181573029607534, 39.490291988477111, 3270.045999999999822],
                    [-106.181551991030574, 39.490377986803651, 3268.126000000000204],
                    [-106.181531958281994, 39.490549983456731, 3267.181],
                    [-106.181531958281994, 39.49063497595489, 3267.668999999999869],
                    [-106.181509997695684, 39.490700019523501, 3266.694],
                    [-106.181509997695684, 39.490785012021661, 3264.773000000000138],
                    [-106.181509997695684, 39.490828011184931, 3263.340999999999894],
                    [-106.181509997695684, 39.490828011184931, 3261.42],
                    [-106.181488037109375, 39.490915015339851, 3260.933],
                    [-106.181466998532414, 39.491087011992931, 3259.013],
                    [-106.181445037946105, 39.49110696092248, 3258.525],
                    [-106.181402038782835, 39.491215003654361, 3258.036999999999807],
                    [-106.181445037946105, 39.49123696424067, 3257.550000000000182],
                    [-106.181488037109375, 39.49119295924902, 3257.092],
                    [-106.181488037109375, 39.49119295924902, 3258.525],
                    [-106.181703032925725, 39.49129999615252, 3257.092],
                    [-106.181722981855273, 39.49129999615252, 3256.605],
                    [-106.181788025423884, 39.491387000307441, 3259.95699999999988],
                    [-106.181809986010194, 39.491365039721131, 3260.445000000000164],
                    [-106.181894978508353, 39.491429999470711, 3259.95699999999988],
                    [-106.181981982663274, 39.491493031382561, 3258.525],
                    [-106.182152973487973, 39.491580035537481, 3258.036999999999807],
                    [-106.182324970141053, 39.491601996123791, 3257.550000000000182],
                    [-106.182324970141053, 39.491601996123791, 3258.036999999999807],
                    [-106.182562010362744, 39.49155799113214, 3257.550000000000182],
                    [-106.182797038927674, 39.491580035537481, 3257.550000000000182],
                    [-106.182883037254214, 39.491622028872371, 3257.550000000000182],
                    [-106.182989990338683, 39.491665028035641, 3256.605],
                    [-106.183227030560374, 39.49168698862195, 3257.550000000000182],
                    [-106.183355022221804, 39.49168698862195, 3257.092],
                    [-106.183570018038154, 39.491665028035641, 3257.550000000000182],
                    [-106.183847961947322, 39.49172998778522, 3254.684000000000196],
                    [-106.184126995503902, 39.491880023851991, 3252.764000000000124],
                    [-106.184256998822093, 39.491923023015261, 3250.356000000000222],
                    [-106.184256998822093, 39.491923023015261, 3250.844],
                    [-106.184320030733943, 39.491923023015261, 3248.924],
                    [-106.184512982144952, 39.49198697693646, 3246.027999999999793],
                    [-106.184512982144952, 39.49198697693646, 3246.027999999999793],
                    [-106.184812970459461, 39.49198697693646, 3244.108000000000175],
                    [-106.184899974614382, 39.49198697693646, 3243.619999999999891],
                    [-106.185007011517882, 39.49198697693646, 3243.132],
                    [-106.185135003179312, 39.49198697693646, 3242.188000000000102],
                    [-106.185222007334232, 39.49194498360157, 3240.266999999999825],
                    [-106.185458041727543, 39.49185797944665, 3238.804],
                    [-106.185521995648742, 39.49179301969707, 3236.884],
                    [-106.185543034225702, 39.49177298694849, 3235.938999999999851],
                    [-106.185692986473441, 39.491622028872371, 3234.018999999999778],
                    [-106.185843022540212, 39.491429999470711, 3233.043999999999869],
                    [-106.186015019193292, 39.49123696424067, 3229.203],
                    [-106.186015019193292, 39.491215003654361, 3229.690999999999804],
                    [-106.186251975595951, 39.491000007838011, 3226.308],
                    [-106.186488009989262, 39.49076296761632, 3223.442],
                    [-106.186488009989262, 39.490743018686771, 3224.875],
                    [-106.186488009989262, 39.490743018686771, 3224.875],
                    [-106.18650795891881, 39.490721980109811, 3226.795],
                    [-106.186530003324151, 39.490572027862072, 3227.283],
                    [-106.18655196391046, 39.490400031208992, 3228.257999999999811],
                    [-106.186617007479072, 39.490270027890801, 3229.690999999999804],
                    [-106.186723038554192, 39.490056959912181, 3231.123],
                    [-106.186895035207272, 39.48979795910418, 3231.610999999999876],
                    [-106.187002994120121, 39.489669967442751, 3232.09900000000016],
                    [-106.187195023521781, 39.489454971626401, 3231.610999999999876],
                    [-106.18721698410809, 39.489411972463131, 3231.123],
                    [-106.187325026839972, 39.489177027717233, 3232.09900000000016],
                    [-106.187325026839972, 39.488982986658812, 3234.018999999999778],
                    [-106.187410019338131, 39.488724991679192, 3234.964],
                    [-106.18753801099956, 39.488661959767342, 3234.964],
                    [-106.187902959063649, 39.488597000017762, 3232.556],
                    [-106.18796800263226, 39.488618038594723, 3232.09900000000016],
                    [-106.18803296238184, 39.488682998344302, 3231.610999999999876],
                    [-106.18803296238184, 39.488854994997382, 3230.177999999999884],
                    [-106.18816195987165, 39.488854994997382, 3229.203],
                    [-106.18831199593842, 39.488811995834112, 3227.283],
                    [-106.188377039507031, 39.488811995834112, 3225.362999999999829],
                    [-106.18839698843658, 39.488790035247803, 3223.929999999999836],
                    [-106.1886549834162, 39.488747036084533, 3222.955],
                    [-106.1887620203197, 39.488639999181032, 3220.547],
                    [-106.18880501948297, 39.488597000017762, 3220.547],
                    [-106.18884801864624, 39.488512007519603, 3220.059000000000196],
                    [-106.188911972567439, 39.488489963114262, 3220.059000000000196],
                    [-106.188997970893979, 39.488468002527952, 3219.601999999999862],
                    [-106.18904197588563, 39.488425003364682, 3219.114],
                    [-106.18906301446259, 39.488402958959341, 3219.601999999999862],
                    [-106.18910500779748, 39.488361971452832, 3217.681999999999789],
                    [-106.18932000361383, 39.488168014213443, 3217.194],
                    [-106.189447995275259, 39.488146975636482, 3214.786],
                    [-106.18949200026691, 39.488102970644832, 3212.866],
                    [-106.189556960016489, 39.487910019233823, 3212.866],
                    [-106.18959803134203, 39.487846987321973, 3212.866],
                    [-106.18968503549695, 39.487846987321973, 3213.353999999999814],
                    [-106.18970699608326, 39.487847993150353, 3214.116],
                    [-106.189791988581419, 39.487847993150353, 3214.20699999999988],
                    [-106.189791988581419, 39.487846987321973, 3214.297999999999774],
                    [-106.189791988581419, 39.487867020070553, 3215.273999999999887],
                    [-106.189834987744689, 39.487825026735663, 3214.786],
                    [-106.189834987744689, 39.487846987321973, 3215.761],
                    [-106.18985703215003, 39.487846987321973, 3215.761],
                    [-106.18985703215003, 39.487846987321973, 3215.273999999999887],
                    [-106.189877986907959, 39.487825026735663, 3215.273999999999887],
                    [-106.189877986907959, 39.487802982330322, 3215.273999999999887],
                    [-106.18998502381146, 39.487695023417473, 3217.194],
                    [-106.190006984397769, 39.487695023417473, 3217.681999999999789],
                    [-106.19024301879108, 39.487587986513972, 3218.627],
                    [-106.190349971875548, 39.487544987350702, 3219.114],
                    [-106.190436976030469, 39.487502994015813, 3219.114],
                    [-106.190521968528628, 39.487459994852543, 3217.681999999999789],
                    [-106.190543007105589, 39.487438034266233, 3218.627],
                    [-106.190587012097239, 39.487416995689273, 3218.139000000000124],
                    [-106.190736964344978, 39.487372990697622, 3216.706000000000131],
                    [-106.190758002921939, 39.487352035939693, 3215.273999999999887],
                    [-106.190758002921939, 39.487332003191113, 3215.273999999999887],
                    [-106.190758002921939, 39.487332003191113, 3216.219],
                    [-106.190779963508248, 39.487309958785772, 3214.297999999999774],
                    [-106.190779963508248, 39.487309958785772, 3214.786],
                    [-106.190865039825439, 39.487180039286613, 3217.194],
                    [-106.190887000411749, 39.487160006538033, 3217.681999999999789],
                    [-106.190908038988709, 39.487137962132692, 3217.194],
                    [-106.190951960161328, 39.487117007374763, 3216.706000000000131],
                    [-106.190951960161328, 39.487094962969422, 3216.219],
                    [-106.190971992909908, 39.487030003219843, 3217.194],
                    [-106.191014992073178, 39.486988009884953, 3217.194],
                    [-106.191080035641789, 39.486966971307993, 3216.219],
                    [-106.191080035641789, 39.486902011558414, 3217.194],
                    [-106.191080035641789, 39.486879967153072, 3217.194],
                    [-106.191080035641789, 39.486858006566763, 3216.219],
                    [-106.191080035641789, 39.486817019060254, 3216.706000000000131],
                    [-106.191123034805059, 39.486794974654913, 3216.706000000000131],
                    [-106.191186988726258, 39.486751975491643, 3217.681999999999789],
                    [-106.191252032294869, 39.486707970499992, 3219.601999999999862],
                    [-106.191314980387688, 39.486664971336722, 3221.034000000000106],
                    [-106.191337024793029, 39.486536979675293, 3220.547],
                    [-106.191401984542608, 39.486473025754094, 3221.522],
                    [-106.191444983705878, 39.486407982185483, 3223.442],
                    [-106.191508015617728, 39.486387027427554, 3223.442],
                    [-106.191508015617728, 39.486364983022213, 3222.467],
                    [-106.191552020609379, 39.486130038276315, 3225.85],
                    [-106.191638018935919, 39.485892998054624, 3225.85],
                    [-106.191872963681817, 39.485656963661313, 3227.283],
                    [-106.192067004740238, 39.485463006421924, 3227.771000000000186],
                    [-106.192151997238398, 39.485335014760494, 3228.715000000000146],
                    [-106.192388031631708, 39.485141979530454, 3232.09900000000016],
                    [-106.192537983879447, 39.485012982040644, 3234.964],
                    [-106.192732024937868, 39.484927989542484, 3238.347000000000207],
                    [-106.192947020754218, 39.484777031466365, 3239.7800000000002],
                    [-106.193162016570568, 39.484648033976555, 3243.132],
                    [-106.193290008231997, 39.484585002064705, 3244.596],
                    [-106.193462004885077, 39.484542002901435, 3247.460999999999785],
                    [-106.193546997383237, 39.484413005411625, 3246.516],
                    [-106.193677000701427, 39.484261963516474, 3249.869000000000142],
                    [-106.193847991526127, 39.484176971018314, 3251.789000000000215],
                    [-106.194083020091057, 39.483917970210314, 3254.197000000000116],
                    [-106.194383008405566, 39.483597027137876, 3254.197000000000116],
                    [-106.194662963971496, 39.483425030484796, 3256.117000000000189],
                    [-106.194791961461306, 39.483317993581295, 3253.252],
                    [-106.194791961461306, 39.483297038823366, 3252.764000000000124],
                    [-106.195027995854616, 39.483190001919866, 3253.252],
                    [-106.195284985005856, 39.483018005266786, 3255.629],
                    [-106.195607017725706, 39.482909962534904, 3257.550000000000182],
                    [-106.195886973291636, 39.482847014442086, 3259.013],
                    [-106.196122001856565, 39.482760010287166, 3261.878000000000156],
                    [-106.196422995999455, 39.482696978375316, 3264.286],
                    [-106.196658024564385, 39.482717011123896, 3267.668999999999869],
                    [-106.196702029556036, 39.482717011123896, 3267.181],
                    [-106.196937980130315, 39.482717011123896, 3270.045999999999822],
                    [-106.197088016197085, 39.482696978375316, 3271.022],
                    [-106.197130009531975, 39.482675017789006, 3271.022],
                    [-106.197173008695245, 39.482652973383665, 3271.022],
                    [-106.197366965934634, 39.482566975057125, 3271.996999999999844],
                    [-106.197538040578365, 39.482460021972656, 3272.454000000000178],
                    [-106.197902988642454, 39.482245026156306, 3272.942],
                    [-106.197946993634105, 39.482203032821417, 3272.942],
                    [-106.198031986132264, 39.482181994244456, 3274.862],
                    [-106.198312025517225, 39.482117034494877, 3277.757999999999811],
                    [-106.198653001338243, 39.482094990089536, 3279.19],
                    [-106.198890041559935, 39.482094990089536, 3283.518],
                    [-106.199039993807673, 39.482094990089536, 3282.54300000000012],
                    [-106.199382981285453, 39.482051990926266, 3284.494000000000142],
                    [-106.199682969599962, 39.481966998428106, 3285.926],
                    [-106.200026962906122, 39.481902038678527, 3287.847000000000207],
                    [-106.200391994789243, 39.481838000938296, 3289.279],
                    [-106.200649989768863, 39.481795001775026, 3290.742000000000189],
                    [-106.200672034174204, 39.481795001775026, 3290.255000000000109],
                    [-106.200800025835633, 39.481752002611756, 3290.255000000000109],
                    [-106.200864985585213, 39.481729958206415, 3291.686999999999898],
                    [-106.200885018333793, 39.481729958206415, 3291.686999999999898],
                    [-106.201077969744802, 39.481667010113597, 3291.199],
                    [-106.201507961377501, 39.481557961553335, 3292.661999999999807],
                    [-106.201852038502693, 39.481495013460517, 3293.119999999999891],
                    [-106.201937031000853, 39.481472969055176, 3293.119999999999891],
                    [-106.202238025143743, 39.481386970728636, 3294.583],
                    [-106.202472969889641, 39.481301978230476, 3296.503000000000156],
                    [-106.202601967379451, 39.481280017644167, 3296.503000000000156],
                    [-106.202667010948062, 39.481257973238826, 3296.014999999999873],
                    [-106.20277295820415, 39.481237018480897, 3296.991],
                    [-106.202925005927682, 39.481193013489246, 3298.422999999999774],
                    [-106.202945038676262, 39.481172980740666, 3298.422999999999774],
                    [-106.202945038676262, 39.481172980740666, 3298.422999999999774],
                    [-106.202945038676262, 39.481193013489246, 3298.422999999999774],
                    [-106.202945038676262, 39.481193013489246, 3299.368],
                    [-106.203117035329342, 39.481129981577396, 3299.368],
                    [-106.203288026154041, 39.481065021827817, 3300.342999999999847],
                    [-106.203524982556701, 39.480914985761046, 3301.319],
                    [-106.203696979209781, 39.480829993262887, 3301.77599999999984],
                    [-106.203782977536321, 39.480786994099617, 3302.264000000000124],
                    [-106.203803010284901, 39.480765033513308, 3303.239],
                    [-106.20386797003448, 39.480742989107966, 3304.184000000000196],
                    [-106.204167958348989, 39.480722034350038, 3307.567],
                    [-106.204404998570681, 39.480678029358387, 3307.567],
                    [-106.204642038792372, 39.480593036860228, 3310.431999999999789],
                    [-106.20491998270154, 39.480464961379766, 3311.864999999999782],
                    [-106.205113017931581, 39.480378041043878, 3313.328],
                    [-106.205328013747931, 39.480249965563416, 3314.273000000000138],
                    [-106.205562958493829, 39.480100013315678, 3313.815999999999804],
                    [-106.20562800206244, 39.480057014152408, 3313.328],
                    [-106.20579999871552, 39.479991970583797, 3314.760000000000218],
                    [-106.206056987866759, 39.479819973930717, 3315.735999999999876],
                    [-106.20620702393353, 39.479734981432557, 3317.16800000000012],
                    [-106.206527967005968, 39.479606989771128, 3319.576],
                    [-106.20685201138258, 39.479476986452937, 3318.601000000000113],
                    [-106.20713003911078, 39.479476986452937, 3321.009],
                    [-106.207451988011599, 39.479413032531738, 3321.984],
                    [-106.207773014903069, 39.479285040870309, 3324.84900000000016],
                    [-106.208137962967157, 39.479132993146777, 3327.257],
                    [-106.208160007372499, 39.479132993146777, 3327.257],
                    [-106.208352958783507, 39.479026962071657, 3327.744999999999891],
                    [-106.208525039255619, 39.478962002322078, 3331.098],
                    [-106.208740035071969, 39.478920008987188, 3332.561000000000149],
                    [-106.209083022549748, 39.478833004832268, 3334.938000000000102],
                    [-106.209361972287297, 39.478748012334108, 3336.858000000000175],
                    [-106.209618039429188, 39.478662014007568, 3338.809000000000196],
                    [-106.209854995831847, 39.478533016517758, 3341.186999999999898],
                    [-106.209897994995117, 39.478511977940798, 3341.186999999999898],
                    [-106.210026992484927, 39.478296982124448, 3344.08199999999988],
                    [-106.210241988301277, 39.47806203737855, 3341.674],
                    [-106.210392024368048, 39.477867996320128, 3341.186999999999898],
                    [-106.210412979125977, 39.4778470415622, 3342.161999999999807],
                    [-106.210627974942327, 39.477740004658699, 3341.674],
                    [-106.211035000160336, 39.477674961090088, 3343.107],
                    [-106.211315039545298, 39.477653000503778, 3346.489999999999782],
                    [-106.211615027859807, 39.477568008005619, 3348.898000000000138],
                    [-106.211721980944276, 39.477546969428658, 3348.898000000000138],
                    [-106.211807979270816, 39.477546969428658, 3350.331000000000131],
                    [-106.212021969258785, 39.477525008842349, 3353.226000000000113],
                    [-106.212064968422055, 39.477525008842349, 3353.683],
                    [-106.212342996150255, 39.477502964437008, 3355.146000000000186],
                    [-106.212601996958256, 39.477482009679079, 3357.554],
                    [-106.212838031351566, 39.477459965273738, 3359.475],
                    [-106.213138019666076, 39.477416966110468, 3361.851999999999862],
                    [-106.213353015482426, 39.47739701718092, 3365.235000000000127],
                    [-106.213544961065054, 39.477416966110468, 3368.130999999999858],
                    [-106.213544961065054, 39.477416966110468, 3368.130999999999858],
                    [-106.213825000450015, 39.477267013862729, 3369.563999999999851],
                    [-106.214103028178215, 39.477159976959229, 3371.971],
                    [-106.214167987927794, 39.477095017209649, 3384.925000000000182],
                    [-106.214124988764524, 39.477116977795959, 3385.413],
                    [-106.214103028178215, 39.477095017209649, 3386.388],
                    [-106.214060029014945, 39.477116977795959, 3384.925000000000182],
                    [-106.214081989601254, 39.47701002471149, 3384.467999999999847],
                    [-106.214167987927794, 39.476987980306149, 3383.005000000000109],
                    [-106.214190032333136, 39.476923020556569, 3384.467999999999847],
                    [-106.214190032333136, 39.476901981979609, 3384.467999999999847],
                    [-106.214211992919445, 39.476859988644719, 3384.467999999999847],
                    [-106.214211992919445, 39.47683802805841, 3384.925000000000182],
                    [-106.214232025668025, 39.47683802805841, 3385.90099999999984],
                    [-106.214232025668025, 39.476816989481449, 3386.388],
                    [-106.214252980425954, 39.47679502889514, 3386.876000000000204],
                    [-106.214275024831295, 39.476772984489799, 3387.333],
                    [-106.214318023994565, 39.47675202973187, 3387.821],
                    [-106.214447021484375, 39.47671003639698, 3388.795999999999822],
                    [-106.214490020647645, 39.47662303224206, 3388.309000000000196],
                    [-106.214490020647645, 39.47662303224206, 3387.821],
                    [-106.214467976242304, 39.4765380397439, 3390.228999999999814],
                    [-106.214382983744144, 39.4765380397439, 3389.741],
                    [-106.214382983744144, 39.47649504058063, 3389.741],
                    [-106.214403016492724, 39.47649504058063, 3389.254],
                    [-106.214490020647645, 39.47640803642571, 3389.741],
                    [-106.214554980397224, 39.476322960108519, 3390.717],
                    [-106.214575013145804, 39.47630200535059, 3390.717],
                    [-106.214575013145804, 39.476258000358939, 3390.228999999999814],
                    [-106.214618012309074, 39.476194968447089, 3390.717],
                    [-106.214726977050304, 39.476107964292169, 3389.741],
                    [-106.214747009798884, 39.476064965128899, 3390.228999999999814],
                    [-106.214833008125424, 39.475936973467469, 3389.741],
                    [-106.214939961209893, 39.475830020383, 3389.254],
                    [-106.214982960373163, 39.475764976814389, 3388.795999999999822],
                    [-106.215177001431584, 39.475592980161309, 3388.795999999999822],
                    [-106.215241961181164, 39.47550798766315, 3387.333],
                    [-106.215261993929744, 39.475530032068491, 3387.333],
                    [-106.215304993093014, 39.47550798766315, 3387.821],
                    [-106.215391997247934, 39.47544302791357, 3387.333],
                    [-106.215413035824895, 39.47542198933661, 3387.333],
                    [-106.215455029159784, 39.47533699683845, 3386.876000000000204],
                    [-106.215476989746094, 39.4752929918468, 3385.90099999999984],
                    [-106.215498028323054, 39.47524999268353, 3384.925000000000182],
                    [-106.215498028323054, 39.47518696077168, 3384.467999999999847],
                    [-106.215498028323054, 39.47516500018537, 3383.981000000000222],
                    [-106.215519988909364, 39.47516500018537, 3384.925000000000182],
                    [-106.215542033314705, 39.475143039599061, 3385.413],
                    [-106.215542033314705, 39.475100040435791, 3386.388],
                    [-106.215562988072634, 39.47507799603045, 3387.333],
                    [-106.215585032477975, 39.475057041272521, 3386.388],
                    [-106.215585032477975, 39.47503499686718, 3387.821],
                    [-106.215604981407523, 39.4750149641186, 3388.309000000000196],
                    [-106.215627025812864, 39.47497196495533, 3388.309000000000196],
                    [-106.215647980570793, 39.474927959963679, 3388.795999999999822],
                    [-106.215647980570793, 39.474927959963679, 3388.795999999999822],
                    [-106.215670024976134, 39.474884960800409, 3389.254],
                    [-106.215647980570793, 39.474884960800409, 3387.821],
                    [-106.215670024976134, 39.474884960800409, 3387.821],
                    [-106.215670024976134, 39.474884960800409, 3387.821],
                    [-106.215647980570793, 39.474884960800409, 3388.309000000000196],
                    [-106.215647980570793, 39.4748630002141, 3388.795999999999822],
                    [-106.215691985562444, 39.47479996830225, 3389.254],
                    [-106.215713024139404, 39.47477800771594, 3388.795999999999822],
                    [-106.215734984725714, 39.47473500855267, 3389.741],
                    [-106.215798016637564, 39.47462797164917, 3390.717],
                    [-106.215862976387143, 39.47452001273632, 3391.661999999999807],
                    [-106.215885020792484, 39.47452001273632, 3391.661999999999807],
                    [-106.215906981378794, 39.474478019401431, 3392.148999999999887],
                    [-106.215928019955754, 39.474392021074891, 3392.637000000000171],
                    [-106.215970013290644, 39.474348016083241, 3392.637000000000171],
                    [-106.216013012453914, 39.474307028576732, 3394.556999999999789],
                    [-106.216013012453914, 39.474307028576732, 3394.069],
                    [-106.216057017445564, 39.474263023585081, 3394.556999999999789],
                    [-106.216057017445564, 39.474263023585081, 3395.502],
                    [-106.216077972203493, 39.474263023585081, 3395.502],
                    [-106.216142009943724, 39.474241985008121, 3394.556999999999789],
                    [-106.216185009106994, 39.474220024421811, 3397.422],
                    [-106.216786997392774, 39.474048027768731, 3404.646000000000186],
                    [-106.216957988217473, 39.473984995856881, 3405.134],
                    [-106.216999981552362, 39.473963035270572, 3405.621],
                    [-106.216999981552362, 39.473920036107302, 3406.079000000000178],
                    [-106.217065025120974, 39.473877036944032, 3406.079000000000178],
                    [-106.217171978205442, 39.473854992538691, 3406.565999999999804],
                    [-106.217237021774054, 39.473854992538691, 3407.542],
                    [-106.217280020937324, 39.473791960626841, 3407.998999999999796],
                    [-106.217301981523633, 39.473727000877261, 3408.97400000000016],
                    [-106.217365013435483, 39.473662041127682, 3409.918999999999869],
                    [-106.217408012598753, 39.473642008379102, 3409.918999999999869],
                    [-106.217580009251833, 39.473555004224181, 3411.382],
                    [-106.217623008415103, 39.473512005060911, 3411.869999999999891],
                    [-106.217708000913262, 39.473470011726022, 3412.327000000000226],
                    [-106.217729961499572, 39.473427012562752, 3412.327000000000226],
                    [-106.217772960662842, 39.473361968994141, 3412.327000000000226],
                    [-106.217901958152652, 39.473298015072942, 3412.815],
                    [-106.217922996729612, 39.473276976495981, 3413.302000000000135],
                    [-106.217967001721263, 39.473212016746402, 3413.302000000000135],
                    [-106.218007989227772, 39.473189972341061, 3413.79],
                    [-106.218051994219422, 39.473146973177791, 3413.79],
                    [-106.218179985880852, 39.473061980679631, 3414.246999999999844],
                    [-106.218266990035772, 39.472997020930052, 3414.735000000000127],
                    [-106.218309989199042, 39.472912028431892, 3414.735000000000127],
                    [-106.218351982533932, 39.472868023440242, 3415.223],
                    [-106.218460025265813, 39.472740031778812, 3417.630000000000109],
                    [-106.218588016927242, 39.472717987373471, 3417.143],
                    [-106.218652976676822, 39.472674988210201, 3416.167],
                    [-106.218737969174981, 39.472612040117383, 3416.167],
                    [-106.218909965828061, 39.472502991557121, 3416.6550000000002],
                    [-106.219017002731562, 39.472417999058962, 3416.167],
                    [-106.219103001058102, 39.472396960482001, 3417.143],
                    [-106.219210037961602, 39.472332000732422, 3417.143],
                    [-106.219296958297491, 39.472310040146112, 3415.71],
                    [-106.219340041279793, 39.472287995740771, 3416.167],
                    [-106.219531986862421, 39.472267962992191, 3418.118],
                    [-106.219531986862421, 39.472267962992191, 3418.574999999999818],
                    [-106.219553025439382, 39.472247008234262, 3418.118],
                    [-106.219661990180612, 39.472203003242612, 3417.630000000000109],
                    [-106.219897018745542, 39.472137959674001, 3419.063000000000102],
                    [-106.219961978495121, 39.472137959674001, 3420.038],
                    [-106.220027022063732, 39.472137959674001, 3420.038],
                    [-106.22013296931982, 39.472052967175841, 3421.471],
                    [-106.22021796181798, 39.471988007426262, 3422.904],
                    [-106.22026196680963, 39.471924975514412, 3423.391],
                    [-106.22026196680963, 39.471924975514412, 3423.391],
                    [-106.22038995847106, 39.471860015764832, 3422.904],
                    [-106.220562038943172, 39.471817016601562, 3423.879],
                    [-106.2205829937011, 39.471794972196221, 3423.879],
                    [-106.2205829937011, 39.471794972196221, 3423.879],
                    [-106.220648037269711, 39.471709979698062, 3424.824],
                    [-106.220648037269711, 39.471688019111753, 3426.286999999999807],
                    [-106.220712997019291, 39.471666980534792, 3424.367000000000189],
                    [-106.220733029767871, 39.471645019948483, 3424.367000000000189],
                    [-106.220777034759521, 39.471666980534792, 3425.311000000000149],
                    [-106.220820033922791, 39.471622975543141, 3426.286999999999807],
                    [-106.220905026420951, 39.471581988036633, 3426.286999999999807],
                    [-106.22092698700726, 39.471537983044982, 3427.719],
                    [-106.22096998617053, 39.471517028287053, 3426.286999999999807],
                    [-106.2210129853338, 39.471430024132133, 3427.232],
                    [-106.221077023074031, 39.471388030797243, 3428.20699999999988],
                    [-106.221207026392221, 39.471279988065362, 3427.719],
                    [-106.221207026392221, 39.471237994730473, 3430.127],
                    [-106.22122798115015, 39.471237994730473, 3429.639999999999873],
                    [-106.22122798115015, 39.471237994730473, 3429.639999999999873],
                    [-106.22124801389873, 39.471217039972544, 3429.639999999999873],
                    [-106.22139997780323, 39.471194995567203, 3429.152],
                    [-106.221550013870001, 39.471107991412282, 3430.614999999999782],
                    [-106.221550013870001, 39.471107991412282, 3430.614999999999782],
                    [-106.22157197445631, 39.471067003905773, 3430.127],
                    [-106.221612961962819, 39.471044959500432, 3430.614999999999782],
                    [-106.22167800553143, 39.471001960337162, 3429.639999999999873],
                    [-106.221722010523081, 39.470979999750853, 3430.614999999999782],
                    [-106.22174296528101, 39.470979999750853, 3430.614999999999782],
                    [-106.221784958615899, 39.470915040001273, 3430.127],
                    [-106.221828041598201, 39.470895007252693, 3431.072000000000116],
                    [-106.22185000218451, 39.470872962847352, 3431.072000000000116],
                    [-106.22187196277082, 39.470852008089423, 3431.072000000000116],
                    [-106.22193499468267, 39.470829963684082, 3430.614999999999782],
                    [-106.22197799384594, 39.470829963684082, 3430.614999999999782],
                    [-106.222043037414551, 39.470786964520812, 3431.072000000000116],
                    [-106.22208695858717, 39.470765003934503, 3430.127],
                    [-106.22212802991271, 39.470701972022653, 3431.072000000000116],
                    [-106.22212802991271, 39.470614967867732, 3432.534999999999854],
                    [-106.22206499800086, 39.470529975369573, 3434.455],
                    [-106.22208695858717, 39.470508014783263, 3434.455],
                    [-106.222215034067631, 39.470442971214652, 3433.48],
                    [-106.22230002656579, 39.470380023121834, 3432.534999999999854],
                    [-106.2223219871521, 39.470357978716493, 3432.992000000000189],
                    [-106.22236498631537, 39.470337023958564, 3432.534999999999854],
                    [-106.222387030720711, 39.470314979553223, 3432.534999999999854],
                    [-106.22247202321887, 39.470293018966913, 3432.047999999999774],
                    [-106.22255802154541, 39.470250019803643, 3432.534999999999854],
                    [-106.222602026537061, 39.470227975398302, 3432.992000000000189],
                    [-106.22264301404357, 39.470208026468754, 3433.967999999999847],
                    [-106.22273001819849, 39.470142982900143, 3433.967999999999847],
                    [-106.22281501069665, 39.470078023150563, 3433.967999999999847],
                    [-106.222879970446229, 39.470056984573603, 3433.967999999999847],
                    [-106.222922969609499, 39.470037035644054, 3433.967999999999847],
                    [-106.222964962944388, 39.470014991238713, 3433.967999999999847],
                    [-106.223007962107658, 39.469950031489134, 3434.913],
                    [-106.22311701066792, 39.469884987920523, 3435.4],
                    [-106.22320200316608, 39.469822039827704, 3435.888],
                    [-106.22328800149262, 39.469735035672784, 3436.862999999999829],
                    [-106.223329994827509, 39.469712991267443, 3437.808],
                    [-106.22339503839612, 39.469649959355593, 3437.808],
                    [-106.22343803755939, 39.469627998769283, 3438.784000000000106],
                    [-106.223501991480589, 39.469606960192323, 3439.241],
                    [-106.223523030057549, 39.469584999606013, 3439.241],
                    [-106.223544990643859, 39.469563039019704, 3439.241],
                    [-106.22361003421247, 39.469521967694163, 3440.215999999999894],
                    [-106.223631994798779, 39.469521967694163, 3441.161],
                    [-106.223631994798779, 39.469500007107854, 3441.648999999999887],
                    [-106.223672982305288, 39.469477962702513, 3443.112],
                    [-106.22378203086555, 39.469391964375973, 3442.136],
                    [-106.223823018372059, 39.469349971041083, 3442.623999999999796],
                    [-106.223844978958368, 39.469328010454774, 3443.112],
                    [-106.223887978121638, 39.469285011291504, 3442.623999999999796],
                    [-106.223931983113289, 39.469262966886163, 3441.648999999999887],
                    [-106.223974982276559, 39.469242012128234, 3442.136],
                    [-106.224016975611448, 39.469198007136583, 3443.112],
                    [-106.224038014188409, 39.469177974388003, 3443.569],
                    [-106.224082019180059, 39.469113014638424, 3444.543999999999869],
                    [-106.224146978929639, 39.469047971069813, 3445.976999999999862],
                    [-106.224210010841489, 39.469006983563304, 3446.465000000000146],
                    [-106.224231971427798, 39.468962978571653, 3446.952000000000226],
                    [-106.224274970591068, 39.468962978571653, 3446.952000000000226],
                    [-106.224297014996409, 39.468942023813725, 3446.952000000000226],
                    [-106.224359963089228, 39.468942023813725, 3446.952000000000226],
                    [-106.224425006657839, 39.468898018822074, 3446.465000000000146],
                    [-106.224468005821109, 39.468898018822074, 3446.465000000000146],
                    [-106.224531959742308, 39.468898018822074, 3443.112],
                    [-106.224552998319268, 39.468898018822074, 3445.976999999999862],
                    [-106.224597003310919, 39.468898018822074, 3446.465000000000146],
                    [-106.224597003310919, 39.468898018822074, 3448.385000000000218],
                    [-106.224618041887879, 39.468876980245113, 3446.952000000000226],
                    [-106.224618041887879, 39.468876980245113, 3448.385000000000218],
                    [-106.224597003310919, 39.468791987746954, 3449.360000000000127],
                    [-106.224597003310919, 39.468791987746954, 3450.304999999999836],
                    [-106.224661963060498, 39.468727027997375, 3453.688000000000102],
                    [-106.224724994972348, 39.468685034662485, 3453.688000000000102],
                    [-106.224747039377689, 39.468642035499215, 3453.688000000000102],
                    [-106.224833037704229, 39.468598030507565, 3453.688000000000102],
                    [-106.224918030202389, 39.468513038009405, 3453.688000000000102],
                    [-106.224962035194039, 39.468404995277524, 3456.065999999999804],
                    [-106.225024983286858, 39.468341963365674, 3457.041000000000167],
                    [-106.225090026855469, 39.468297958374023, 3457.041000000000167],
                    [-106.225154986605048, 39.468277003616095, 3456.552999999999884],
                    [-106.225262023508549, 39.468148006126285, 3458.47400000000016],
                    [-106.225305022671819, 39.468148006126285, 3457.529],
                    [-106.225326983258128, 39.468189999461174, 3456.065999999999804],
                    [-106.225433014333248, 39.468169966712594, 3455.121],
                    [-106.225539967417717, 39.468126967549324, 3454.632999999999811],
                    [-106.225648010149598, 39.468126967549324, 3452.713000000000193],
                    [-106.225690003484488, 39.468082962557673, 3454.146000000000186],
                    [-106.225777007639408, 39.468018002808094, 3455.121],
                    [-106.225904999300838, 39.467977015301585, 3455.609],
                    [-106.225947998464108, 39.467954970896244, 3455.121],
                    [-106.226076995953918, 39.467933010309935, 3455.121],
                    [-106.226162994280457, 39.467890011146665, 3455.121],
                    [-106.226270031183958, 39.467847011983395, 3455.609],
                    [-106.226313030347228, 39.467890011146665, 3455.609],
                    [-106.226334990933537, 39.467911971732974, 3455.609],
                    [-106.226376984268427, 39.467933010309935, 3455.121],
                    [-106.226398022845387, 39.467954970896244, 3455.121],
                    [-106.226398022845387, 39.467933010309935, 3455.121],
                    [-106.226398022845387, 39.467933010309935, 3454.632999999999811],
                    [-106.226506987586617, 39.467867966741323, 3454.632999999999811],
                    [-106.226528026163578, 39.467847011983395, 3454.632999999999811],
                    [-106.226570019498467, 39.467805018648505, 3455.121],
                    [-106.226613018661737, 39.467718014493585, 3456.065999999999804],
                    [-106.226634979248047, 39.467696975916624, 3455.609],
                    [-106.226742016151547, 39.467696975916624, 3457.041000000000167],
                    [-106.226742016151547, 39.467696975916624, 3456.552999999999884],
                    [-106.226762970909476, 39.467696975916624, 3457.041000000000167],
                    [-106.226849975064397, 39.467675015330315, 3457.985999999999876],
                    [-106.226849975064397, 39.467675015330315, 3457.985999999999876],
                    [-106.226891968399286, 39.467611983418465, 3459.449],
                    [-106.226934967562556, 39.467590022832155, 3460.393999999999778],
                    [-106.226934967562556, 39.467590022832155, 3460.393999999999778],
                    [-106.226957011967897, 39.467547023668885, 3459.449],
                    [-106.227000011131167, 39.467503018677235, 3461.369000000000142],
                    [-106.226977966725826, 39.467418026179075, 3462.313999999999851],
                    [-106.226977966725826, 39.467396987602115, 3462.313999999999851],
                    [-106.227000011131167, 39.467352982610464, 3463.29],
                    [-106.227021971717477, 39.467332027852535, 3463.29],
                    [-106.227000011131167, 39.467311995103955, 3463.777],
                    [-106.227021971717477, 39.467224990949035, 3465.697000000000116],
                    [-106.227062959223986, 39.467203030362725, 3466.641999999999825],
                    [-106.227172007784247, 39.467118037864566, 3468.105],
                    [-106.227215006947517, 39.467096999287605, 3468.105],
                    [-106.227278038859367, 39.467052994295955, 3469.050000000000182],
                    [-106.227278038859367, 39.467032039538026, 3469.538],
                    [-106.227278038859367, 39.467009995132685, 3468.563000000000102],
                    [-106.227321960031986, 39.466988034546375, 3469.050000000000182],
                    [-106.227364959195256, 39.466988034546375, 3470.02599999999984],
                    [-106.227450035512447, 39.466946963220835, 3470.483000000000175],
                    [-106.227578027173877, 39.466882003471255, 3471.946],
                    [-106.227665031328797, 39.466816959902644, 3472.434000000000196],
                    [-106.227750023826957, 39.466797010973096, 3472.434000000000196],
                    [-106.227793022990227, 39.466797010973096, 3472.434000000000196],
                    [-106.227814983576536, 39.466753005981445, 3473.866],
                    [-106.227901987731457, 39.466644963249564, 3476.273999999999887],
                    [-106.227942975237966, 39.466602969914675, 3477.219],
                    [-106.227986980229616, 39.466472966596484, 3479.627],
                    [-106.227986980229616, 39.466431979089975, 3479.139000000000124],
                    [-106.227986980229616, 39.466410018503666, 3480.601999999999862],
                    [-106.228008018806577, 39.466367019340396, 3480.601999999999862],
                    [-106.228052023798227, 39.466344974935055, 3480.601999999999862],
                    [-106.228072978556156, 39.466282026842237, 3482.522],
                    [-106.228137016296387, 39.466195022687316, 3484.443000000000211],
                    [-106.228157971054316, 39.466172978281975, 3484.929999999999836],
                    [-106.228137016296387, 39.466129979118705, 3486.362999999999829],
                    [-106.228137016296387, 39.466110030189157, 3487.308],
                    [-106.228157971054316, 39.466067031025887, 3488.283],
                    [-106.228223014622927, 39.465980026870966, 3489.715999999999894],
                    [-106.228244975209236, 39.465957982465625, 3490.690999999999804],
                    [-106.228265007957816, 39.465872989967465, 3492.610999999999876],
                    [-106.228223014622927, 39.465852035209537, 3493.09900000000016],
                    [-106.228244975209236, 39.465808030217886, 3495.964],
                    [-106.228352012112737, 39.465744998306036, 3496.938999999999851],
                    [-106.228352012112737, 39.465744998306036, 3497.427000000000135],
                    [-106.228437004610896, 39.465657994151115, 3498.860000000000127],
                    [-106.228457959368825, 39.465594962239265, 3499.804999999999836],
                    [-106.228457959368825, 39.465573001652956, 3500.292],
                    [-106.228501964360476, 39.465530002489686, 3501.268],
                    [-106.228523002937436, 39.465487003326416, 3502.212],
                    [-106.228544963523746, 39.465422965586185, 3503.676],
                    [-106.228630039840937, 39.465358005836606, 3506.541000000000167],
                    [-106.228716960176826, 39.465292962267995, 3507.516],
                    [-106.228737998753786, 39.465207969769835, 3509.436000000000149],
                    [-106.228802036494017, 39.465143010020256, 3510.869000000000142],
                    [-106.228866996243596, 39.465036978945136, 3511.356000000000222],
                    [-106.228888034820557, 39.464972019195557, 3513.764000000000124],
                    [-106.228909995406866, 39.464949974790215, 3514.222000000000207],
                    [-106.228973027318716, 39.464907981455326, 3515.685],
                    [-106.229037987068295, 39.464800022542477, 3517.117000000000189],
                    [-106.229122979566455, 39.464757023379207, 3517.605],
                    [-106.229166984558105, 39.464692985638976, 3519.525],
                    [-106.229188023135066, 39.464628025889397, 3521.445000000000164],
                    [-106.229209983721375, 39.464500034227967, 3522.420999999999822],
                    [-106.229232028126717, 39.464413030073047, 3524.340999999999894],
                    [-106.229294976219535, 39.464349998161197, 3525.286],
                    [-106.229317020624876, 39.464262994006276, 3527.694],
                    [-106.229360019788146, 39.464199962094426, 3529.126000000000204],
                    [-106.229447023943067, 39.464156962931156, 3530.101999999999862],
                    [-106.229447023943067, 39.464135002344847, 3531.534000000000106],
                    [-106.229447023943067, 39.464092003181577, 3532.967],
                    [-106.229447023943067, 39.464069958776236, 3534.429999999999836],
                    [-106.229447023943067, 39.464050009846687, 3536.35],
                    [-106.229447023943067, 39.464027965441346, 3536.838000000000193],
                    [-106.229488011449575, 39.464007010683417, 3536.838000000000193],
                    [-106.229532016441226, 39.463963005691767, 3536.838000000000193],
                    [-106.229552971199155, 39.463941967114806, 3538.27],
                    [-106.229575015604496, 39.463920006528497, 3538.27],
                    [-106.229618014767766, 39.463878013193607, 3539.246],
                    [-106.229681968688965, 39.463835014030337, 3540.190999999999804],
                    [-106.229724967852235, 39.463792014867067, 3542.110999999999876],
                    [-106.229790011420846, 39.463748009875417, 3543.085999999999785],
                    [-106.229852959513664, 39.463726971298456, 3543.54300000000012],
                    [-106.229896964505315, 39.463684977963567, 3544.518999999999778],
                    [-106.230002995580435, 39.463641978800297, 3545.951],
                    [-106.230002995580435, 39.463641978800297, 3545.494000000000142],
                    [-106.230068039149046, 39.463641978800297, 3544.518999999999778],
                    [-106.230111960321665, 39.463663017377257, 3543.54300000000012],
                    [-106.230132998898625, 39.463684977963567, 3544.518999999999778],
                    [-106.230174992233515, 39.463769970461726, 3545.494000000000142],
                    [-106.230153031647205, 39.463792014867067, 3545.005999999999858],
                    [-106.230174992233515, 39.463835014030337, 3544.518999999999778],
                    [-106.230197036638856, 39.463941967114806, 3544.518999999999778],
                    [-106.230261996388435, 39.463984966278076, 3544.031],
                    [-106.230304995551705, 39.464050009846687, 3544.031],
                    [-106.230283034965396, 39.464092003181577, 3544.031]
                  ],
                camera:[[-106.12730806220965, 39.512540096049534],
                [-106.168826079791089, 39.51397725819659],
                [-106.245554569975269, 39.455852033582566]],
            },
            visible:false,
            description: 'Janet\'s cabin is a relatively easy hut to get to (especially if you use the chairlift), except for the very end, were it tempts you with a view of the cabin, even though you are a few hundred feet below it. One of the nicer features you can opt in on in this hike is a lift ride from Copper Mountain Resort to the trail. Of course, you can always hike from the base of the lift, but it\'s nice to use the lift system (as long as you are comfortable with carrying your pack on the lift).  There is great skiing around the hut, and it\'s great that you get to ski right to the hut, instead of having to trek out and back.  There\'s also a nice sauna to relax in, as well as an indoor bathroom.',
            location: {
                center: [-106.17953, 39.48265],
                zoom: 13.77,
                pitch: 58.50,
                bearing: -173.55
            },
            onChapterEnter: [{
                layer: 'janets-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'janets-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'vancescabin',
            title: 'Vances Cabin',
            image: '/img/vances.jpg',
            huttriplink: 'https://www.huttrip.com/2013/02/vances-cabin-10th-mountain.html',
            hutorglink: 'https://www.huts.org/The_Huts/vances.php',
            gpx: 'gpx/VancesCabin.gpx',
            data: {
                route:          [
                    [-106.311006965115666, 39.362233020365238, 3196.041000000000167],
            [-106.311006965115666, 39.362190021201968, 3197.960999999999785],
            [-106.310985004529357, 39.362190021201968, 3199.880999999999858],
            [-106.310942005366087, 39.362211981788278, 3199.393999999999778],
            [-106.310900012031198, 39.362211981788278, 3199.880999999999858],
            [-106.310619972646236, 39.362190021201968, 3205.641999999999825],
            [-106.310513019561768, 39.362190021201968, 3207.105],
            [-106.310276985168457, 39.362190021201968, 3210.946],
            [-106.309826960787177, 39.362147022038698, 3213.811000000000149],
            [-106.309354975819588, 39.362103017047048, 3218.139000000000124],
            [-106.309052975848317, 39.362062029540539, 3219.114],
            [-106.307829972356558, 39.361803028732538, 3222.955],
            [-106.30693000741303, 39.361588032916188, 3223.442],
            [-106.306736972182989, 39.361546961590648, 3223.442],
            [-106.306564975529909, 39.361503040418029, 3225.85],
            [-106.30622198805213, 39.361374964937568, 3225.85],
            [-106.30600699223578, 39.361244961619377, 3226.795],
            [-106.305857039988041, 39.361182013526559, 3227.771000000000186],
            [-106.305857039988041, 39.361159969121218, 3227.283],
            [-106.3058349955827, 39.361159969121218, 3228.257999999999811],
            [-106.3058349955827, 39.361159969121218, 3228.257999999999811],
            [-106.305727958679199, 39.361287960782647, 3229.690999999999804],
            [-106.305512962862849, 39.361310005187988, 3232.09900000000016],
            [-106.30521297454834, 39.361287960782647, 3234.964],
            [-106.304376963526011, 39.361202968284488, 3239.292],
            [-106.303110038861632, 39.361072964966297, 3239.7800000000002],
            [-106.302317027002573, 39.360816981643438, 3236.884],
            [-106.302187023684382, 39.360795021057129, 3235.938999999999851],
            [-106.302122985944152, 39.360772976651788, 3237.859],
                    [-106.302122985944152, 39.360772976651788, 3237.859],
                    [-106.302079986780882, 39.360772976651788, 3238.347000000000207],
                    [-106.301887035369873, 39.360816981643438, 3238.804],
                    [-106.301801959052682, 39.360838020220399, 3238.804],
                    [-106.301480010151863, 39.360923012718558, 3235.452000000000226],
                    [-106.300492035225034, 39.361159969121218, 3232.09900000000016],
                    [-106.299270037561655, 39.361353004351258, 3232.09900000000016],
                    [-106.298518013209105, 39.361609993502498, 3233.043999999999869],
                    [-106.296952022239566, 39.362211981788278, 3232.556],
                    [-106.295577976852655, 39.362789997830987, 3234.018999999999778],
                    [-106.294784964993596, 39.363155029714108, 3233.531],
                    [-106.293690036982298, 39.363734973594546, 3233.531],
                    [-106.293025016784668, 39.364184997975826, 3234.018999999999778],
                    [-106.292746989056468, 39.364484986290336, 3234.018999999999778],
                    [-106.292425040155649, 39.364742981269956, 3235.452000000000226],
                    [-106.292316997423768, 39.364827973768115, 3234.964],
                    [-106.292081968858838, 39.364957977086306, 3235.452000000000226],
                    [-106.292081968858838, 39.364957977086306, 3234.018999999999778],
                    [-106.292102001607418, 39.364980021491647, 3233.531],
                    [-106.292102001607418, 39.364999970421195, 3234.018999999999778],
                    [-106.292102001607418, 39.364999970421195, 3234.964],
                    [-106.292081968858838, 39.365022014826536, 3234.018999999999778],
                    [-106.292081968858838, 39.365042969584465, 3235.452000000000226],
                    [-106.292060008272529, 39.365065013989806, 3234.018999999999778],
                    [-106.292081968858838, 39.365108013153076, 3233.531],
                    [-106.292102001607418, 39.365152018144727, 3235.452000000000226],
                    [-106.292166961356997, 39.365237010642886, 3234.964],
                    [-106.292187999933958, 39.365237010642886, 3235.452000000000226],
                    [-106.292232004925609, 39.365323008969426, 3236.427000000000135],
                    [-106.292209960520267, 39.365495005622506, 3237.371999999999844],
                    [-106.292145000770688, 39.365622997283936, 3238.347000000000207],
                    [-106.292081968858838, 39.365751994773746, 3241.212],
                    [-106.291994964703918, 39.365902030840516, 3243.132],
                    [-106.291973004117608, 39.366137981414795, 3245.052999999999884],
                    [-106.292017009109259, 39.366352977231145, 3247.460999999999785],
                    [-106.292060008272529, 39.366588005796075, 3247.460999999999785],
                    [-106.292060008272529, 39.366609966382384, 3247.003000000000156],
                    [-106.292037963867188, 39.366717003285885, 3249.380999999999858],
                    [-106.292037963867188, 39.366781963035464, 3248.924],
                    [-106.292037963867188, 39.366803001612425, 3250.356000000000222],
                    [-106.292037963867188, 39.366847006604075, 3251.789000000000215],
                    [-106.292037963867188, 39.366910038515925, 3252.27599999999984],
                    [-106.292017009109259, 39.367146994918585, 3255.172],
                    [-106.291951965540648, 39.367317985743284, 3255.629],
                    [-106.291930004954338, 39.367489982396364, 3257.092],
                    [-106.291951965540648, 39.367555025964975, 3256.605],
                    [-106.291973004117608, 39.367597019299865, 3257.550000000000182],
                    [-106.291973004117608, 39.367617974057794, 3258.525],
                    [-106.291930004954338, 39.367661979049444, 3258.525],
                    [-106.291909972205758, 39.367768010124564, 3260.445000000000164],
                    [-106.291951965540648, 39.367897007614374, 3260.933],
                    [-106.291930004954338, 39.368047965690494, 3263.340999999999894],
                    [-106.291930004954338, 39.368198001757264, 3264.286],
                    [-106.291951965540648, 39.368198001757264, 3264.773000000000138],
                    [-106.291930004954338, 39.368305038660765, 3265.748999999999796],
                    [-106.291994964703918, 39.368433030322194, 3268.126000000000204],
                    [-106.292017009109259, 39.368582982569933, 3270.534000000000106],
                    [-106.291994964703918, 39.368626987561584, 3271.509],
                    [-106.291930004954338, 39.368797978386283, 3272.454000000000178],
                    [-106.291866973042488, 39.368948014453053, 3272.454000000000178],
                    [-106.291909972205758, 39.368907026946545, 3272.942],
                    [-106.291845012456179, 39.369056979194283, 3272.942],
                    [-106.291866973042488, 39.369056979194283, 3272.942],
                    [-106.291822968050838, 39.369120011106133, 3272.942],
                    [-106.291802013292909, 39.369120011106133, 3273.917],
                    [-106.291822968050838, 39.369163010269403, 3275.35],
                    [-106.291845012456179, 39.369227970018983, 3276.782000000000153],
                    [-106.291866973042488, 39.369292007759213, 3279.19],
                    [-106.291888011619449, 39.369335006922483, 3280.166000000000167],
                    [-106.291888011619449, 39.369378006085753, 3282.54300000000012],
                    [-106.291909972205758, 39.369462998583913, 3284.494000000000142],
                    [-106.291930004954338, 39.369550002738833, 3285.438999999999851],
                    [-106.291973004117608, 39.369593001902103, 3288.333999999999833],
                    [-106.292037963867188, 39.369721999391913, 3289.766999999999825],
                    [-106.292081968858838, 39.369849991053343, 3290.742000000000189],
                    [-106.292081968858838, 39.369957027956843, 3292.661999999999807],
                    [-106.292166961356997, 39.370000027120113, 3293.607],
                    [-106.292232004925609, 39.370021987706423, 3295.070000000000164],
                    [-106.292232004925609, 39.370021987706423, 3295.527999999999793],
                    [-106.292252959683537, 39.370021987706423, 3296.503000000000156],
                    [-106.292338036000729, 39.370128018781543, 3299.368],
                    [-106.292402995750308, 39.370215022936463, 3302.264000000000124],
                    [-106.292425040155649, 39.370236983522773, 3303.239],
                    [-106.292487988248467, 39.370321976020932, 3306.103999999999814],
                    [-106.292531993240118, 39.370407974347472, 3308.512000000000171],
                    [-106.292616985738277, 39.370430018752813, 3309.945000000000164],
                    [-106.292616985738277, 39.370387019589543, 3311.408],
                    [-106.292638024315238, 39.370343014597893, 3312.840000000000146],
                    [-106.292638024315238, 39.370343014597893, 3312.353],
                    [-106.292659984901547, 39.370387019589543, 3312.353],
                    [-106.292725028470159, 39.370407974347472, 3312.840000000000146],
                    [-106.292725028470159, 39.370407974347472, 3313.328],
                    [-106.292917979881167, 39.370407974347472, 3315.735999999999876],
                    [-106.293068015947938, 39.370407974347472, 3318.112999999999829],
                    [-106.293112020939589, 39.370407974347472, 3317.656],
                    [-106.293197013437748, 39.370407974347472, 3319.576],
                    [-106.293325005099177, 39.370451979339123, 3321.009],
                    [-106.293452996760607, 39.370451979339123, 3321.009],
                    [-106.293667992576957, 39.370492966845632, 3324.362],
                    [-106.293777041137218, 39.370515011250973, 3324.84900000000016],
                    [-106.293948031961918, 39.370579971000552, 3326.311999999999898],
                    [-106.294077029451728, 39.370643002912402, 3327.257],
                    [-106.294120028614998, 39.370643002912402, 3327.257],
                    [-106.294333012774587, 39.370730007067323, 3329.665],
                    [-106.294526970013976, 39.370794966816902, 3330.152999999999793],
                    [-106.294592013582587, 39.370814999565482, 3331.585],
                    [-106.294635012745857, 39.370836960151792, 3331.585],
                    [-106.294741965830326, 39.370922958478332, 3333.505999999999858],
                    [-106.294826958328485, 39.370966963469982, 3336.40099999999984],
                    [-106.294870041310787, 39.370966963469982, 3337.833999999999833],
                    [-106.294935001060367, 39.370986996218562, 3339.266],
                    [-106.295191990211606, 39.370922958478332, 3340.728999999999814],
                    [-106.295191990211606, 39.370922958478332, 3339.266],
                    [-106.295191990211606, 39.370966963469982, 3338.809000000000196],
                    [-106.295385025441647, 39.371029995381832, 3342.161999999999807],
                    [-106.295406986027956, 39.371052039787173, 3343.107],
                    [-106.295492984354496, 39.371157987043262, 3344.570000000000164],
                    [-106.295534977689385, 39.371157987043262, 3346.002],
                    [-106.295492984354496, 39.371329983696342, 3347.922999999999774],
                    [-106.295472029596567, 39.371416987851262, 3347.922999999999774],
                    [-106.295513017103076, 39.371587978675961, 3349.355],
                    [-106.295643020421267, 39.371738014742732, 3350.331000000000131],
                    [-106.295772017911077, 39.371910011395812, 3350.331000000000131],
                    [-106.295877965167165, 39.372188039124012, 3350.818000000000211],
                    [-106.295900009572506, 39.372209999710321, 3351.306],
                    [-106.295857010409236, 39.372297003865242, 3351.763],
                    [-106.295836977660656, 39.372340003028512, 3351.763],
                    [-106.295728012919426, 39.372467994689941, 3353.683],
                    [-106.295685013756156, 39.372511999681592, 3355.603999999999814],
                    [-106.295534977689385, 39.372724983841181, 3356.579000000000178],
                    [-106.295492984354496, 39.372790027409792, 3356.579000000000178],
                    [-106.295513017103076, 39.372854987159371, 3356.579000000000178],
                    [-106.295492984354496, 39.372896980494261, 3356.579000000000178],
                    [-106.295472029596567, 39.372939979657531, 3357.554],
                    [-106.295428024604917, 39.373026983812451, 3358.498999999999796],
                    [-106.295385025441647, 39.373026983812451, 3358.987],
                    [-106.295321993529797, 39.373111976310611, 3358.987],
                    [-106.295191990211606, 39.373177019879222, 3358.498999999999796],
                    [-106.294978000223637, 39.37328296713531, 3360.907000000000153],
                    [-106.294612968340516, 39.373433003202081, 3362.827000000000226],
                    [-106.294354973360896, 39.373562000691891, 3365.235000000000127],
                    [-106.294182976707816, 39.37366995960474, 3368.130999999999858],
                    [-106.294162021949887, 39.373692004010081, 3368.130999999999858],
                    [-106.293882988393307, 39.373862994834781, 3369.563999999999851],
                    [-106.293624993413687, 39.373991992324591, 3372.429],
                    [-106.293368004262447, 39.374013030901551, 3372.916000000000167],
                    [-106.293025016784668, 39.374013030901551, 3375.811999999999898],
                    [-106.292959973216057, 39.374034991487861, 3376.757],
                    [-106.292940024286509, 39.374034991487861, 3376.300000000000182],
                    [-106.292917979881167, 39.374034991487861, 3376.757],
                    [-106.292897025123239, 39.374013030901551, 3377.244000000000142],
                    [-106.292510032653809, 39.373927032575011, 3378.677000000000135],
                    [-106.292252959683537, 39.37390498816967, 3379.165],
                    [-106.292123040184379, 39.373885039240122, 3380.628000000000156],
                    [-106.292081968858838, 39.37390498816967, 3380.139999999999873],
                    [-106.292037963867188, 39.373970031738281, 3381.572999999999865],
                    [-106.292081968858838, 39.374034991487861, 3383.005000000000109],
                    [-106.292123040184379, 39.374142028391361, 3384.925000000000182],
                    [-106.292145000770688, 39.374185027554631, 3386.388],
                    [-106.292145000770688, 39.374206988140941, 3386.388],
                    [-106.292187999933958, 39.374228026717901, 3385.90099999999984],
                    [-106.292272992432117, 39.374313019216061, 3387.333],
                    [-106.292444989085197, 39.37454997561872, 3389.741],
                    [-106.292531993240118, 39.3747219722718, 3390.717],
                    [-106.292659984901547, 39.374872008338571, 3392.637000000000171],
                    [-106.292682029306889, 39.374935040250421, 3394.556999999999789],
                    [-106.292702984064817, 39.37495700083673, 3395.045],
                    [-106.292853020131588, 39.375193035230041, 3397.422],
                    [-106.292982017621398, 39.37545002438128, 3399.83],
                    [-106.293068015947938, 39.375686980783939, 3402.237999999999829],
                    [-106.293068015947938, 39.375901976600289, 3403.670999999999822],
                    [-106.293002972379327, 39.376157959923148, 3405.621],
                    [-106.292959973216057, 39.376416960731149, 3407.054],
                    [-106.292959973216057, 39.376437999308109, 3407.542],
                    [-106.292874980717897, 39.376566996797919, 3409.462],
                    [-106.292787976562977, 39.376802025362849, 3409.918999999999869],
                    [-106.292768027633429, 39.376973016187549, 3411.382],
                    [-106.292831981554627, 39.377209972590208, 3413.302000000000135],
                    [-106.292810020968318, 39.377467967569828, 3413.79],
                    [-106.292874980717897, 39.377702996134758, 3413.302000000000135],
                    [-106.292897025123239, 39.377961996942759, 3413.302000000000135],
                    [-106.292940024286509, 39.378068028017879, 3412.815],
                    [-106.292917979881167, 39.378197025507689, 3414.246999999999844],
                    [-106.292874980717897, 39.378389976918697, 3414.735000000000127],
                    [-106.292874980717897, 39.378627017140388, 3415.71],
                    [-106.292940024286509, 39.378904961049557, 3413.79],
                    [-106.292940024286509, 39.379077041521668, 3414.246999999999844],
                    [-106.292853020131588, 39.379205033183098, 3414.735000000000127],
                    [-106.292917979881167, 39.379335036501288, 3415.223],
                    [-106.292917979881167, 39.379397984594107, 3414.735000000000127],
                    [-106.292940024286509, 39.379635024815798, 3413.79],
                    [-106.293025016784668, 39.379912968724966, 3414.735000000000127],
                    [-106.293025016784668, 39.380150008946657, 3416.167],
                    [-106.293046977370977, 39.380386965349317, 3416.6550000000002],
                    [-106.293089976534247, 39.380449997261167, 3415.71],
                    [-106.293089976534247, 39.380600033327937, 3416.6550000000002],
                    [-106.293197013437748, 39.380792984738946, 3416.167],
                    [-106.293197013437748, 39.381073024123907, 3417.630000000000109],
                    [-106.293283011764288, 39.381136978045106, 3418.118],
                    [-106.293302960693836, 39.381264969706535, 3418.118],
                    [-106.293346965685487, 39.381458004936576, 3417.630000000000109],
                    [-106.293346965685487, 39.381479965522885, 3418.118],
                    [-106.293432964012027, 39.381694961339235, 3419.551],
                    [-106.293518040329218, 39.381929989904165, 3419.063000000000102],
                    [-106.293604960665107, 39.382038032636046, 3417.143],
                    [-106.293647037819028, 39.382167030125856, 3419.063000000000102],
                    [-106.293690036982298, 39.382272977381945, 3419.063000000000102],
                    [-106.293754996731877, 39.382402980700135, 3418.118],
                    [-106.293882988393307, 39.382597021758556, 3420.038],
                    [-106.293905032798648, 39.382638009265065, 3420.983000000000175],
                    [-106.293967980891466, 39.382702969014645, 3421.471],
                    [-106.294011985883117, 39.382787961512804, 3423.391],
                    [-106.294011985883117, 39.382810005918145, 3423.391],
                    [-106.294120028614998, 39.382874965667725, 3423.879],
                    [-106.294248020276427, 39.383025001734495, 3425.311000000000149],
                    [-106.294269980862737, 39.383068000897765, 3426.744000000000142],
                    [-106.294333012774587, 39.383112005889416, 3427.719],
                    [-106.294397972524166, 39.383175037801266, 3427.719],
                    [-106.294548008590937, 39.383282996714115, 3429.152],
                    [-106.294654961675406, 39.383367989212275, 3428.664000000000215],
                    [-106.294635012745857, 39.383390033617616, 3428.664000000000215],
                    [-106.294763004407287, 39.383411994203925, 3428.20699999999988],
                    [-106.294956961646676, 39.383496986702085, 3427.719],
                    [-106.295042037963867, 39.383539985865355, 3426.286999999999807],
                    [-106.295213028788567, 39.383626990020275, 3426.286999999999807],
                    [-106.295449985191226, 39.383711982518435, 3424.367000000000189],
                    [-106.295643020421267, 39.383839974179864, 3422.904],
                    [-106.295792972669005, 39.383882973343134, 3422.416000000000167],
                    [-106.296007968485355, 39.384011970832944, 3420.983000000000175],
                    [-106.296136965975165, 39.384033009409904, 3419.551],
                    [-106.296287002041936, 39.383948016911745, 3417.630000000000109],
                    [-106.296393033117056, 39.383862018585205, 3414.246999999999844],
                    [-106.296480037271976, 39.383818013593554, 3411.869999999999891],
                    [-106.296608028933406, 39.383754981681705, 3409.918999999999869],
                    [-106.296695033088326, 39.383733021095395, 3407.998999999999796],
                    [-106.296867029741406, 39.383733021095395, 3406.079000000000178],
                    [-106.297080013900995, 39.383690021932125, 3403.213000000000193],
                    [-106.297295009717345, 39.383667977526784, 3400.806],
                    [-106.297487961128354, 39.383690021932125, 3399.373],
                    [-106.297660041600466, 39.383711982518435, 3396.965000000000146],
                    [-106.297852993011475, 39.383754981681705, 3395.502],
                    [-106.297937985509634, 39.383754981681705, 3395.045],
                    [-106.298047034069896, 39.383733021095395, 3392.637000000000171],
                    [-106.298109982162714, 39.383711982518435, 3392.148999999999887],
                    [-106.298303017392755, 39.383733021095395, 3388.795999999999822],
                    [-106.298539973795414, 39.383733021095395, 3386.876000000000204],
                    [-106.298562018200755, 39.383733021095395, 3388.309000000000196],
                    [-106.298582972958684, 39.383754981681705, 3387.821],
                    [-106.298667965456843, 39.383733021095395, 3386.876000000000204],
                    [-106.298754969611764, 39.383711982518435, 3385.90099999999984],
                    [-106.298882961273193, 39.383690021932125, 3383.981000000000222],
                    [-106.298946999013424, 39.383667977526784, 3383.005000000000109],
                    [-106.299055041745305, 39.383562030270696, 3381.572999999999865],
                    [-106.299183033406734, 39.383518025279045, 3379.652],
                    [-106.299312030896544, 39.383518025279045, 3379.165],
                    [-106.299398029223084, 39.383518025279045, 3378.677000000000135],
                    [-106.299591986462474, 39.383496986702085, 3375.811999999999898],
                    [-106.299632973968983, 39.383496986702085, 3374.837],
                    [-106.299827015027404, 39.383454993367195, 3371.484],
                    [-106.299870014190674, 39.383433032780886, 3371.484],
                    [-106.299804970622063, 39.383347034454346, 3370.051],
                    [-106.299827015027404, 39.383324990049005, 3370.051],
                    [-106.299891974776983, 39.383282996714115, 3368.588000000000193],
                    [-106.299870014190674, 39.383261958137155, 3368.588000000000193]
                  ],
                camera:[
                    [-106.359489591299706, 39.365470503001411],
                    [-106.285874952434142, 39.341198431184566],
                    [-106.268868533694061, 39.376169376762782]
                  ],
            },
            visible:false,
            description: 'Vance\'s Cabin is a 10th Mountain hut, is located near Tennessee Pass and the Ski Cooper Resort, between camp Hale and Leadville. There are three levels to the hut, a lower sleeping quarters, a middle common area, and a loft. The hut sleeps a total of sixteen people, ten in the lower area, and 6 in the loft.   The lower area suffers from a lack of heat, so I usually try to get the loft.  It\'s located in a wooded area, with an open field where it looks like they took the timber to build the hut.  For Skiing, you are going to either ski at the Resort, or head over to Chicago Ridge.  There are a few ways to get up to Vance\'s Cabin: you can get to it from 10th Mountain Division Camp Hale, Ski Cooper, or as a hut to hut from Jackyl. The route I took was (of course) the simplest.  Starting at 10,400 feet and going as high as 11,000 feet, there is only about 600 vertical feet of elevation over three miles, with no significantly hard sections.',
            location: {
                center: [-106.31070, 39.36976],
                zoom: 14.69,
                pitch: 46.50,
                bearing: -9.60
            },
            onChapterEnter: [{
                layer: 'vancescabin-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'vancescabin-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'pointbreezecabin',
            title: 'Point Breeze and Continental Divide Cabins',
            image: '/img/continentaldividecabin.jpg',
            huttriplink: '',
            hutorglink: 'https://www.huts.org/The_Huts/continental_divide.php',
            gpx: 'gpx/CDCPointBreeze.gpx',
            data: {
                route:[
                    [-106.311405021697283, 39.362572990357876, 3198.936999999999898],
                    [-106.31150996312499, 39.362562010064721, 3199.393999999999778],
                    [-106.311554973945022, 39.362553041428328, 3199.880999999999858],
                    [-106.311566960066557, 39.362535020336509, 3199.393999999999778],
                    [-106.311601996421814, 39.36251699924469, 3200.857],
                    [-106.311657987535, 39.362487997859716, 3199.880999999999858],
                    [-106.311727976426482, 39.362433012574911, 3201.313999999999851],
                    [-106.311784973368049, 39.362396970391273, 3202.289000000000215],
                    [-106.311925034970045, 39.362394958734512, 3202.289000000000215],
                    [-106.312041962519288, 39.362429995089769, 3202.777],
                    [-106.31206501275301, 39.362448016181588, 3202.777],
                    [-106.312123015522957, 39.362465031445026, 3202.777],
                    [-106.312168026342988, 39.362456984817982, 3204.21],
                    [-106.312214965000749, 39.362465031445026, 3203.722000000000207],
                    [-106.312285037711263, 39.362463019788265, 3204.697000000000116],
                    [-106.312308004125953, 39.362454973161221, 3204.697000000000116],
                    [-106.312504978850484, 39.362380038946867, 3205.641999999999825],
                    [-106.312734978273511, 39.362286999821663, 3206.617000000000189],
                    [-106.312770014628768, 39.362267972901464, 3207.105],
                    [-106.312885014340281, 39.362212987616658, 3209.513],
                    [-106.312976963818073, 39.362194966524839, 3209.513],
                    [-106.313000014051795, 39.362177029252052, 3210.458],
                    [-106.313115013763309, 39.362148027867079, 3210.458],
                    [-106.313196988776326, 39.362127995118499, 3210.458],
                    [-106.313230013474822, 39.36210997402668, 3210.946],
                    [-106.313345013186336, 39.361992040649056, 3210.946],
                    [-106.313399998471141, 39.361847033724189, 3210.458],
                    [-106.313502006232738, 39.361712001264095, 3210.946],
                    [-106.313637960702181, 39.361591972410679, 3211.433],
                    [-106.313729993999004, 39.361473033204675, 3211.433],
                    [-106.313832001760602, 39.361373037099838, 3211.433],
                    [-106.313853040337563, 39.361310005187988, 3210.458],
                    [-106.31395697593689, 39.361183019354939, 3211.433],
                    [-106.314048003405333, 39.361128034070134, 3211.889999999999873],
                    [-106.314127966761589, 39.361082017421722, 3213.811000000000149],
                    [-106.314220000058413, 39.361017979681492, 3214.297999999999774],
                    [-106.3143449742347, 39.360908009111881, 3214.786],
                    [-106.31447003223002, 39.360782029107213, 3213.811000000000149],
                    [-106.3146420288831, 39.360616989433765, 3213.811000000000149],
                    [-106.314768008887768, 39.360552029684186, 3214.786],
                    [-106.314894994720817, 39.360487991943955, 3216.706000000000131],
                    [-106.315007982775569, 39.360396964475513, 3217.194],
                    [-106.315147038549185, 39.360286993905902, 3216.706000000000131],
                    [-106.315248040482402, 39.360186997801065, 3216.706000000000131],
                    [-106.315339989960194, 39.36013201251626, 3217.194],
                    [-106.315454989671707, 39.360058000311255, 3215.273999999999887],
                    [-106.315639978274703, 39.35998298227787, 3214.786],
                    [-106.315799988806248, 39.359902013093233, 3214.297999999999774],
                    [-106.315811974927783, 39.359892038628459, 3214.297999999999774],
                    [-106.315856985747814, 39.35980997979641, 3212.866],
                    [-106.315917000174522, 39.359854990616441, 3214.297999999999774],
                    [-106.315986989066005, 39.359872005879879, 3213.811000000000149],
                    [-106.316044991835952, 39.359890026971698, 3213.353999999999814],
                    [-106.316067958250642, 39.359906958416104, 3213.811000000000149],
                    [-106.316184969618917, 39.359933026134968, 3214.297999999999774],
                    [-106.316220005974174, 39.359941994771361, 3213.811000000000149],
                    [-106.316325031220913, 39.359966972842813, 3214.297999999999774],
                    [-106.316591994836926, 39.360026987269521, 3215.761],
                    [-106.316627031192183, 39.360035033896565, 3216.219],
                    [-106.316870022565126, 39.360007038339972, 3215.761],
                    [-106.317068003118038, 39.360021958127618, 3214.297999999999774],
                    [-106.317079989239573, 39.360021958127618, 3214.297999999999774],
                    [-106.317347036674619, 39.360009972006083, 3213.353999999999814],
                    [-106.317554991692305, 39.359990023076534, 3214.786],
                    [-106.317681977525353, 39.359924979507923, 3213.811000000000149],
                    [-106.317923041060567, 39.359832024201751, 3214.786],
                    [-106.318027982488275, 39.359812997281551, 3216.219],
                    [-106.318153040483594, 39.359748037531972, 3215.761],
                    [-106.318282037973404, 39.359709983691573, 3215.273999999999887],
                    [-106.318465014919639, 39.359636977314949, 3215.273999999999887],
                    [-106.31866198964417, 39.359545027837157, 3215.273999999999887],
                    [-106.31868302822113, 39.359534969553351, 3216.706000000000131],
                    [-106.318823006004095, 39.359533041715622, 3217.681999999999789],
                    [-106.318823006004095, 39.359533041715622, 3217.681999999999789],
                    [-106.318823006004095, 39.359533041715622, 3217.681999999999789],
                    [-106.31883499212563, 39.359533041715622, 3221.034000000000106],
                    [-106.31883499212563, 39.359533041715622, 3221.034000000000106],
                    [-106.318823006004095, 39.359533041715622, 3221.614],
                    [-106.318904981017113, 39.359577968716621, 3222.467],
                    [-106.31896298378706, 39.359587021172047, 3222.467],
                    [-106.319068009033799, 39.359592972323298, 3222.955],
                    [-106.319185020402074, 39.359636977314949, 3223.929999999999836],
                    [-106.319277975708246, 39.359645023941994, 3225.85],
                    [-106.319464975968003, 39.359715012833476, 3226.795],
                    [-106.31965197622776, 39.359803022816777, 3229.203],
                    [-106.319675026461482, 39.359792964532971, 3227.283],
                    [-106.319709978997707, 39.359785001724958, 3227.771000000000186],
                    [-106.3197570014745, 39.35976698063314, 3228.715000000000146],
                    [-106.319824978709221, 39.359747031703591, 3229.203],
                    [-106.319872001186013, 39.359737979248166, 3229.690999999999804],
                    [-106.320056989789009, 39.359726998955011, 3231.610999999999876],
                    [-106.320196967571974, 39.359697997570038, 3231.610999999999876],
                    [-106.32022999227047, 39.359697997570038, 3232.09900000000016],
                    [-106.320232003927231, 39.359733033925295, 3232.09900000000016],
                    [-106.320369970053434, 39.359660027548671, 3232.556],
                    [-106.320471977815032, 39.359567994251847, 3234.018999999999778],
                    [-106.320482958108187, 39.359560031443834, 3233.043999999999869],
                    [-106.320495028048754, 39.359532035887241, 3233.531],
                    [-106.320527968928218, 39.359496999531984, 3234.018999999999778],
                    [-106.320608016103506, 39.359413012862206, 3234.018999999999778],
                    [-106.320722009986639, 39.35929499566555, 3233.531],
                    [-106.320756962522864, 39.359276974573731, 3235.452000000000226],
                    [-106.3208950124681, 39.359240010380745, 3236.427000000000135],
                    [-106.321023004129529, 39.359237998723984, 3236.884],
                    [-106.3210469763726, 39.359237998723984, 3238.347000000000207],
                    [-106.321032978594303, 39.359219977632165, 3237.859],
                    [-106.321057034656405, 39.359219977632165, 3237.859],
                    [-106.321070026606321, 39.359237998723984, 3238.804],
                    [-106.32130203768611, 39.359227018430829, 3237.859],
                    [-106.321322992444038, 39.35920799151063, 3238.347000000000207],
                    [-106.321322992444038, 39.359198017045856, 3237.859],
                    [-106.321404967457056, 39.359179995954037, 3237.371999999999844],
                    [-106.32156296633184, 39.358987966552377, 3235.938999999999851],
                    [-106.321677966043353, 39.35888797044754, 3235.452000000000226],
                    [-106.32173496298492, 39.358859974890947, 3235.938999999999851],
                    [-106.321918023750186, 39.358742041513324, 3234.964],
                    [-106.322102006524801, 39.358612960204482, 3233.531],
                    [-106.32225195877254, 39.358511958271265, 3234.018999999999778],
                    [-106.322320019826293, 39.358474994078279, 3232.09900000000016],
                    [-106.322467960417271, 39.358320012688637, 3231.123],
                    [-106.322487993165851, 39.358139969408512, 3229.690999999999804],
                    [-106.322452034801245, 39.358041984960437, 3227.771000000000186],
                    [-106.322390008717775, 39.357835035771132, 3226.308],
                    [-106.322327982634306, 39.3576829880476, 3224.387000000000171],
                    [-106.32231499068439, 39.357628002762794, 3222.955],
                    [-106.322303004562855, 39.357602018862963, 3224.875],
                    [-106.322254976257682, 39.357530018314719, 3223.929999999999836],
                    [-106.322137964889407, 39.35746799223125, 3222.955],
                    [-106.321975020691752, 39.357372019439936, 3220.547],
                    [-106.321903020143509, 39.357300018891692, 3220.547],
                    [-106.321809981018305, 39.357291972264647, 3221.034000000000106]
                  ],
                camera:          [
                    [-106.298569884733084, 39.367227034514471],
                    [-106.318290943084278, 39.36307523275633]
                  ],
            },
            visible:false,
            description: 'To get to the Continental Divide Cabin, the simplest way is to take Highway 24 to Tennessee Pass. The trailhead is located on the west side of the road, across the street from both "Ski Cooper" and the 10th Mountain Memorial. Looking towards the west, you will take the Colorado Trail or Continental Divide Trail. The hike itself is less than a mile at about .66 miles. The hut itself is one of the nicest huts I have been in. I have done a winter and summer trip to the hut. There are ample cross country trails, and you can still get to the tenth mountain hut and ski that terrain. ',
            location: {
                center: [-106.31693, 39.35659],
                zoom: 15.82,
                pitch: 0.00,
                bearing: -84.80
            },
            onChapterEnter: [{
                layer: 'cdc-pointbreeze-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'cdc-pointbreeze-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'tenthmountaindivisionhut',
            title: '10th Mountain Division Hut',
            image: '/img/tenth.jpg',
            huttriplink: 'https://www.huttrip.com/2008/03/tenth-mountain-hut.html',
            hutorglink: 'https://www.huts.org/The_Huts/10th_mountain.php',
            gpx: 'gpx/tenthmtnhut-data.gpx',
            data: {
                route:[
                    [-106.333353742, 39.347148653, 3093.741],
                    [-106.333484978, 39.347263672, 3094.125],
                    [-106.334736097, 39.347168951, 3099.166000000000167],
                    [-106.335374781, 39.346986273, 3102.46],
                    [-106.336669645, 39.34695921, 3103.685],
                    [-106.337553303, 39.347730512, 3106.244999999999891],
                    [-106.337938262, 39.347933485, 3107.271000000000186],
                    [-106.338235731, 39.348177052, 3107.987999999999829],
                    [-106.338323222, 39.348352961, 3108.304],
                    [-106.338585695, 39.348474743, 3109.752],
                    [-106.338699433, 39.348664183, 3112.994000000000142],
                    [-106.339075643, 39.348894216, 3118.621],
                    [-106.339163134, 39.349158076, 3122.946],
                    [-106.339819316, 39.349516654, 3128.844],
                    [-106.340615482, 39.350260866, 3132.851999999999862],
                    [-106.341569133, 39.350788576, 3130.382],
                    [-106.341822856, 39.350910354, 3129.652999999999793],
                    [-106.341919096, 39.351025367, 3130.538],
                    [-106.343572673, 39.35115391, 3130.608000000000175],
                    [-106.343712658, 39.351072725, 3130.498],
                    [-106.34408012, 39.351045663, 3131.621],
                    [-106.344281349, 39.351160676, 3134.473],
                    [-106.344867538, 39.351221565, 3136.844],
                    [-106.345392483, 39.351323046, 3137.436000000000149],
                    [-106.345856184, 39.351336577, 3137.422],
                    [-106.346267391, 39.351526009, 3139.647],
                    [-106.346634853, 39.351877809, 3144.465999999999894],
                    [-106.346879827, 39.351830451, 3144.902],
                    [-106.347553507, 39.35218225, 3153.565999999999804],
                    [-106.347850975, 39.352189016, 3155.092999999999847],
                    [-106.348069703, 39.352134893, 3155.301],
                    [-106.348305928, 39.352128127, 3159.571],
                    [-106.348402168, 39.352074005, 3160.547999999999774],
                    [-106.349032102, 39.35199282, 3167.1550000000002],
                    [-106.349425811, 39.352134893, 3172.268],
                    [-106.349767025, 39.352161954, 3173.949999999999818],
                    [-106.349837018, 39.352074005, 3172.766],
                    [-106.349749527, 39.351762798, 3173.90099999999984],
                    [-106.349635789, 39.351749267, 3172.938999999999851],
                    [-106.349583294, 39.351641021, 3175.045999999999822],
                    [-106.349460807, 39.351451589, 3178.715000000000146],
                    [-106.348769629, 39.351025367, 3183.996999999999844],
                    [-106.348620895, 39.35093065, 3184.449999999999818],
                    [-106.349032102, 39.350883292, 3187.617000000000189],
                    [-106.349137091, 39.350944181, 3188.0300000000002],
                    [-106.349679534, 39.350923885, 3192.103999999999814],
                    [-106.349915759, 39.350890058, 3193.886],
                    [-106.350046996, 39.350666797, 3194.467999999999847],
                    [-106.350423206, 39.350430004, 3198.846],
                    [-106.350510697, 39.350294694, 3198.627],
                    [-106.350475701, 39.349990244, 3196.523000000000138],
                    [-106.350265723, 39.349854933, 3192.819],
                    [-106.350239476, 39.349624903, 3190.585],
                    [-106.350116988, 39.349516654, 3188.063000000000102],
                    [-106.349802021, 39.34941517, 3185.806],
                    [-106.349592043, 39.349191904, 3181.985000000000127],
                    [-106.349863265, 39.349259561, 3181.429],
                    [-106.35019573, 39.349225732, 3178.587],
                    [-106.350711926, 39.349056591, 3176.190999999999804],
                    [-106.351394355, 39.34915131, 3176.533],
                    [-106.351621831, 39.349063357, 3175.985000000000127],
                    [-106.352278012, 39.349070123, 3175.357],
                    [-106.352496739, 39.348955107, 3173.462],
                    [-106.352802957, 39.348975404, 3173.833],
                    [-106.353467888, 39.349503123, 3179.588000000000193],
                    [-106.35392284, 39.349604607, 3177.927999999999884],
                    [-106.354045327, 39.349577544, 3176.846],
                    [-106.354220309, 39.349665497, 3177.235000000000127],
                    [-106.354509029, 39.349679028, 3177.273000000000138],
                    [-106.354797749, 39.34975345, 3179.579000000000178],
                    [-106.355322694, 39.349739918, 3179.119999999999891],
                    [-106.356013872, 39.349611372, 3181.955],
                    [-106.356206351, 39.349469295, 3182.681999999999789],
                    [-106.356328839, 39.349469295, 3184.02],
                    [-106.356477573, 39.349361045, 3183.378000000000156],
                    [-106.356582562, 39.349361045, 3184.77],
                    [-106.356705049, 39.349185139, 3183.878000000000156],
                    [-106.356993769, 39.34909042, 3186.130999999999858],
                    [-106.357317485, 39.348643886, 3182.683],
                    [-106.35755371, 39.348569463, 3181.126000000000204],
                    [-106.357536212, 39.348447681, 3180.148000000000138],
                    [-106.357903674, 39.348001143, 3174.876000000000204],
                    [-106.358183645, 39.348034971, 3173.279],
                    [-106.358341128, 39.34811616, 3172.549],
                    [-106.358524859, 39.348102629, 3171.257999999999811],
                    [-106.35879608, 39.347886125, 3169.40099999999984],
                    [-106.358883571, 39.347926719, 3169.514999999999873],
                    [-106.358918568, 39.348204115, 3171.023000000000138],
                    [-106.359137295, 39.348650652, 3172.295999999999822],
                    [-106.359426015, 39.349171607, 3174.536999999999807],
                    [-106.359609745, 39.349293389, 3175.2800000000002],
                    [-106.35995096, 39.349421935, 3176.177000000000135],
                    [-106.360659636, 39.350105259, 3179.731000000000222],
                    [-106.360992101, 39.350105259, 3180.266999999999825],
                    [-106.361429555, 39.350342052, 3183.364999999999782],
                    [-106.361639533, 39.35070739, 3187.114],
                    [-106.36282066, 39.351613959, 3201.225],
                    [-106.363188121, 39.351823686, 3204.159999999999854],
                    [-106.363800557, 39.351972524, 3208.379],
                    [-106.364089277, 39.352175485, 3209.773999999999887],
                    [-106.364867945, 39.3522702, 3214.041000000000167],
                    [-106.365550374, 39.352216077, 3218.326],
                    [-106.365742854, 39.352094301, 3220.333],
                    [-106.366302795, 39.352033413, 3222.965000000000146],
                    [-106.366810242, 39.351756032, 3224.308],
                    [-106.367247696, 39.351769563, 3225.293999999999869],
                    [-106.367518918, 39.351857513, 3225.705],
                    [-106.367658903, 39.352060474, 3225.529],
                    [-106.367833885, 39.353176749, 3226.413],
                    [-106.368735041, 39.354380952, 3234.114999999999782],
                    [-106.368752539, 39.354942456, 3240.177999999999884],
                    [-106.369146248, 39.3554566, 3252.452000000000226],
                    [-106.368463819, 39.355991035, 3264.952000000000226],
                    [-106.368113856, 39.357215485, 3285.159000000000106],
                    [-106.368183848, 39.357513138, 3290.273999999999887],
                    [-106.368691295, 39.357641669, 3294.632],
                    [-106.369015012, 39.357851378, 3298.596],
                    [-106.369723687, 39.357986674, 3303.913],
                    [-106.370546101, 39.358331677, 3309.092],
                    [-106.371534748, 39.358460207, 3311.103999999999814],
                    [-106.372672129, 39.358940502, 3316.177000000000135],
                    [-106.373503292, 39.359109619, 3318.9],
                    [-106.374080732, 39.359468146, 3321.603999999999814],
                    [-106.37419447, 39.359745495, 3323.513],
                    [-106.376530475, 39.361707205, 3356.481000000000222],
                    [-106.377046671, 39.361808671, 3362.860999999999876],
                    [-106.377361638, 39.362058954, 3369.007999999999811],
                    [-106.378079063, 39.362058954, 3374.306999999999789],
                    [-106.378604008, 39.362248357, 3380.072999999999865],
                    [-106.378997717, 39.362674511, 3385.4699999999998],
                    [-106.379618902, 39.362938319, 3389.934000000000196],
                    [-106.379741389, 39.362938319, 3390.764000000000124],
                    [-106.380047607, 39.363296826, 3393.014999999999873],
                    [-106.380450065, 39.363533575, 3395.135000000000218],
                    [-106.380642545, 39.363587689, 3395.781],
                    [-106.381473708, 39.363736502, 3401.110999999999876],
                    [-106.382366115, 39.364284402, 3408.395],
                    [-106.383967197, 39.364954052, 3420.239],
                    [-106.384430899, 39.364913467, 3423.121999999999844],
                    [-106.384509641, 39.365014929, 3423.409999999999854],
                    [-106.384973342, 39.365048749, 3425.667],
                    [-106.38547204, 39.365177267, 3427.382999999999811],
                    [-106.386040731, 39.365217852, 3430.514000000000124],
                    [-106.38613697, 39.365170503, 3430.635000000000218],
                    [-106.386338199, 39.365244908, 3431.443000000000211],
                    [-106.386688163, 39.365569583, 3434.079000000000178],
                    [-106.386994381, 39.366232456, 3441.493],
                    [-106.387064373, 39.366753281, 3447.023999999999887],
                    [-106.387204359, 39.367105005, 3452.534999999999854],
                    [-106.387134366, 39.367740808, 3456.703],
                    [-106.387116868, 39.367936959, 3457.225],
                    [-106.386854395, 39.368281913, 3461.042],
                    [-106.386793152, 39.36892447, 3468.295999999999822]
                  ],
                camera:[
                    [-106.307991281030425, 39.331697192545739],
                    [-106.377134748771823, 39.331218138496723],
                    [-106.393342744096898, 39.342156539282598]
                  ],
            },
            visible:false,
            description: 'Tenth Mountain Division Hut is a classic!  The memorabilia in the hut is enough to absorb you into the history of the soldiers. The hut has a great deck with a table pointing out the mountains in your view.  A classic hike is to make it up to Slide Lake, Once you get up there, every way down funnels you back to the hut.  If you are more adventurous, climbing to the top of Homestake Peak is also a day trip.',
            location: {
                center: [-106.35161, 39.34576],
                zoom: 14.23,
                pitch: 51.50,
                bearing: -103.20
            },
            onChapterEnter: [{
                layer: 'tenth-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'tenth-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'tenthtobuds',
            title: 'Tenth to Buds Hut to Hut',
            image: '',
            huttriplink: 'https://www.huttrip.com/2008/03/10th-mountain-to-uncle-buds.html',
            hutorglink: '',
            gpx: 'gpx/tenthtobuds-data.gpx',
            data: {
                route:[
                    [-106.386850021, 39.368897415, 3467.779],
                    [-106.386867519, 39.368572755, 3464.186999999999898],
                    [-106.387164988, 39.368248094, 3458.610999999999876],
                    [-106.387112493, 39.367909904, 3457.112],
                    [-106.388739823, 39.36573192, 3466.335],
                    [-106.38877482, 39.365488414, 3465.472000000000207],
                    [-106.389089787, 39.364554968, 3454.869000000000142],
                    [-106.389422252, 39.364243817, 3453.377],
                    [-106.390244666, 39.36421676, 3454.697000000000116],
                    [-106.391504534, 39.362931555, 3454.809000000000196],
                    [-106.392484431, 39.362106305, 3447.504],
                    [-106.393079369, 39.361294574, 3434.885000000000218],
                    [-106.393149362, 39.359995785, 3407.243],
                    [-106.392886889, 39.359792848, 3401.821],
                    [-106.39297438, 39.359562851, 3399.931999999999789],
                    [-106.392606919, 39.358886384, 3381.744999999999891],
                    [-106.392606919, 39.358412854, 3381.382999999999811],
                    [-106.391836999, 39.357912262, 3388.013],
                    [-106.392484431, 39.356816357, 3375.5],
                    [-106.393009377, 39.35659988, 3369.741],
                    [-106.392886889, 39.355246884, 3350.65],
                    [-106.393411834, 39.354353892, 3344.041000000000167],
                    [-106.39517915, 39.353379706, 3346.387000000000171],
                    [-106.3956516, 39.35294673, 3346.434000000000196],
                    [-106.397331424, 39.351904871, 3358.944],
                    [-106.396946465, 39.350105259, 3381.641999999999825],
                    [-106.395984065, 39.349726387, 3382.088000000000193],
                    [-106.39517915, 39.349699325, 3382.917],
                    [-106.393569318, 39.350051134, 3378.847000000000207],
                    [-106.392361944, 39.349794043, 3381.118],
                    [-106.391697014, 39.34975345, 3380.369999999999891],
                    [-106.390402149, 39.347791404, 3397.809000000000196],
                    [-106.390192171, 39.347060697, 3394.806],
                    [-106.389649728, 39.346208196, 3365.802000000000135],
                    [-106.38877482, 39.345436877, 3347.434000000000196],
                    [-106.388634834, 39.345017384, 3347.121],
                    [-106.388704827, 39.344354309, 3348.2800000000002],
                    [-106.388022398, 39.343596501, 3340.487],
                    [-106.387567446, 39.343393516, 3337.416000000000167],
                    [-106.387497453, 39.342798088, 3337.646000000000186],
                    [-106.386937512, 39.341959068, 3335.348],
                    [-106.386990006, 39.341526021, 3335.188000000000102],
                    [-106.386780028, 39.341187702, 3331.46],
                    [-106.386517556, 39.341160636, 3328.233000000000175],
                    [-106.386132596, 39.340646387, 3325.578],
                    [-106.386132596, 39.340037403, 3328.79300000000012],
                    [-106.385957614, 39.340010337, 3326.806],
                    [-106.385852625, 39.339834407, 3325.601999999999862],
                    [-106.38590512, 39.339617877, 3326.630999999999858],
                    [-106.386080101, 39.339191583, 3326.636],
                    [-106.386552552, 39.338711152, 3326.447999999999865],
                    [-106.386430065, 39.338481086, 3324.434000000000196],
                    [-106.386517556, 39.338088618, 3323.658],
                    [-106.386937512, 39.337533746, 3321.603],
                    [-106.386780028, 39.337330742, 3315.016],
                    [-106.38705125, 39.337114205, 3312.40099999999984],
                    [-106.388179882, 39.335429251, 3292.577000000000226],
                    [-106.388634834, 39.335239776, 3290.226999999999862],
                    [-106.39106708, 39.334657813, 3287.27599999999984],
                    [-106.391390796, 39.334630744, 3289.078],
                    [-106.392300701, 39.33431946, 3284.364],
                    [-106.392729406, 39.334245022, 3286.40099999999984],
                    [-106.393193107, 39.334245022, 3288.917],
                    [-106.393683056, 39.334082612, 3288.985999999999876],
                    [-106.394549215, 39.333967571, 3290.800000000000182],
                    [-106.394899179, 39.33375779, 3291.712],
                    [-106.39508291, 39.333480338, 3293.382],
                    [-106.395564109, 39.333412666, 3300.962],
                    [-106.396281534, 39.333128445, 3304.983000000000175],
                    [-106.397208937, 39.332316377, 3299.322999999999865],
                    [-106.398285075, 39.331626113, 3298.744000000000142],
                    [-106.401644723, 39.33036061, 3310.811000000000149],
                    [-106.402003436, 39.330320005, 3314.531],
                    [-106.402292156, 39.330184656, 3315.389999999999873],
                    [-106.403517027, 39.329853049, 3325.783],
                    [-106.404059471, 39.329575581, 3327.893],
                    [-106.4042607, 39.329602651, 3329.135000000000218],
                    [-106.405861782, 39.328912359, 3330.308],
                    [-106.406474218, 39.328736402, 3330.264999999999873],
                    [-106.406334233, 39.32862812, 3330.884],
                    [-106.406815432, 39.328093475, 3341.561000000000149],
                    [-106.407707839, 39.327660343, 3343.542],
                    [-106.408197788, 39.327139227, 3357.821],
                    [-106.409011453, 39.326651946, 3373.614999999999782],
                    [-106.409238929, 39.326672249, 3369.433],
                    [-106.409352667, 39.326597803, 3371.389000000000124],
                    [-106.409405161, 39.326503054, 3375.126000000000204],
                    [-106.410586288, 39.326063143, 3388.164000000000215],
                    [-106.411093735, 39.326076679, 3391.880000000000109],
                    [-106.411496193, 39.325704445, 3411.936999999999898],
                    [-106.411924898, 39.325555551, 3417.347000000000207],
                    [-106.412554832, 39.324939667, 3435.809000000000196],
                    [-106.413219762, 39.324689252, 3444.043999999999869],
                    [-106.413639718, 39.324235794, 3453.654],
                    [-106.412992286, 39.323883854, 3467.292],
                    [-106.412992286, 39.323809405, 3470.715000000000146],
                    [-106.413552228, 39.323606362, 3481.65],
                    [-106.413902191, 39.323660507, 3482.859],
                    [-106.413902191, 39.32336271, 3493.135000000000218],
                    [-106.414164664, 39.32325442, 3492.697000000000116],
                    [-106.414269653, 39.323132593, 3492.688999999999851],
                    [-106.414269653, 39.322970157, 3493.784999999999854],
                    [-106.414672111, 39.322381324, 3496.179999999999836],
                    [-106.414628365, 39.322022607, 3495.565],
                    [-106.414278402, 39.321684193, 3493.518],
                    [-106.414322147, 39.321521754, 3493.643],
                    [-106.413665966, 39.321318704, 3491.117000000000189],
                    [-106.413350999, 39.321156264, 3490.277999999999793],
                    [-106.412966039, 39.320804309, 3488.02],
                    [-106.412922294, 39.320838151, 3488.819],
                    [-106.412721065, 39.320709551, 3485.583],
                    [-106.411854905, 39.320763699, 3477.31],
                    [-106.41171492, 39.320702783, 3473.48],
                    [-106.411487444, 39.320404973, 3462.868],
                    [-106.411496193, 39.320235763, 3459.516],
                    [-106.411872403, 39.319951488, 3459.036],
                    [-106.411627429, 39.31989734, 3451.835],
                    [-106.411434949, 39.319998867, 3451.592],
                    [-106.411093735, 39.320019172, 3446.683],
                    [-106.411216222, 39.319904108, 3445.29],
                    [-106.411207473, 39.31980935, 3443.686999999999898],
                    [-106.410936251, 39.319640138, 3441.543999999999869],
                    [-106.410752521, 39.319619832, 3440.777999999999793],
                    [-106.410577539, 39.319565684, 3440.154],
                    [-106.410446303, 39.319457388, 3439.038],
                    [-106.410393808, 39.319281407, 3437.885000000000218],
                    [-106.409466405, 39.318435335, 3445.536],
                    [-106.40942266, 39.318286426, 3448.266999999999825],
                    [-106.408705235, 39.317832926, 3452.815],
                    [-106.408416515, 39.317744934, 3451.425000000000182],
                    [-106.407734086, 39.31713575, 3465.266],
                    [-106.407524108, 39.317108675, 3466.061999999999898],
                    [-106.407060407, 39.316722856, 3474.853],
                    [-106.406964167, 39.316546868, 3478.889999999999873],
                    [-106.406771687, 39.316546868, 3479.862999999999829],
                    [-106.406500465, 39.316100126, 3484.654],
                    [-106.406587956, 39.315924136, 3487.493],
                    [-106.407034159, 39.315578924, 3494.013],
                    [-106.407734086, 39.315308168, 3508.283],
                    [-106.407174145, 39.315159251, 3518.112],
                    [-106.407480363, 39.315003566, 3525.994000000000142],
                    [-106.40750661, 39.314874956, 3533.213000000000193],
                    [-106.40712165, 39.31484788, 3537.652],
                    [-106.406465469, 39.314658349, 3548.929],
                    [-106.405319339, 39.313961141, 3558.152999999999793],
                    [-106.404873136, 39.31323008, 3564.906],
                    [-106.404523172, 39.312356859, 3570.822000000000116],
                    [-106.403735755, 39.311673167, 3566.975],
                    [-106.40339454, 39.310975929, 3567.715000000000146],
                    [-106.402974584, 39.310292223, 3570.960999999999785],
                    [-106.403062075, 39.310007908, 3569.953],
                    [-106.40339454, 39.309926675, 3569.262000000000171],
                    [-106.405704299, 39.3097439, 3545.29300000000012],
                    [-106.405931775, 39.309567894, 3540.103],
                    [-106.405940524, 39.309466352, 3537.6],
                    [-106.405074364, 39.308999256, 3528.753000000000156],
                    [-106.404111965, 39.307530254, 3502.494999999999891],
                    [-106.404216954, 39.307279776, 3498.940999999999804],
                    [-106.40434819, 39.307103764, 3496.746999999999844],
                    [-106.404531921, 39.307164692, 3493.735999999999876],
                    [-106.404855637, 39.306920982, 3486.623],
                    [-106.404995623, 39.306650192, 3482.172999999999774],
                    [-106.406054262, 39.305106672, 3454.598],
                    [-106.406019266, 39.304639548, 3454.3449999999998],
                    [-106.406272989, 39.304470299, 3450.705],
                    [-106.407716588, 39.302818409, 3438.413],
                    [-106.407611599, 39.302608535, 3437.732],
                    [-106.407751584, 39.302337729, 3434.096],
                    [-106.407681592, 39.302270027, 3434.013],
                    [-106.40769909, 39.301938288, 3432.409999999999854],
                    [-106.407235388, 39.301863815, 3440.049],
                    [-106.407156647, 39.301647168, 3441.536999999999807],
                    [-106.406404225, 39.301450831, 3458.963000000000193],
                    [-106.406141753, 39.301471142, 3463.594],
                    [-106.40559931, 39.301403439, 3467.181999999999789]
                  ],
                camera:[
                    [-106.347505938263282, 39.365896813689005],
                    [-106.438487618876991, 39.326736066726738],
                    [-106.366013414567362, 39.311071767941826]
                  ],
            },
            visible:false,
            description: 'I\'ve done this going from Tenth Mountain to Uncle Buds.  It is one of the more challenging hut to hut trips, as it is long, travels through wilderness, and ends with a nice climb.  When you move into the wilderness, there are no longer blue diamonds to guide you, and you need to rely on blazes on the trees.  We had the fortune of being the first to traverse it after new snow, and all tracks were gone, and the blazes were covered in snow.  Luckily we had good maps and gps data, so we were able to navigate our way.  Needless to say, there is nothing better than smelling the fire from the hut, seeing the tracks and finally Uncle Bud\'s hut!',
            location: {
                center: [-106.37436, 39.34415],
                zoom: 13.53,
                pitch: 52.50,
                bearing: -160.00
            },
            onChapterEnter: [{
                layer: 'tenth-to-buds-tracks',
                opacity: 1.00
            },{
                layer: 'unclebudshut-tracks',
                opacity: 0.25
            },{
                layer: 'tenth-tracks',
                opacity: 0.25
            }],
            onChapterExit: [{
                layer: 'tenth-to-buds-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'unclebudhut',
            title: 'Uncle Buds Hut',
            image: '/img/ub_panorama.png',
            huttriplink: 'https://www.huttrip.com/2010/01/uncle-buds-hut.html',
            hutorglink: 'https://www.huts.org/The_Huts/uncle_buds.php',
            gpx: 'gpx/unclebuds-data.gpx',
            data: {
                route:[
                    [-106.339891369, 39.263862886, 2977.742000000000189],
                    [-106.340541223, 39.264057982, 2976.456000000000131],
                    [-106.341283914, 39.264068251, 2975.355],
                    [-106.341787883, 39.264273614, 2976.241],
                    [-106.34250405, 39.264971846, 2980.659000000000106],
                    [-106.343551775, 39.265485248, 2982.869000000000142],
                    [-106.34414858, 39.265557124, 2985.715999999999894],
                    [-106.345010632, 39.265485248, 2987.97400000000016],
                    [-106.345766585, 39.265362032, 2991.551],
                    [-106.346111406, 39.265362032, 2993.735000000000127],
                    [-106.347291755, 39.265813823, 3001.848],
                    [-106.347795724, 39.265895966, 3007.17],
                    [-106.349055646, 39.26587543, 3009.601999999999862],
                    [-106.34936068, 39.266871413, 3011.186000000000149],
                    [-106.349188269, 39.267353997, 3014.743],
                    [-106.348405791, 39.267662028, 3017.949],
                    [-106.348246643, 39.267672296, 3018.496],
                    [-106.347371329, 39.267343729, 3021.083],
                    [-106.346880622, 39.267323194, 3022.223],
                    [-106.346535801, 39.26751828, 3023.446],
                    [-106.346403178, 39.267939254, 3023.356000000000222],
                    [-106.346575588, 39.268349959, 3027.739999999999782],
                    [-106.346880622, 39.26855531, 3029.090000000000146],
                    [-106.348445578, 39.269561521, 3033.004],
                    [-106.348856711, 39.270198097, 3033.964],
                    [-106.349387204, 39.270619055, 3033.340999999999894],
                    [-106.349387204, 39.271050278, 3034.388],
                    [-106.349082171, 39.271204285, 3033.721],
                    [-106.348485365, 39.271327491, 3033.172],
                    [-106.348034446, 39.271594436, 3027.597000000000207],
                    [-106.347835511, 39.271933249, 3025.597000000000207],
                    [-106.347822248, 39.272066721, 3025.214],
                    [-106.348047708, 39.272528735, 3025.706000000000131],
                    [-106.34806097, 39.272703273, 3024.356000000000222],
                    [-106.34788856, 39.272867544, 3024.364999999999782],
                    [-106.347636575, 39.273103683, 3025.944],
                    [-106.347411116, 39.273668358, 3024.629],
                    [-106.347636575, 39.274017429, 3025.656],
                    [-106.34788856, 39.27423303, 3025.061999999999898],
                    [-106.348896498, 39.274469164, 3028.945000000000164],
                    [-106.349161745, 39.27469503, 3030.79],
                    [-106.349692238, 39.275403425, 3033.858000000000175],
                    [-106.350050321, 39.275577956, 3035.478],
                    [-106.350355355, 39.275680621, 3034.866],
                    [-106.351058259, 39.275814085, 3032.041000000000167],
                    [-106.35145613, 39.276122079, 3031.353999999999814],
                    [-106.351920311, 39.276389005, 3035.856000000000222],
                    [-106.353074135, 39.276676464, 3033.646000000000186],
                    [-106.353803564, 39.276717529, 3032.581000000000131],
                    [-106.354877813, 39.276399272, 3034.565],
                    [-106.355235897, 39.276399272, 3035.092],
                    [-106.355580717, 39.276512202, 3033.862],
                    [-106.356482557, 39.276501936, 3032.556],
                    [-106.356960001, 39.276676464, 3032.626000000000204],
                    [-106.357755741, 39.276748328, 3032.643999999999778],
                    [-106.358432121, 39.277066584, 3032.326],
                    [-106.359440058, 39.277004986, 3030.973],
                    [-106.359877716, 39.277128182, 3031.306],
                    [-106.360434734, 39.277405371, 3033.266],
                    [-106.361349835, 39.277343773, 3034.862999999999829],
                    [-106.363458548, 39.277590163, 3043.011],
                    [-106.363935992, 39.277662027, 3044.217999999999847],
                    [-106.364705208, 39.277939214, 3048.882999999999811],
                    [-106.36516939, 39.278072674, 3052.373999999999796],
                    [-106.367145478, 39.277795487, 3061.072000000000116],
                    [-106.367463774, 39.277826286, 3060.5],
                    [-106.36766271, 39.277959746, 3063.646000000000186],
                    [-106.367689234, 39.278113738, 3065.42],
                    [-106.367450512, 39.278616777, 3067.864],
                    [-106.367556611, 39.27884263, 3070.094],
                    [-106.368272777, 39.279006887, 3075.97400000000016],
                    [-106.368829796, 39.278863162, 3080.609],
                    [-106.369784684, 39.278883695, 3086.101999999999862],
                    [-106.37010298, 39.278976089, 3089.032000000000153],
                    [-106.370354965, 39.279222473, 3092.041000000000167],
                    [-106.370606949, 39.279314866, 3093.547999999999774],
                    [-106.370925245, 39.279201941, 3097.56],
                    [-106.371827084, 39.278647575, 3105.505999999999858],
                    [-106.373524664, 39.278370391, 3116.190999999999804],
                    [-106.374758062, 39.278442254, 3121.728999999999814],
                    [-106.376614789, 39.278319061, 3120.648000000000138],
                    [-106.379466193, 39.278555181, 3144.027999999999793],
                    [-106.380991362, 39.278627043, 3152.734],
                    [-106.383232697, 39.278647575, 3163.071],
                    [-106.384386521, 39.278852896, 3167.297999999999774],
                    [-106.385036376, 39.279715238, 3171.839],
                    [-106.385500557, 39.280290126, 3174.54300000000012],
                    [-106.385474033, 39.280577569, 3179.094],
                    [-106.385726017, 39.280885541, 3180.452000000000226],
                    [-106.385938215, 39.282599898, 3205.380999999999858],
                    [-106.385832116, 39.28324662, 3216.576],
                    [-106.385765804, 39.283708561, 3223.583],
                    [-106.38551382, 39.284314213, 3232.643999999999778],
                    [-106.38573928, 39.28500198, 3240.4],
                    [-106.385659706, 39.285679475, 3248.402999999999793],
                    [-106.386495233, 39.287999334, 3272.751000000000204],
                    [-106.386866578, 39.288697329, 3279.476000000000113],
                    [-106.387184875, 39.288995002, 3283.252],
                    [-106.387622532, 39.289169499, 3286.824999999999818],
                    [-106.38920075, 39.289456905, 3297.443000000000211],
                    [-106.389731244, 39.289764838, 3304.221],
                    [-106.3912962, 39.289816161, 3309.328],
                    [-106.39191953, 39.29000092, 3315.523999999999887],
                    [-106.392648959, 39.289723781, 3322.193000000000211],
                    [-106.392980517, 39.289692987, 3325.268999999999778],
                    [-106.393272289, 39.290113828, 3332.541000000000167],
                    [-106.393285551, 39.290544931, 3341.027],
                    [-106.392874419, 39.291704789, 3351.81],
                    [-106.394041505, 39.291899808, 3362.311999999999898],
                    [-106.394266964, 39.291766374, 3361.98],
                    [-106.395792134, 39.292002449, 3355.219],
                    [-106.396044118, 39.291920336, 3352.795999999999822],
                    [-106.396826596, 39.292248787, 3352.116],
                    [-106.397264253, 39.292690141, 3358.203],
                    [-106.397675386, 39.292823573, 3357.871999999999844],
                    [-106.397834534, 39.293244395, 3357.906],
                    [-106.397662124, 39.294034712, 3360.522],
                    [-106.397609074, 39.294486318, 3364.311000000000149],
                    [-106.397635599, 39.295707693, 3379.092],
                    [-106.398006944, 39.296159288, 3390.072999999999865],
                    [-106.398577225, 39.296672461, 3396.772],
                    [-106.399492327, 39.297144577, 3399.989999999999782],
                    [-106.399717786, 39.298037482, 3413.568000000000211],
                    [-106.399823885, 39.298160641, 3415.06],
                    [-106.400911397, 39.298735378, 3418.038],
                    [-106.401070545, 39.299094586, 3424.534999999999854],
                    [-106.402012171, 39.29920748, 3429.130000000000109],
                    [-106.40229068, 39.299351162, 3433.628000000000156],
                    [-106.402754862, 39.299279321, 3435.197000000000116],
                    [-106.404107621, 39.299351162, 3440.897],
                    [-106.404399393, 39.29944353, 3441.504],
                    [-106.404452442, 39.299638527, 3442.944],
                    [-106.404346343, 39.299854049, 3445.281],
                    [-106.404558541, 39.300305617, 3451.556999999999789],
                    [-106.404240244, 39.30060324, 3456.592999999999847],
                    [-106.404226982, 39.300839285, 3460.04300000000012],
                    [-106.404797263, 39.30130111, 3467.215000000000146],
                    [-106.405433855, 39.301496102, 3469.223]
                  ],
                camera:[
                    [-106.297777154107081, 39.231999245993357],
                    [-106.407212666166032, 39.27319849759202]
                  ],
            },
            visible:false,
            description: 'Uncle Bud\'s hut is one of my personal favorites as a hut to hut combined with either Skinner Hut or Tenth Mountain Hut. It has great skiing around Galena Mountain, and has some of the best tenth mountain memorabilia, telling the story of Bud Winter. The trail to Uncle Bud\'s is located outside of Leadville. The first part of the trail is on the road that wraps around Tourquiose Lake, so it is very gradual and is shared with snowmobilers. It the cuts up into the trees following a jeep trail the rest of the way. It is a moderate hike, and the accessibility makes this one of the more popular huts to visit. The Hut has 2 private bedrooms, 1 with 6 beds, 1 with 4 beds, and a communal sleeping area with 1 double bed and 4 single beds.',
            location: {
                center: [-106.36989, 39.27004],
                zoom: 14.02,
                pitch: 54.00,
                bearing: -30.40
            },
            onChapterEnter: [{
                layer: 'unclebudshut-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'unclebudshut-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'skinnerhut',
            title: 'Skinner Hut',
            image: '/img/skinner_in.jpg',
            huttriplink: 'https://www.huttrip.com/2010/02/skinner-hut.html',
            hutorglink: 'https://www.huts.org/The_Huts/skinner.php',
            gpx: 'gpx/skinnerhut-data.gpx',
            data: {
                route:[
                    [-106.339931156, 39.263873155, 2977.699],
                    [-106.340647322, 39.264057982, 2976.34900000000016],
                    [-106.341191078, 39.264057982, 2975.4],
                    [-106.341641998, 39.264191469, 2975.788],
                    [-106.34188072, 39.264366028, 2976.710999999999785],
                    [-106.342623411, 39.265023187, 2980.391],
                    [-106.343326315, 39.26542364, 2981.368],
                    [-106.343830284, 39.265536588, 2984.507999999999811],
                    [-106.344957583, 39.265485248, 2987.688000000000102],
                    [-106.345819635, 39.2653723, 2991.895],
                    [-106.34624403, 39.265433908, 2994.128000000000156],
                    [-106.347305017, 39.265813823, 3001.915],
                    [-106.347755937, 39.265895966, 3006.813000000000102],
                    [-106.348883235, 39.26587543, 3009.981000000000222],
                    [-106.349095433, 39.26592677, 3009.483000000000175],
                    [-106.349373942, 39.266891948, 3011.359],
                    [-106.349188269, 39.267333462, 3014.667],
                    [-106.348525152, 39.267641493, 3017.543999999999869],
                    [-106.348273168, 39.267662028, 3018.362999999999829],
                    [-106.347543739, 39.2673848, 3021.188000000000102],
                    [-106.346907147, 39.267312926, 3022.085],
                    [-106.34646949, 39.267579886, 3023.199999999999818],
                    [-106.346442965, 39.268083001, 3025.315],
                    [-106.346575588, 39.268391029, 3027.911999999999807],
                    [-106.348432316, 39.269510184, 3033.261],
                    [-106.348896498, 39.270218632, 3033.820000000000164],
                    [-106.349373942, 39.27059852, 3033.215000000000146],
                    [-106.349413729, 39.270947606, 3034.688999999999851],
                    [-106.349241319, 39.271132415, 3033.81],
                    [-106.348419054, 39.271306957, 3032.324],
                    [-106.347848773, 39.27186138, 3025.859],
                    [-106.347822248, 39.272025653, 3025.29300000000012],
                    [-106.34806097, 39.27258007, 3025.109],
                    [-106.34806097, 39.27271354, 3024.376000000000204],
                    [-106.347689625, 39.273052348, 3025.628000000000156],
                    [-106.347411116, 39.273606758, 3024.744000000000142],
                    [-106.34788856, 39.27420223, 3024.565999999999804],
                    [-106.348790399, 39.274438364, 3028.754],
                    [-106.349108695, 39.27460263, 3029.811999999999898],
                    [-106.349625927, 39.275290493, 3034.036],
                    [-106.350037059, 39.275598489, 3035.315999999999804],
                    [-106.350633864, 39.27571142, 3033.851999999999862],
                    [-106.351071522, 39.275834618, 3031.827000000000226],
                    [-106.351840737, 39.276327407, 3035.042],
                    [-106.352503854, 39.276543001, 3034.109],
                    [-106.353365907, 39.27668673, 3032.308],
                    [-106.353830088, 39.276707263, 3032.797],
                    [-106.354824764, 39.276368473, 3033.85],
                    [-106.355182847, 39.276389005, 3035.266999999999825],
                    [-106.355647029, 39.276522468, 3033.601999999999862],
                    [-106.356469294, 39.276491669, 3032.465000000000146],
                    [-106.356973263, 39.276655931, 3032.380999999999858],
                    [-106.357769004, 39.276758595, 3032.741],
                    [-106.358352547, 39.277025519, 3032.214],
                    [-106.358604531, 39.277056318, 3031.806999999999789],
                    [-106.359453321, 39.276984454, 3031.033],
                    [-106.359837929, 39.277097383, 3031.637000000000171],
                    [-106.360474521, 39.277415637, 3033.563999999999851],
                    [-106.361495721, 39.27735404, 3035.40099999999984],
                    [-106.363776844, 39.277610696, 3042.904],
                    [-106.36511634, 39.278062408, 3051.887000000000171],
                    [-106.367344413, 39.277774955, 3060.09900000000016],
                    [-106.367530086, 39.277826286, 3060.734],
                    [-106.367729021, 39.278093206, 3065.565],
                    [-106.367450512, 39.278555181, 3067.551],
                    [-106.367530086, 39.278801566, 3069.219],
                    [-106.36828604, 39.278986355, 3075.502],
                    [-106.368843058, 39.278863162, 3080.715000000000146],
                    [-106.369638798, 39.278863162, 3085.972000000000207],
                    [-106.370036668, 39.278935025, 3088.126000000000204],
                    [-106.370288653, 39.279119813, 3091.507],
                    [-106.370567162, 39.279273802, 3093.34900000000016],
                    [-106.370858934, 39.279212207, 3096.684000000000196],
                    [-106.371866871, 39.278657842, 3106.311999999999898],
                    [-106.372490201, 39.278514117, 3109.268999999999778],
                    [-106.373577713, 39.278370391, 3116.292],
                    [-106.374718275, 39.27845252, 3122.016999999999825],
                    [-106.376521953, 39.278308795, 3120.128000000000156],
                    [-106.379572291, 39.278555181, 3144.331000000000131],
                    [-106.380911788, 39.278637309, 3151.978],
                    [-106.383312271, 39.278637309, 3163.804],
                    [-106.384399783, 39.278873428, 3167.176],
                    [-106.385102687, 39.27985896, 3173.293999999999869],
                    [-106.386919628, 39.281686264, 3179.373],
                    [-106.387503171, 39.281860779, 3180.158],
                    [-106.388272387, 39.281850514, 3180.859],
                    [-106.388683519, 39.281665733, 3181.494000000000142],
                    [-106.390248475, 39.278729704, 3203.551],
                    [-106.390659608, 39.278534649, 3206.561000000000149],
                    [-106.391402299, 39.278390923, 3211.013],
                    [-106.391839956, 39.278370391, 3211.388],
                    [-106.393099879, 39.279037685, 3217.909000000000106],
                    [-106.393975193, 39.281522014, 3221.737999999999829],
                    [-106.394399588, 39.281747858, 3219.21],
                    [-106.394890294, 39.281583608, 3221.344],
                    [-106.395593199, 39.280947136, 3224.35],
                    [-106.396190004, 39.280690492, 3223.382999999999811],
                    [-106.396707235, 39.280557037, 3225.936999999999898],
                    [-106.397781485, 39.280669961, 3232.123],
                    [-106.39877616, 39.280485177, 3236.205],
                    [-106.400168706, 39.280639163, 3240.844],
                    [-106.400911397, 39.280813681, 3243.984],
                    [-106.40251614, 39.281542545, 3251.261],
                    [-106.404730951, 39.283205559, 3263.266999999999825],
                    [-106.405367543, 39.283339009, 3262.471],
                    [-106.406242858, 39.283872806, 3258.514999999999873],
                    [-106.406746827, 39.283934398, 3257.915],
                    [-106.407356894, 39.28399599, 3260.300000000000182],
                    [-106.407688453, 39.283893337, 3260.304999999999836],
                    [-106.407913913, 39.283677765, 3255.379],
                    [-106.408218947, 39.283616173, 3253.551],
                    [-106.408815752, 39.283646969, 3251.226999999999862],
                    [-106.410248085, 39.283862541, 3243.360999999999876],
                    [-106.410685742, 39.283883071, 3241.284000000000106],
                    [-106.411123399, 39.283790684, 3238.572999999999865],
                    [-106.412635306, 39.283811214, 3229.808],
                    [-106.415513234, 39.284221825, 3227.587],
                    [-106.416308974, 39.284529782, 3229.043999999999869],
                    [-106.417025141, 39.284509252, 3229.338000000000193],
                    [-106.417502585, 39.284303947, 3228.248999999999796],
                    [-106.417993292, 39.284149969, 3226.545],
                    [-106.419651084, 39.284652964, 3216.069],
                    [-106.420632497, 39.285227812, 3214.5],
                    [-106.421229303, 39.285320198, 3212.295999999999822],
                    [-106.421865895, 39.285309933, 3208.710999999999785],
                    [-106.423682836, 39.284714555, 3196.664000000000215],
                    [-106.424186804, 39.28470429, 3195.047],
                    [-106.424597937, 39.284776146, 3191.036999999999807],
                    [-106.425897646, 39.285392054, 3182.743],
                    [-106.426467927, 39.285361258, 3181.359],
                    [-106.4266536, 39.285186752, 3178.648999999999887],
                    [-106.426786223, 39.284899328, 3176.982],
                    [-106.426719912, 39.284570843, 3175.868],
                    [-106.425857859, 39.283729092, 3170.574],
                    [-106.425738498, 39.283297947, 3166.679999999999836],
                    [-106.425658924, 39.282887331, 3162.652],
                    [-106.425393677, 39.282066091, 3158.27],
                    [-106.425473252, 39.281717061, 3156.496999999999844],
                    [-106.425831335, 39.28153228, 3155.083999999999833],
                    [-106.42722388, 39.281121653, 3148.262000000000171],
                    [-106.427794161, 39.281049793, 3142.311000000000149],
                    [-106.428510327, 39.281234575, 3138.096],
                    [-106.429783512, 39.281265372, 3131.84900000000016],
                    [-106.430433367, 39.281316701, 3127.567],
                    [-106.430924073, 39.281491217, 3125.327000000000226],
                    [-106.434717103, 39.282220074, 3105.558],
                    [-106.43600355, 39.282712818, 3097.496999999999844],
                    [-106.436878864, 39.282743615, 3091.547999999999774],
                    [-106.438934527, 39.283554581, 3081.025],
                    [-106.441228912, 39.283811214, 3077.732],
                    [-106.442250112, 39.283718827, 3071.885000000000218],
                    [-106.442939754, 39.283636704, 3067.748999999999796],
                    [-106.443563084, 39.283688031, 3064.475],
                    [-106.445181089, 39.284334743, 3059.452000000000226],
                    [-106.445645271, 39.284457926, 3060.385000000000218],
                    [-106.445817682, 39.284345008, 3057.319],
                    [-106.446838882, 39.284909593, 3059.475],
                    [-106.447541786, 39.284817207, 3061.857],
                    [-106.44801923, 39.284786412, 3065.262000000000171],
                    [-106.448138591, 39.284817207, 3067.106000000000222],
                    [-106.448456887, 39.28470429, 3066.5300000000002],
                    [-106.448921069, 39.284755616, 3072.643],
                    [-106.449027168, 39.284529782, 3071.324],
                    [-106.449504612, 39.284149969, 3077.554999999999836],
                    [-106.449862696, 39.284221825, 3080.494999999999891],
                    [-106.450220779, 39.283811214, 3085.527999999999793],
                    [-106.450154467, 39.283605908, 3087.487999999999829],
                    [-106.449769859, 39.283205559, 3090.609],
                    [-106.449597449, 39.282959189, 3092.04300000000012],
                    [-106.448960856, 39.282476712, 3096.324999999999818],
                    [-106.448297739, 39.282055825, 3095.978],
                    [-106.448616036, 39.281737592, 3111.94],
                    [-106.449053693, 39.281768389, 3118.908],
                    [-106.450021844, 39.281747858, 3141.737999999999829],
                    [-106.450207516, 39.281583608, 3151.47400000000016],
                    [-106.450260566, 39.281070324, 3165.491],
                    [-106.450141205, 39.280711024, 3171.353],
                    [-106.450141205, 39.280444113, 3176.071],
                    [-106.450751272, 39.280290126, 3189.286],
                    [-106.451414389, 39.280639163, 3197.646000000000186],
                    [-106.452104031, 39.280669961, 3209.893999999999778],
                    [-106.45221013, 39.280454379, 3209.732],
                    [-106.452104031, 39.278750236, 3240.031],
                    [-106.452820197, 39.277292442, 3250.994999999999891],
                    [-106.454690187, 39.275444491, 3267.784999999999854],
                    [-106.455061533, 39.274551297, 3275.188000000000102],
                    [-106.455817486, 39.273873694, 3281.417],
                    [-106.456958048, 39.273329554, 3282.746],
                    [-106.457727263, 39.273401421, 3281.943000000000211],
                    [-106.458257757, 39.273165284, 3282.136],
                    [-106.45861584, 39.272734074, 3284.186000000000149],
                    [-106.459398318, 39.272210459, 3279.829000000000178],
                    [-106.460021648, 39.27213859, 3282.869000000000142],
                    [-106.460406256, 39.271912715, 3285.907000000000153],
                    [-106.461042849, 39.27052665, 3314.581000000000131],
                    [-106.461440719, 39.269931147, 3330.288],
                    [-106.462103836, 39.269438313, 3356.915],
                    [-106.463403545, 39.268709323, 3390.302999999999884],
                    [-106.464398221, 39.26859638, 3403.927000000000135],
                    [-106.465432683, 39.268606647, 3412.744000000000142],
                    [-106.467236362, 39.268421831, 3424.168999999999869],
                    [-106.468390185, 39.268237015, 3425.424],
                    [-106.468801318, 39.267867381, 3438.409000000000106],
                    [-106.469716419, 39.267918719, 3447.772],
                    [-106.470246913, 39.267692831, 3456.844],
                    [-106.470551947, 39.267189713, 3467.914000000000215],
                    [-106.469769469, 39.266245075, 3487.203],
                    [-106.469544009, 39.266204003, 3489.967999999999847],
                    [-106.469769469, 39.266049985, 3495.302999999999884],
                    [-106.469742944, 39.26587543, 3503.476000000000113],
                    [-106.469345074, 39.265854894, 3508.797999999999774],
                    [-106.46943791, 39.265618731, 3516.938000000000102],
                    [-106.468947203, 39.26557766, 3524.157000000000153],
                    [-106.469106352, 39.265259352, 3529.364999999999782],
                    [-106.468668694, 39.265074527, 3537.168999999999869],
                    [-106.467687281, 39.2653723, 3552.170999999999822],
                    [-106.466453883, 39.265598196, 3553.7199999999998],
                    [-106.466175374, 39.265536588, 3551.335],
                    [-106.465737717, 39.265721411, 3547.706000000000131],
                    [-106.4650746, 39.265721411, 3546.505000000000109],
                    [-106.463403545, 39.265998645, 3544.148999999999887]
                  ],
                camera:[
                    [-106.317786686459044, 39.234949713230243],
                    [-106.426900329364884, 39.301469338207248],
                    [-106.48247640313599, 39.259519058584651]
                  ],
            },
            visible:false,
            description: 'The hike to Skinner Hut is long and hard and should not be taken lightly. There is an alternate route which approaches avalanche zones, so I don\'t care to go near there. Instead I take the long way, with the dreaded losing of 600 vertical feet on a two mile down hill. Skinner Hut was the very first hut that I went to, and it taught me some lessons that I use to this very day: Get in shape for a hut trip, start early, travel light, study maps and have an idea of where you are going as best you can. I learned most of these the hard way, being lost, in a blizzard, in the dark, out of water, trying to find skinner hut. All in all, Skinner Hut will earn your respect, and it\'s worth the challenge once you get there.I think the elevation profile says it all, it\'s long and steep. The good news is that the first 8 miles are all on a road that is well traversed by snow mobiles. I used kick wax on my tele boards to make the climb up easier. While the road is downhill, it\'s not like you are on a ski run, so there is some work involved. Finally you get to a park where you connect with the colorado trail, and you head into the trees into the start of a steep climb to skinner hut. The trail is marked with blue diamonds, and the last push to the saddle was especially hard for our trip because we were breaking trail.',
            location: {
                center: [-106.37098, 39.25705],
                zoom: 13.61,
                pitch: 60.00,
                bearing: -110.40
            },
            onChapterEnter: [{
                layer: 'skinner-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'skinner-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'budtoskinnerhut',
            title: 'Skinner Hut to Uncle Buds',
            image: '/img/huttohut.jpg',
            huttriplink: 'https://www.huttrip.com/2010/02/uncle-buds-hut-to-skinner-hut.html',
            hutorglink: '',
            gpx: 'gpx/skinnertobudhike-data.gpx',
            data: {
                route:[
                    [-106.463390283, 39.266003779, 3543.887000000000171],
                    [-106.464046769, 39.265885698, 3550.272],
                    [-106.464815984, 39.265772751, 3547.382],
                    [-106.465160805, 39.265711143, 3546.387000000000171],
                    [-106.465731086, 39.265711143, 3547.79],
                    [-106.466221793, 39.26552632, 3551.766],
                    [-106.466586507, 39.265587928, 3555.011],
                    [-106.468695219, 39.265074527, 3536.922999999999774],
                    [-106.469086458, 39.265290156, 3528.862],
                    [-106.469020146, 39.265598196, 3522.20699999999988],
                    [-106.469411385, 39.265644401, 3516.44],
                    [-106.469358336, 39.265854894, 3508.581000000000131],
                    [-106.469729682, 39.265931904, 3500.663],
                    [-106.469577165, 39.266214271, 3488.846],
                    [-106.469868936, 39.266255343, 3486.806999999999789],
                    [-106.47051216, 39.267169178, 3467.8449999999998],
                    [-106.470180601, 39.267708232, 3455.563000000000102],
                    [-106.469219081, 39.267939254, 3442.324],
                    [-106.468880892, 39.267877649, 3439.262000000000171],
                    [-106.468357029, 39.268247283, 3425.317],
                    [-106.467216468, 39.268432099, 3424.190999999999804],
                    [-106.466453883, 39.268488571, 3421.871],
                    [-106.465406159, 39.26863745, 3412.859],
                    [-106.464570631, 39.268560443, 3404.676],
                    [-106.463522906, 39.268693921, 3392.192],
                    [-106.461951319, 39.269535853, 3350.059000000000196],
                    [-106.461387669, 39.269972216, 3328.648999999999887],
                    [-106.460930119, 39.270690926, 3310.510000000000218],
                    [-106.460379732, 39.271938383, 3285.627],
                    [-106.459928812, 39.272164257, 3281.947999999999865],
                    [-106.459365162, 39.272261794, 3278.946],
                    [-106.458595947, 39.272734074, 3284.458999999999833],
                    [-106.458184814, 39.273211485, 3282.123999999999796],
                    [-106.457660952, 39.273437355, 3281.402],
                    [-106.456918261, 39.27333982, 3282.838000000000193],
                    [-106.455810855, 39.273904494, 3280.886],
                    [-106.455054902, 39.274566697, 3274.938000000000102],
                    [-106.454776393, 39.275403425, 3267.98],
                    [-106.453483315, 39.276584067, 3257.804],
                    [-106.452800304, 39.277369439, 3250.04300000000012],
                    [-106.452064244, 39.278786167, 3239.896000000000186],
                    [-106.452230023, 39.280444113, 3209.967999999999847],
                    [-106.452163712, 39.280623765, 3209.929],
                    [-106.45192499, 39.280695625, 3208.04],
                    [-106.45153375, 39.280664828, 3200.347000000000207],
                    [-106.451321553, 39.280613499, 3195.701],
                    [-106.45091042, 39.280372253, 3191.04300000000012],
                    [-106.450731379, 39.280310658, 3188.647],
                    [-106.450346771, 39.28036712, 3181.597000000000207],
                    [-106.450161098, 39.28043898, 3176.550000000000182],
                    [-106.450121311, 39.280726422, 3170.523999999999887],
                    [-106.450260566, 39.281157583, 3163.451],
                    [-106.450214148, 39.281634936, 3150.094],
                    [-106.450015212, 39.28178892, 3140.197000000000116],
                    [-106.449013906, 39.281778654, 3118.152],
                    [-106.448748659, 39.281737592, 3114.839],
                    [-106.448602773, 39.281737592, 3111.65],
                    [-106.448317633, 39.281922373, 3100.052000000000135],
                    [-106.448297739, 39.282096887, 3094.978999999999814],
                    [-106.448456887, 39.282173879, 3096.585999999999785],
                    [-106.448549724, 39.282281667, 3095.41800000000012],
                    [-106.448742028, 39.282394588, 3095.286999999999807],
                    [-106.448914438, 39.282451048, 3096.292],
                    [-106.449120005, 39.282605031, 3095.19],
                    [-106.44931894, 39.282830871, 3091.824999999999818],
                    [-106.449577555, 39.282959189, 3091.659999999999854],
                    [-106.449657129, 39.283102905, 3090.998],
                    [-106.449677023, 39.283154232, 3090.425000000000182],
                    [-106.449809646, 39.283220957, 3090.70699999999988],
                    [-106.450207516, 39.283693163, 3086.545],
                    [-106.450200885, 39.283831745, 3085.018999999999778],
                    [-106.449796384, 39.284216693, 3080.670999999999822],
                    [-106.449590818, 39.284144836, 3078.992000000000189],
                    [-106.449444932, 39.284165367, 3076.797999999999774],
                    [-106.449352095, 39.284237223, 3076.688000000000102],
                    [-106.449226103, 39.284283417, 3074.876000000000204],
                    [-106.449013906, 39.284570843, 3071.398999999999887],
                    [-106.44898075, 39.284729953, 3072.842999999999847],
                    [-106.448708872, 39.284760749, 3070.717999999999847],
                    [-106.448403838, 39.284714555, 3066.449],
                    [-106.448125329, 39.284832605, 3067.257],
                    [-106.447933025, 39.284801809, 3064.614],
                    [-106.447594835, 39.28482234, 3062.384],
                    [-106.447468843, 39.284806942, 3061.110999999999876],
                    [-106.447064342, 39.284837737, 3058.266],
                    [-106.446785833, 39.284904461, 3059.577000000000226],
                    [-106.446746046, 39.28500198, 3060.674],
                    [-106.446407856, 39.285268873, 3066.842],
                    [-106.445645271, 39.285463909, 3074.936999999999898],
                    [-106.445074991, 39.285325331, 3078.868],
                    [-106.444511341, 39.284950654, 3080.895],
                    [-106.442223587, 39.284986582, 3102.22400000000016],
                    [-106.44178593, 39.285248342, 3105.955],
                    [-106.441235543, 39.285325331, 3110.842],
                    [-106.441155969, 39.285941233, 3122.487999999999829],
                    [-106.440439803, 39.28655713, 3140.692],
                    [-106.439949096, 39.286736765, 3148.369999999999891],
                    [-106.439537964, 39.287337258, 3165.130999999999858],
                    [-106.439431865, 39.288112245, 3183.292],
                    [-106.43974353, 39.288338068, 3186.465999999999894],
                    [-106.44036686, 39.288415053, 3188.985000000000127],
                    [-106.442216956, 39.289759706, 3200.793999999999869],
                    [-106.442535252, 39.290247265, 3206.139999999999873],
                    [-106.442389367, 39.290899049, 3213.065],
                    [-106.442077702, 39.291047881, 3218.172],
                    [-106.441169231, 39.290509006, 3224.299],
                    [-106.440512746, 39.290390966, 3231.998],
                    [-106.43900747, 39.290899049, 3246.266999999999825],
                    [-106.438490239, 39.291812563, 3259.964],
                    [-106.43838414, 39.29253618, 3277.77],
                    [-106.438974314, 39.293434277, 3300.425000000000182],
                    [-106.439259454, 39.293701138, 3307.679999999999836],
                    [-106.438516763, 39.293849963, 3319.518999999999778],
                    [-106.436122911, 39.293639555, 3341.1],
                    [-106.434723734, 39.293721665, 3361.857],
                    [-106.434425331, 39.293901283, 3369.873999999999796],
                    [-106.434100404, 39.29405524, 3377.012000000000171],
                    [-106.433709165, 39.29392181, 3376.839],
                    [-106.433450549, 39.293906415, 3381.15],
                    [-106.433344451, 39.293998789, 3385.898999999999887],
                    [-106.433384238, 39.294224592, 3391.744000000000142],
                    [-106.433437287, 39.294239988, 3391.16800000000012],
                    [-106.433490336, 39.294511978, 3398.156],
                    [-106.433158778, 39.294455527, 3402.969],
                    [-106.433145515, 39.294624879, 3408.210999999999785],
                    [-106.433251614, 39.29485068, 3413.940999999999804],
                    [-106.432966474, 39.294804494, 3416.94],
                    [-106.432727752, 39.294855812, 3423.092999999999847],
                    [-106.432588497, 39.294819889, 3425.25],
                    [-106.43254871, 39.294886603, 3427.96],
                    [-106.432568604, 39.295081613, 3432.295],
                    [-106.432668071, 39.295266358, 3435.288],
                    [-106.432727752, 39.295348467, 3436.510000000000218],
                    [-106.431686658, 39.295820592, 3462.654],
                    [-106.431268894, 39.295846251, 3470.233000000000175],
                    [-106.431010279, 39.295825724, 3474.145],
                    [-106.430804712, 39.295846251, 3476.739],
                    [-106.430459892, 39.295841119, 3479.041000000000167],
                    [-106.430154858, 39.295769274, 3477.045],
                    [-106.429617733, 39.295738483, 3468.431],
                    [-106.429213232, 39.295630716, 3462.6],
                    [-106.428914829, 39.295358731, 3455.436999999999898],
                    [-106.428570008, 39.295348467, 3448.672999999999774],
                    [-106.427475865, 39.295882173, 3432.728999999999814],
                    [-106.427422816, 39.295445971, 3423.072000000000116],
                    [-106.426063426, 39.295379258, 3401.619000000000142],
                    [-106.425718605, 39.29558453, 3402.889999999999873],
                    [-106.425128431, 39.295723088, 3390.661],
                    [-106.424889709, 39.296205474, 3388.297],
                    [-106.424644355, 39.296369689, 3380.065],
                    [-106.424213329, 39.296426138, 3372.772],
                    [-106.424080706, 39.296523641, 3374.661],
                    [-106.423875139, 39.296518509, 3374.484],
                    [-106.423105924, 39.296882861, 3376.273999999999887],
                    [-106.42257543, 39.297426818, 3379.998],
                    [-106.420997212, 39.298058009, 3385.174],
                    [-106.420579448, 39.298083667, 3384.489999999999782],
                    [-106.420373882, 39.298304326, 3385.964],
                    [-106.420294308, 39.298324852, 3386.715999999999894],
                    [-106.419903069, 39.298997087, 3390.206000000000131],
                    [-106.418934918, 39.299433266, 3395.842],
                    [-106.41747606, 39.299515371, 3385.165],
                    [-106.416381917, 39.300233777, 3391.494000000000142],
                    [-106.416189613, 39.300531401, 3398.427999999999884],
                    [-106.415446922, 39.301054804, 3413.338000000000193],
                    [-106.415566283, 39.301260059, 3417.281],
                    [-106.415254618, 39.301875822, 3422.036],
                    [-106.414962847, 39.302029761, 3422.734],
                    [-106.414518558, 39.302132388, 3426.177000000000135],
                    [-106.414041114, 39.302409478, 3437.949999999999818],
                    [-106.413901859, 39.302245276, 3439.590999999999894],
                    [-106.413815654, 39.302009236, 3438.010000000000218],
                    [-106.413238742, 39.30179372, 3443.954000000000178],
                    [-106.411030563, 39.302686567, 3454.406],
                    [-106.407582354, 39.301803983, 3433.389999999999873],
                    [-106.405480273, 39.30130111, 3466.04300000000012]
                  ],
                camera:[
                    [-106.444066684197622, 39.313056627719384],
                    [-106.451791543872375, 39.282908217044316],
                    [-106.424861824728453, 39.260055507173185],
                    [-106.392353040263885, 39.269926161202029]
                  ],
            },
            visible:false,
            description: 'Grueling!  Ironically, this was my first experience with the hut system, and, even with the experience, I somehow fell in love with hut trips!  This has to be one of the most difficult hut to hut trips you will ever do. We did it by doing Skinner first, spent two nights, then a night of winter camping between the two, and finally a night at Uncle Bud\'s.  Nothing about this was easy!',
            location: {
                center: [-106.38229, 39.27684],
                zoom: 12.49,
                pitch: 32.50,
                bearing: 143.20
            },
            onChapterEnter: [{
                layer: 'bud-to-skinner-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'bud-to-skinner-tracks',
                opacity: 0.25
            }]
        },
        {
            id: 'sisterscabin',
            title: 'Sisters Cabin',
            image: '/img/sisters.jpg',
            huttriplink: 'https://www.huttrip.com/2020/01/sisters-cabin.html',
            hutorglink: '',
            gpx: 'gpx/sisters-data.gpx',
            data: {
                route:[
                    [-105.979153, 39.486156, 3139.164000000000215],
                    [-105.979131, 39.48612, 3138.70699999999988],
                    [-105.979086, 39.486108, 3138.713000000000193],
                    [-105.97904, 39.48611, 3138.933],
                    [-105.979015, 39.486089, 3138.79300000000012],
                    [-105.978898, 39.486088, 3139.804],
                    [-105.978821, 39.486071, 3140.306999999999789],
                    [-105.978659, 39.486058, 3141.561000000000149],
                    [-105.978399, 39.486002, 3143.023000000000138],
                    [-105.978197, 39.485977, 3143.507999999999811],
                    [-105.978, 39.485934, 3143.897],
                    [-105.977959, 39.48593, 3143.95699999999988],
                    [-105.977899, 39.485948, 3143.954000000000178],
                    [-105.977841, 39.485916, 3144.003000000000156],
                    [-105.977762, 39.485905, 3143.987],
                    [-105.977567, 39.485843, 3143.996],
                    [-105.977536, 39.485818, 3144.016],
                    [-105.977441, 39.485688, 3144.114],
                    [-105.977378, 39.48558, 3144.414000000000215],
                    [-105.977365, 39.485471, 3144.739],
                    [-105.977377, 39.485402, 3145.087],
                    [-105.977436, 39.485316, 3146.070000000000164],
                    [-105.977477, 39.485295, 3146.472000000000207],
                    [-105.9776, 39.485271, 3147.389999999999873],
                    [-105.977634, 39.48527, 3147.597000000000207],
                    [-105.977821, 39.485325, 3147.789000000000215],
                    [-105.977943, 39.485328, 3148.183],
                    [-105.978069, 39.485355, 3148.143],
                    [-105.978226, 39.485363, 3148.369999999999891],
                    [-105.978402, 39.485333, 3148.991],
                    [-105.978476, 39.485331, 3149.101999999999862],
                    [-105.97862, 39.485277, 3149.860999999999876],
                    [-105.978791, 39.485241, 3150.262000000000171],
                    [-105.979097, 39.485138, 3150.967],
                    [-105.979203, 39.485087, 3151.525],
                    [-105.979332, 39.485053, 3151.752],
                    [-105.979417, 39.485017, 3152.177000000000135],
                    [-105.979535, 39.484944, 3153.266],
                    [-105.979599, 39.484921, 3153.574999999999818],
                    [-105.979656, 39.484884, 3154.243],
                    [-105.979759, 39.484855, 3154.654],
                    [-105.980027, 39.48471, 3156.610000000000127],
                    [-105.98018, 39.484655, 3157.101999999999862],
                    [-105.980423, 39.484525, 3158.505999999999858],
                    [-105.980707, 39.484407, 3159.402],
                    [-105.980814, 39.484383, 3159.414000000000215],
                    [-105.980874, 39.484348, 3159.692],
                    [-105.981043, 39.484285, 3160.237],
                    [-105.981216, 39.484264, 3160.353],
                    [-105.981281, 39.484241, 3160.713000000000193],
                    [-105.981467, 39.484154, 3162.186000000000149],
                    [-105.981504, 39.484106, 3163.116],
                    [-105.981534, 39.484083, 3163.536999999999807],
                    [-105.981634, 39.484045, 3164.156],
                    [-105.981711, 39.484029, 3164.346],
                    [-105.981812, 39.483986, 3165.197999999999865],
                    [-105.981988, 39.48395, 3165.795],
                    [-105.98209, 39.48394, 3165.869000000000142],
                    [-105.982229, 39.4839, 3166.489],
                    [-105.982373, 39.483894, 3166.286],
                    [-105.982444, 39.483872, 3166.625],
                    [-105.982529, 39.483825, 3167.592999999999847],
                    [-105.982899, 39.483757, 3168.259],
                    [-105.982927, 39.483737, 3168.880999999999858],
                    [-105.983001, 39.483733, 3168.795999999999822],
                    [-105.983076, 39.483701, 3169.804],
                    [-105.983346, 39.483661, 3170.302999999999884],
                    [-105.98339, 39.483664, 3169.994000000000142],
                    [-105.983601, 39.483623, 3170.282000000000153],
                    [-105.98402, 39.483512, 3170.967999999999847],
                    [-105.984155, 39.483499, 3168.864999999999782],
                    [-105.984256, 39.483449, 3168.561999999999898],
                    [-105.984389, 39.48343, 3167.554999999999836],
                    [-105.984457, 39.4834, 3168.074999999999818],
                    [-105.984473, 39.483384, 3168.498999999999796],
                    [-105.984649, 39.483336, 3169.168999999999869],
                    [-105.98467, 39.483314, 3169.846],
                    [-105.984841, 39.483245, 3171.58199999999988],
                    [-105.984986, 39.483125, 3175.601999999999862],
                    [-105.985187, 39.483099, 3175.652],
                    [-105.985523, 39.482972, 3178.175000000000182],
                    [-105.985547, 39.482949, 3178.818000000000211],
                    [-105.985656, 39.482905, 3179.638],
                    [-105.985798, 39.482825, 3182.523999999999887],
                    [-105.985823, 39.482799, 3183.577000000000226],
                    [-105.985936, 39.482725, 3186.324],
                    [-105.986079, 39.482692, 3187.402],
                    [-105.98628, 39.48262, 3190.038],
                    [-105.986407, 39.482552, 3192.35],
                    [-105.986575, 39.482524, 3193.012000000000171],
                    [-105.98672, 39.482522, 3192.652999999999793],
                    [-105.986849, 39.482489, 3193.666000000000167],
                    [-105.986927, 39.482488, 3193.41800000000012],
                    [-105.987109, 39.482454, 3194.31],
                    [-105.987181, 39.482427, 3195.179999999999836],
                    [-105.987338, 39.482338, 3198.415],
                    [-105.987369, 39.482338, 3198.243],
                    [-105.987594, 39.482251, 3199.418999999999869],
                    [-105.987626, 39.482224, 3200.074],
                    [-105.987679, 39.482217, 3199.668999999999869],
                    [-105.987737, 39.482174, 3200.413],
                    [-105.987772, 39.482178, 3199.842999999999847],
                    [-105.987802, 39.482166, 3199.935],
                    [-105.987861, 39.482129, 3200.737],
                    [-105.9879, 39.482083, 3202.253000000000156],
                    [-105.988092, 39.481978, 3202.891999999999825],
                    [-105.988127, 39.481938, 3203.746],
                    [-105.988269, 39.48183, 3205.324],
                    [-105.98836, 39.481774, 3205.79],
                    [-105.988626, 39.48169, 3204.835],
                    [-105.989043, 39.481496, 3209.972000000000207],
                    [-105.989305, 39.481401, 3210.804],
                    [-105.98967, 39.481101, 3216.563999999999851],
                    [-105.989716, 39.481053, 3217.505000000000109],
                    [-105.989828, 39.480966, 3219.063999999999851],
                    [-105.989912, 39.480915, 3219.85],
                    [-105.990205, 39.480775, 3221.435],
                    [-105.99034, 39.480693, 3223.195000000000164],
                    [-105.991041, 39.480392, 3224.621],
                    [-105.99116, 39.480319, 3225.494000000000142],
                    [-105.991248, 39.48028, 3225.447999999999865],
                    [-105.991429, 39.480178, 3225.152999999999793],
                    [-105.99153, 39.480146, 3223.411],
                    [-105.991719, 39.480054, 3223.114999999999782],
                    [-105.991854, 39.479939, 3223.887000000000171],
                    [-105.991872, 39.479907, 3224.426],
                    [-105.991889, 39.479842, 3225.821],
                    [-105.991895, 39.479742, 3228.679],
                    [-105.991908, 39.479697, 3229.821],
                    [-105.992002, 39.479643, 3230.096],
                    [-105.992211, 39.479582, 3229.371],
                    [-105.992452, 39.479463, 3230.59900000000016],
                    [-105.99281, 39.479405, 3229.98],
                    [-105.992947, 39.479418, 3229.647],
                    [-105.993187, 39.479475, 3228.75],
                    [-105.993332, 39.47949, 3228.696],
                    [-105.993441, 39.479482, 3229.04300000000012],
                    [-105.993562, 39.479446, 3229.739],
                    [-105.993632, 39.479403, 3230.501000000000204],
                    [-105.993735, 39.479318, 3231.985999999999876],
                    [-105.993931, 39.479131, 3236.744999999999891],
                    [-105.993942, 39.479109, 3237.297999999999774],
                    [-105.994069, 39.478979, 3239.864999999999782],
                    [-105.994135, 39.478936, 3240.456000000000131],
                    [-105.994348, 39.478753, 3242.898999999999887],
                    [-105.994388, 39.478731, 3243.165],
                    [-105.994436, 39.478676, 3244.266999999999825],
                    [-105.994685, 39.478514, 3245.455],
                    [-105.994782, 39.478472, 3245.710999999999785],
                    [-105.994868, 39.478451, 3246.368],
                    [-105.99509, 39.478439, 3247.576],
                    [-105.99519, 39.478447, 3247.344],
                    [-105.995536, 39.478497, 3248.598],
                    [-105.995767, 39.47852, 3251.896000000000186],
                    [-105.996091, 39.478584, 3252.681999999999789],
                    [-105.996157, 39.478618, 3252.56],
                    [-105.996141, 39.47859, 3253.252],
                    [-105.996183, 39.478476, 3257.59900000000016],
                    [-105.996202, 39.478378, 3258.934000000000196],
                    [-105.996256, 39.478324, 3259.56],
                    [-105.996097, 39.478232, 3260.958999999999833],
                    [-105.996147, 39.478241, 3260.641999999999825],
                    [-105.996129, 39.478224, 3260.956000000000131],
                    [-105.99608, 39.478192, 3261.697999999999865],
                    [-105.995965, 39.478115, 3263.157000000000153],
                    [-105.995891, 39.478024, 3264.516999999999825],
                    [-105.995824, 39.477983, 3265.409000000000106],
                    [-105.995709, 39.477933, 3266.851999999999862],
                    [-105.995663, 39.477873, 3267.972000000000207],
                    [-105.995667, 39.477838, 3268.417],
                    [-105.995707, 39.477787, 3268.766999999999825],
                    [-105.995786, 39.477738, 3268.623],
                    [-105.995812, 39.477686, 3268.956000000000131],
                    [-105.995846, 39.477649, 3268.998999999999796],
                    [-105.995887, 39.477512, 3269.813000000000102],
                    [-105.995911, 39.477322, 3271.110000000000127],
                    [-105.995931, 39.477294, 3270.958],
                    [-105.99589, 39.477208, 3272.656],
                    [-105.995889, 39.477171, 3273.045999999999822],
                    [-105.995915, 39.477105, 3273.025],
                    [-105.995922, 39.477027, 3273.436000000000149],
                    [-105.9959, 39.476964, 3274.498],
                    [-105.995877, 39.476823, 3276.213000000000193],
                    [-105.995893, 39.476795, 3275.9],
                    [-105.995888, 39.476711, 3276.313000000000102],
                    [-105.995823, 39.476619, 3278.527],
                    [-105.99583, 39.476585, 3278.424],
                    [-105.995803, 39.476504, 3279.427999999999884],
                    [-105.995697, 39.47641, 3282.735000000000127],
                    [-105.995659, 39.476401, 3283.521000000000186],
                    [-105.995662, 39.476436, 3283.391999999999825],
                    [-105.995704, 39.476519, 3282.333],
                    [-105.995696, 39.47662, 3282.288],
                    [-105.995643, 39.476711, 3282.938000000000102],
                    [-105.99565, 39.476813, 3282.431999999999789],
                    [-105.995624, 39.476914, 3281.871999999999844],
                    [-105.995559, 39.477027, 3281.83199999999988],
                    [-105.995517, 39.47713, 3281.241],
                    [-105.995396, 39.47724, 3281.364],
                    [-105.995304, 39.47727, 3281.982],
                    [-105.995264, 39.477249, 3282.79],
                    [-105.99527, 39.477198, 3283.52599999999984],
                    [-105.995308, 39.477131, 3284.136],
                    [-105.995304, 39.476996, 3285.858000000000175],
                    [-105.99532, 39.476927, 3286.368],
                    [-105.995304, 39.476857, 3287.321],
                    [-105.995331, 39.476802, 3287.487],
                    [-105.995325, 39.476759, 3287.853999999999814],
                    [-105.995279, 39.476695, 3288.987],
                    [-105.99526, 39.476622, 3289.79300000000012],
                    [-105.995201, 39.47651, 3291.538],
                    [-105.995199, 39.476405, 3292.098],
                    [-105.995242, 39.476307, 3291.800000000000182],
                    [-105.995236, 39.476258, 3292.143999999999778],
                    [-105.995204, 39.476197, 3292.945000000000164],
                    [-105.995172, 39.476098, 3293.888],
                    [-105.995188, 39.476007, 3293.862999999999829],
                    [-105.995138, 39.475849, 3295.283],
                    [-105.995074, 39.475777, 3296.619999999999891],
                    [-105.995054, 39.475719, 3297.108000000000175],
                    [-105.994996, 39.47567, 3298.176],
                    [-105.994954, 39.475574, 3298.821],
                    [-105.994907, 39.47552, 3299.701],
                    [-105.994887, 39.475455, 3300.065999999999804],
                    [-105.994764, 39.475318, 3302.431],
                    [-105.994743, 39.475259, 3302.768],
                    [-105.9947, 39.475186, 3303.648000000000138],
                    [-105.994527, 39.475043, 3307.447999999999865],
                    [-105.994504, 39.474988, 3307.795],
                    [-105.994403, 39.474884, 3309.721],
                    [-105.994365, 39.474812, 3310.344],
                    [-105.994277, 39.474743, 3312.134],
                    [-105.994246, 39.474736, 3312.802000000000135],
                    [-105.994222, 39.47478, 3313.438999999999851],
                    [-105.99419, 39.47478, 3314.145],
                    [-105.994167, 39.474767, 3314.621999999999844],
                    [-105.994184, 39.474812, 3314.353],
                    [-105.994211, 39.474831, 3313.804],
                    [-105.994244, 39.4749, 3313.246999999999844],
                    [-105.994268, 39.474929, 3312.792],
                    [-105.994264, 39.474963, 3312.964],
                    [-105.9943, 39.475081, 3312.475],
                    [-105.994296, 39.475128, 3312.679],
                    [-105.994308, 39.475175, 3312.447000000000116],
                    [-105.994396, 39.475333, 3310.569],
                    [-105.994427, 39.475465, 3309.859],
                    [-105.994422, 39.475505, 3309.913],
                    [-105.994397, 39.475555, 3310.375],
                    [-105.994392, 39.475634, 3310.375],
                    [-105.99434, 39.475727, 3311.286999999999807],
                    [-105.99426, 39.475709, 3313.04300000000012],
                    [-105.994239, 39.475683, 3313.554],
                    [-105.994167, 39.475641, 3315.17],
                    [-105.994078, 39.475548, 3317.226000000000113],
                    [-105.99406, 39.475386, 3317.804],
                    [-105.994019, 39.475256, 3318.690999999999804],
                    [-105.993944, 39.475143, 3320.339],
                    [-105.993872, 39.474938, 3321.527999999999793],
                    [-105.993758, 39.474832, 3323.859],
                    [-105.99374, 39.4748, 3324.192],
                    [-105.993729, 39.47473, 3324.282000000000153],
                    [-105.993654, 39.474591, 3325.592999999999847],
                    [-105.993653, 39.47457, 3325.603],
                    [-105.993613, 39.474517, 3326.484],
                    [-105.993546, 39.474473, 3327.909000000000106],
                    [-105.993534, 39.474443, 3328.159999999999854],
                    [-105.993472, 39.474357, 3329.424],
                    [-105.993464, 39.474284, 3329.697000000000116],
                    [-105.993476, 39.474251, 3329.618],
                    [-105.993436, 39.474133, 3331.051],
                    [-105.993439, 39.474066, 3331.322000000000116],
                    [-105.993483, 39.474007, 3330.772],
                    [-105.993477, 39.473974, 3331.052000000000135],
                    [-105.993488, 39.473936, 3331.002],
                    [-105.993468, 39.473909, 3331.533],
                    [-105.993448, 39.473783, 3332.489999999999782],
                    [-105.993403, 39.473745, 3333.498],
                    [-105.993387, 39.473712, 3333.976000000000113],
                    [-105.993424, 39.473625, 3333.733000000000175],
                    [-105.99341, 39.473594, 3334.172999999999774],
                    [-105.993412, 39.473503, 3334.616],
                    [-105.993446, 39.473443, 3334.242000000000189],
                    [-105.993502, 39.473306, 3333.862999999999829],
                    [-105.993438, 39.473183, 3336.083],
                    [-105.993503, 39.473054, 3335.496],
                    [-105.993486, 39.472988, 3336.362999999999829],
                    [-105.993447, 39.472937, 3337.643999999999778],
                    [-105.993435, 39.472866, 3338.481000000000222],
                    [-105.993446, 39.472828, 3338.536],
                    [-105.993383, 39.472786, 3340.309000000000196],
                    [-105.993331, 39.472727, 3341.936000000000149],
                    [-105.99343, 39.47274, 3339.612],
                    [-105.993331, 39.47264, 3342.592999999999847],
                    [-105.993322, 39.472576, 3343.6],
                    [-105.993332, 39.47252, 3344.098],
                    [-105.993314, 39.472441, 3345.489],
                    [-105.99327, 39.472384, 3347.136],
                    [-105.993259, 39.47234, 3347.925000000000182],
                    [-105.993261, 39.472281, 3348.647],
                    [-105.993279, 39.472216, 3349.126000000000204],
                    [-105.993259, 39.472149, 3350.396000000000186],
                    [-105.99327, 39.472113, 3350.646000000000186],
                    [-105.993296, 39.47209, 3350.438000000000102],
                    [-105.993311, 39.472022, 3350.842],
                    [-105.993296, 39.47197, 3351.635000000000218],
                    [-105.993251, 39.471942, 3352.735000000000127],
                    [-105.993218, 39.471936, 3353.395],
                    [-105.993176, 39.472033, 3353.306999999999789],
                    [-105.993125, 39.472082, 3353.862],
                    [-105.993081, 39.472083, 3354.708],
                    [-105.99299, 39.472153, 3355.744000000000142],
                    [-105.99293, 39.472277, 3355.732],
                    [-105.99287, 39.472309, 3356.577000000000226],
                    [-105.992828, 39.472443, 3355.973],
                    [-105.992786, 39.472508, 3356.103],
                    [-105.992726, 39.47254, 3356.97400000000016],
                    [-105.992676, 39.472642, 3356.998],
                    [-105.992645, 39.472656, 3357.525],
                    [-105.992568, 39.472739, 3358.373],
                    [-105.992537, 39.472749, 3358.917],
                    [-105.992522, 39.472781, 3358.949999999999818],
                    [-105.992514, 39.472842, 3358.596],
                    [-105.992475, 39.472872, 3359.139000000000124],
                    [-105.992436, 39.472922, 3359.5300000000002],
                    [-105.992399, 39.472937, 3360.197000000000116],
                    [-105.992368, 39.472936, 3360.840000000000146],
                    [-105.992312, 39.472984, 3361.705],
                    [-105.992302, 39.473018, 3361.699],
                    [-105.992311, 39.473063, 3361.232],
                    [-105.992356, 39.473049, 3360.395],
                    [-105.992315, 39.473096, 3360.944],
                    [-105.992284, 39.4731, 3361.551],
                    [-105.992252, 39.473085, 3362.300000000000182],
                    [-105.992182, 39.473104, 3363.606000000000222],
                    [-105.99217, 39.473135, 3363.646000000000186],
                    [-105.992156, 39.473268, 3363.235999999999876],
                    [-105.992059, 39.473343, 3365.098],
                    [-105.991995, 39.473369, 3366.648999999999887],
                    [-105.991964, 39.473436, 3367.134],
                    [-105.991894, 39.473512, 3368.708],
                    [-105.991877, 39.473567, 3369.141999999999825],
                    [-105.991871, 39.473729, 3369.322999999999865],
                    [-105.991853, 39.473757, 3369.806999999999789],
                    [-105.991818, 39.473776, 3370.755999999999858],
                    [-105.991809, 39.473813, 3371.092999999999847],
                    [-105.991809, 39.473919, 3371.398000000000138],
                    [-105.991756, 39.474001, 3372.909000000000106],
                    [-105.991744, 39.474075, 3373.420999999999822],
                    [-105.991722, 39.474118, 3374.081000000000131],
                    [-105.991728, 39.47422, 3374.277],
                    [-105.991646, 39.474347, 3376.543999999999869],
                    [-105.991597, 39.474354, 3377.674],
                    [-105.99156, 39.474333, 3378.449],
                    [-105.991534, 39.4743, 3378.945000000000164],
                    [-105.991445, 39.47413, 3380.699999999999818],
                    [-105.99138, 39.474077, 3382.21],
                    [-105.991369, 39.474038, 3382.418999999999869],
                    [-105.991389, 39.473974, 3381.887000000000171],
                    [-105.991344, 39.473874, 3383.047999999999774],
                    [-105.991343, 39.4738, 3383.083],
                    [-105.99138, 39.473735, 3382.110000000000127],
                    [-105.99138, 39.473668, 3382.197999999999865],
                    [-105.991396, 39.473602, 3381.838000000000193],
                    [-105.991431, 39.473552, 3380.908],
                    [-105.991379, 39.473544, 3382.389999999999873],
                    [-105.991393, 39.473436, 3382.369999999999891],
                    [-105.991387, 39.473406, 3382.777999999999793],
                    [-105.991396, 39.473313, 3383.264999999999873],
                    [-105.991462, 39.473237, 3382.090999999999894],
                    [-105.99146, 39.473205, 3382.416000000000167],
                    [-105.991472, 39.473175, 3382.389999999999873],
                    [-105.991452, 39.473074, 3383.972000000000207],
                    [-105.991484, 39.472915, 3384.820000000000164],
                    [-105.991462, 39.472875, 3385.782000000000153],
                    [-105.991475, 39.472837, 3385.8449999999998],
                    [-105.991467, 39.472671, 3387.737999999999829],
                    [-105.991512, 39.472614, 3387.179],
                    [-105.991513, 39.472557, 3387.610000000000127],
                    [-105.991489, 39.472493, 3388.713000000000193],
                    [-105.991481, 39.472376, 3389.858000000000175],
                    [-105.991486, 39.47233, 3390.085],
                    [-105.991509, 39.472304, 3389.690999999999804],
                    [-105.991526, 39.472201, 3389.998999999999796],
                    [-105.991518, 39.472172, 3390.42],
                    [-105.991469, 39.472121, 3392.101000000000113],
                    [-105.991524, 39.471993, 3391.585999999999785],
                    [-105.991536, 39.471936, 3391.7199999999998],
                    [-105.991506, 39.471842, 3393.226000000000113],
                    [-105.991469, 39.47184, 3394.264000000000124],
                    [-105.991445, 39.47186, 3394.771000000000186],
                    [-105.991447, 39.471925, 3394.210999999999785],
                    [-105.991389, 39.472014, 3395.096],
                    [-105.991397, 39.472049, 3394.601999999999862],
                    [-105.991383, 39.472046, 3395.005000000000109],
                    [-105.991307, 39.472124, 3396.409999999999854],
                    [-105.991257, 39.472157, 3397.451],
                    [-105.991164, 39.472299, 3398.630000000000109],
                    [-105.99108, 39.47237, 3400.119000000000142],
                    [-105.991058, 39.472401, 3400.378000000000156],
                    [-105.991007, 39.472428, 3401.411999999999807],
                    [-105.990977, 39.472469, 3401.752],
                    [-105.990947, 39.472527, 3402.04],
                    [-105.990946, 39.472586, 3401.455],
                    [-105.990899, 39.47269, 3401.795],
                    [-105.990861, 39.472725, 3402.542],
                    [-105.990749, 39.472885, 3403.826],
                    [-105.990667, 39.472951, 3405.84900000000016],
                    [-105.990668, 39.472977, 3405.588000000000193],
                    [-105.990639, 39.473062, 3406.181],
                    [-105.990219, 39.473838, 3417.117000000000189],
                    [-105.99011, 39.473983, 3419.902],
                    [-105.990053, 39.474024, 3421.176],
                    [-105.990024, 39.47407, 3421.592999999999847],
                    [-105.990007, 39.474075, 3422.027999999999793],
                    [-105.989897, 39.474238, 3423.574999999999818],
                    [-105.989877, 39.47429, 3423.621],
                    [-105.989852, 39.474308, 3424.114999999999782],
                    [-105.989821, 39.474355, 3424.445000000000164],
                    [-105.989777, 39.474451, 3424.581000000000131],
                    [-105.989762, 39.474515, 3424.286],
                    [-105.989697, 39.474584, 3425.168999999999869],
                    [-105.989479, 39.474709, 3428.777],
                    [-105.989382, 39.474795, 3429.73],
                    [-105.989242, 39.474885, 3431.315],
                    [-105.989166, 39.47496, 3431.629],
                    [-105.989151, 39.474991, 3431.417],
                    [-105.98902, 39.475101, 3431.969],
                    [-105.98891, 39.475214, 3431.618],
                    [-105.988818, 39.475234, 3432.568000000000211],
                    [-105.988755, 39.475272, 3432.783],
                    [-105.988706, 39.475264, 3433.378000000000156],
                    [-105.988662, 39.475243, 3434.148000000000138],
                    [-105.988661, 39.475215, 3434.67],
                    [-105.988704, 39.475159, 3435.284999999999854],
                    [-105.988776, 39.475129, 3435.013],
                    [-105.988741, 39.475128, 3435.494999999999891],
                    [-105.988973, 39.47478, 3438.277],
                    [-105.989046, 39.474712, 3438.138],
                    [-105.989079, 39.474649, 3438.485999999999876],
                    [-105.989083, 39.474615, 3438.963000000000193],
                    [-105.989129, 39.474525, 3439.286999999999807],
                    [-105.989168, 39.474374, 3440.483000000000175],
                    [-105.989165, 39.474256, 3442.141],
                    [-105.989209, 39.474165, 3442.221],
                    [-105.989234, 39.474135, 3441.952000000000226],
                    [-105.989238, 39.4741, 3442.273000000000138],
                    [-105.989269, 39.474047, 3442.076],
                    [-105.989298, 39.473958, 3442.389000000000124],
                    [-105.989286, 39.473856, 3444.016999999999825],
                    [-105.98924, 39.473726, 3446.976000000000113],
                    [-105.989228, 39.473661, 3448.092],
                    [-105.989106, 39.473955, 3447.752],
                    [-105.989068, 39.474155, 3445.949],
                    [-105.988991, 39.474313, 3445.357],
                    [-105.988953, 39.474365, 3445.348],
                    [-105.98886, 39.474429, 3446.157000000000153],
                    [-105.98869, 39.474469, 3448.299],
                    [-105.988621, 39.474474, 3449.054],
                    [-105.988513, 39.474508, 3449.686000000000149],
                    [-105.988263, 39.474523, 3450.77],
                    [-105.988195, 39.474513, 3451.29300000000012],
                    [-105.987975, 39.474439, 3452.907000000000153],
                    [-105.987821, 39.474401, 3453.420999999999822],
                    [-105.987761, 39.474371, 3453.960999999999785],
                    [-105.987629, 39.474361, 3453.665],
                    [-105.987529, 39.474342, 3453.639000000000124],
                    [-105.987433, 39.474302, 3454.304999999999836],
                    [-105.987219, 39.47425, 3455.321],
                    [-105.987036, 39.474255, 3454.683],
                    [-105.986943, 39.474225, 3455.427000000000135],
                    [-105.986739, 39.474248, 3454.034999999999854],
                    [-105.986671, 39.474232, 3454.183],
                    [-105.986455, 39.474228, 3453.137000000000171],
                    [-105.986311, 39.474206, 3452.884],
                    [-105.986185, 39.474162, 3453.248],
                    [-105.986045, 39.474133, 3452.934000000000196],
                    [-105.985866, 39.474118, 3451.659999999999854],
                    [-105.985692, 39.47407, 3451.239],
                    [-105.985487, 39.474006, 3450.569],
                    [-105.985281, 39.473915, 3450.300000000000182],
                    [-105.985144, 39.47388, 3449.251000000000204],
                    [-105.985072, 39.47384, 3449.292],
                    [-105.984945, 39.473798, 3448.632999999999811],
                    [-105.984837, 39.473801, 3447.114],
                    [-105.982899, 39.472624, 3477.342999999999847],
                    [-105.982664, 39.472562, 3475.993],
                    [-105.982518, 39.472499, 3476.417],
                    [-105.982448, 39.472481, 3475.954000000000178],
                    [-105.982419, 39.47246, 3476.552000000000135],
                    [-105.982357, 39.472462, 3475.217999999999847],
                    [-105.982189, 39.472385, 3479.681999999999789],
                    [-105.982167, 39.472367, 3480.697000000000116],
                    [-105.982124, 39.47239, 3479.391999999999825],
                    [-105.981466, 39.472515, 3475.139999999999873],
                    [-105.981274, 39.47251, 3475.648999999999887],
                    [-105.981236, 39.472498, 3476.342],
                    [-105.981126, 39.4725, 3476.049],
                    [-105.981091, 39.472489, 3476.647],
                    [-105.980936, 39.472498, 3475.760000000000218],
                    [-105.980773, 39.472491, 3475.471],
                    [-105.980682, 39.472468, 3476.507999999999811],
                    [-105.980523, 39.472473, 3475.147],
                    [-105.980382, 39.472437, 3476.388],
                    [-105.980304, 39.472445, 3474.987],
                    [-105.980118, 39.472432, 3473.804999999999836],
                    [-105.979983, 39.472405, 3474.373999999999796],
                    [-105.979692, 39.472384, 3475.597000000000207],
                    [-105.979621, 39.472368, 3476.458999999999833],
                    [-105.979555, 39.472391, 3477.302000000000135],
                    [-105.979454, 39.472476, 3479.271000000000186],
                    [-105.979384, 39.472505, 3481.054999999999836],
                    [-105.979316, 39.472571, 3481.958999999999833],
                    [-105.97927, 39.47259, 3482.993],
                    [-105.979177, 39.472692, 3483.601000000000113],
                    [-105.979116, 39.472726, 3484.58199999999988],
                    [-105.979042, 39.472734, 3486.523999999999887],
                    [-105.978849, 39.472885, 3487.397],
                    [-105.978098, 39.473651, 3492.0],
                    [-105.97812, 39.47364, 3494.83199999999988],
                    [-105.978226, 39.473643, 3493.998],
                    [-105.978129, 39.47363, 3494.728999999999814],
                    [-105.978115, 39.473611, 3494.786],
                    [-105.978208, 39.473667, 3494.311000000000149],
                    [-105.97816, 39.473657, 3494.605],
                    [-105.978131, 39.473673, 3494.862],
                    [-105.978181, 39.473709, 3494.732],
                    [-105.978179, 39.473687, 3494.628000000000156],
                    [-105.97813, 39.473672, 3494.864999999999782]
                  ],
                camera:[
                    [-105.947932207100948, 39.519562511871762],
                    [-106.000504168776317, 39.493437465610633],
                    [-105.983230524225846, 39.486356344242111]
                  ],
            },
            visible:false,
            description: 'The hike is relatively painless:  a 3.7 mile hike that has about a 1,200 vertical feet elevation gain.  There is  fine skiing around the hut.  You will have to climb about 600 vertical feet to get around tree line, and, while the top was wind blown, there was some great open powder runs that bleed into the woods. From there you have great panoramic views from Breckenridge to Keystone',
            location: {
                center: [-105.98111, 39.47785],
                zoom: 14.97,
                pitch: 60.00,
                bearing: 177.70
            },
            onChapterEnter: [{
                layer: 'sisters-tracks',
                opacity: 1.00
            }],
            onChapterExit: [{
                layer: 'sisters-tracks',
                opacity: 0.25
            }]
        }


        

        
    ]
};

export default config;