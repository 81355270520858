import React, { Component } from 'react';
import { Button,ButtonToolbar } from 'react-bootstrap';
import './App.css';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import scrollama from 'scrollama';
import {explode,distance,length,lineString,along} from '@turf/turf';
import {gpx} from '@mapbox/togeojson';
import Chart from 'chart.js'
import 'bootstrap/dist/css/bootstrap.min.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const chartRefs = [];
const layerTypes = {
    'fill': ['fill-opacity'],
    'line': ['line-opacity'],
    'circle': ['circle-opacity', 'circle-stroke-opacity'],
    'symbol': ['icon-opacity', 'text-opacity'],
    'raster': ['raster-opacity'],
    'fill-extrusion': ['fill-extrusion-opacity']
}

const alignments = {
    'left': 'lefty',
    'center': 'centered',
    'right': 'righty'
}

const transformRequest = (url) => {
    const hasQuery = url.indexOf("?") !== -1;	  
    const suffix = hasQuery ? "&pluginName=journalismScrollytelling" : "?pluginName=journalismScrollytelling";	  
    return {
      url: url + suffix
    }	  
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentChapter: props.chapters[0],
            gpx:null,
            point: {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'Point',
                            'coordinates': []
                        }
                    }
                ]
            }
        };
    }

    componentDidMount() {
        const config = this.props;
        const mapStart = config.chapters[0].location;

        mapboxgl.accessToken = config.accessToken;

        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.props.style,
            center: this.props.chapters[0].location.center,
            zoom: config.chapters[0].location.zoom,
            pitch: config.chapters[0].location.pitch,
            bearing: config.chapters[0].location.bearing,
            transformRequest: transformRequest
        });

        this.map = map;

        const point = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [0,0]
                    }
                }
            ]
        };
        this.point = point;

        const marker = new mapboxgl.Marker();
        if (config.showMarkers) {
            marker.setLngLat(mapStart.center).addTo(this.map);
        }

        function getLayerPaintType(layer) {
            var layerType = map.getLayer(layer).type;
            return layerTypes[layerType];
        }

        function setLayerOpacity(layer) {
            var paintProps = getLayerPaintType(layer.layer);
            paintProps.forEach(function(prop) {
                map.setPaintProperty(layer.layer, prop, layer.opacity);
            });
        }

        const setState = this.setState.bind(this);

        // instantiate the scrollama
        const scroller = scrollama();

        this.map.on('load', function () {
            map.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
                });
                // add the DEM source as a terrain layer with exaggerated height
                map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

                // add a sky layer that will show when the map is highly pitched
                map.addLayer({
                    'id': 'sky',
                    'type': 'sky',
                    'paint': {
                        // set up the sky layer to use a color gradient
                        'sky-type': 'gradient',
                        // the sky will be lightest in the center and get darker moving radially outward
                        // this simulates the look of the sun just below the horizon
                        'sky-gradient': [
                            'interpolate',
                            ['linear'],
                            ['sky-radial-progress'],
                            0.8,
                            'rgba(73, 118, 174, 1.0)',
                            1,
                            'rgba(0,0,0,0.1)'
                        ],
                        'sky-gradient-center': [0, 0],
                        'sky-gradient-radius': 90,
                        'sky-opacity': [
                            'interpolate',
                            ['exponential', 0.1],
                            ['zoom'],
                            5,
                            0,
                            22,
                            1
                        ]
                    }
                    });

            // setup the instance, pass callback functions
            scroller
            .setup({
                step: '.step',
                offset: 0.5,
                progress: true
            })
            .onStepEnter(response => {
                const chapter= config.chapters.find(chap => chap.id === response.element.id);
                chapter.visible = true;
                setState({currentChapter:chapter});
                map.flyTo(chapter.location);
                if (config.showMarkers) {
                    marker.setLngLat(chapter.location.center);
                }
                if (chapter.onChapterEnter.length > 0) {
                    chapter.onChapterEnter.forEach(setLayerOpacity);
                }
                lineFollower(chapter.data,chapter.location);
            })
            .onStepExit(response => {
                var chapter = config.chapters.find(chap => chap.id === response.element.id);
                chapter.visible = false;
                setState({currentChapter:chapter});
                if (chapter.onChapterExit.length > 0) {
                    chapter.onChapterExit.forEach(setLayerOpacity);
                }
            });

            // add function for camera to follow line

            function lineFollower(data,datalocation) {
                           // const parser = new DOMParser();
                           // const kmlData = parser.parseFromString(data, "application/xml");
                           // const route = explode(gpx(kmlData)).features;
                           //the route is not in config
                           
                            var routelinestring = lineString(data.route);
                            var cameralinestring = lineString(data.camera);

                            var animationDuration = 80000;
                            var cameraAltitude = 6000;
                            // get the overall distance of each route so we can interpolate along them
                            var routeDistance = length(routelinestring);
                            var cameraRouteDistance = length(cameralinestring);
                            
                            var start;

                            var targetRoute = routelinestring;
                            // this is the path the camera will move along
                            var cameraRoute = cameralinestring;

                            function frame(time) {
                                if (!start) start = time;
                                // phase determines how far through the animation we are
                                var phase = (time - start) / animationDuration;

                                // phase is normalized between 0 and 1
                                // when the animation is finished, reset start to loop the animation
                                if (time >= animationDuration) {
                                    // wait 1.5 seconds before looping
                                    //setTimeout(function () {
                                   //     map.flyTo(datalocation)
                                   // }, 1500);
                                }

                                // use the phase to get a point that is the appropriate distance along the route
                                // this approach syncs the camera and route positions ensuring they move
                                // at roughly equal rates even if they don't contain the same number of points
                                var alongRoute = along(
                                    targetRoute,
                                    routeDistance * phase
                                ).geometry.coordinates;

                                const routepoint = {
                                    'type': 'FeatureCollection',
                                    'features': [
                                        {
                                            'type': 'Feature',
                                            'properties': {},
                                            'geometry': {
                                                'type': 'Point',
                                                'coordinates': [0,0]
                                            }
                                        }
                                    ]
                                };

                                routepoint.features[0].geometry.coordinates = alongRoute
                                map.getSource('point').setData(routepoint); 

                                var alongCamera = along(
                                    cameraRoute,
                                    cameraRouteDistance * phase
                                ).geometry.coordinates;

                                var camera = map.getFreeCameraOptions();

                                // set the position and altitude of the camera
                                camera.position = mapboxgl.MercatorCoordinate.fromLngLat({
                                        lng: alongCamera[0],
                                        lat: alongCamera[1]
                                    },
                                    cameraAltitude,
                                );

                                // tell the camera to look at a point along the route
                                camera.lookAtPoint({
                                    lng: alongRoute[0],
                                    lat: alongRoute[1]
                                });

                                map.setFreeCameraOptions(camera);

                                window.requestAnimationFrame(frame);
                            }

                            window.requestAnimationFrame(frame);
            }

            // wait for the terrain and sky to load before starting animations
            
            map.addSource('point', {
                'type': 'geojson',
                'data': point
            });

            map.addLayer({
                'id': 'point',
                'source': 'point',
                'type': 'circle',
                'paint': {
                'circle-color': '#FF0000',
                'circle-radius': 10
                }
            });    
        });

        window.addEventListener('resize', scroller.resize);
    }

    callbackFunction = (childData) => {
        //console.log(this.props.chapters.indexOf(this.state.currentChapter));
        //console.log(chartRefs[0]);
        this.point.features[0].geometry.coordinates = childData;
        if(this.map.loaded()){
            this.map.getSource('point').setData(this.point); 
            // this.map.easeTo({
            //     center: this.point.features[0].geometry.coordinates,
            //     pitch:60,
            //     essential: true // this animation is considered essential with respect to prefers-reduced-motion
            //     });
        }
           
    }

    render() {
        const config = this.props;
        const theme = config.theme;
        
        const currentChapterID = this.state.currentChapter.id;
        return (
            <div>
                <div ref={el => this.mapContainer = el} className="absolute top right left bottom" />
                
                <div id="story">
                    {config.title &&
                        <div id="header" className={theme}>
                            <h1>{config.title}</h1>
                            {config.subtitle &&
                                <h2>{config.subtitle}</h2>
                            }
                            {config.byline &&
                                <p>{config.byline}</p>
                            }
                        </div>
                    }
                    <div id="features" className={alignments[config.alignment]}>
                        {
                            config.chapters.map((chapter,index) => {
                                chartRefs[this.state.currentChapter] = React.createRef(); //reference={chartRefs[index]}
                                return(<Chapter key={chapter.id} theme={theme} {...chapter} currentChapterID={currentChapterID} parentCallback = {this.callbackFunction} chartRef={chartRefs[this.state.currentChapter]} />);
                            }
                                
                            )
                        }
                    </div>
                
                    {config.footer &&
                        <div id="footer" className={theme}>
                            <p>{config.footer}</p>
                        </div>
                    }
                </div>
            </div>
        );
    }

}

function Chapter({id, theme, title, image, description, huttriplink, hutorglink, gpx,visible, currentChapterID,parentCallback}) {
    const classList = id === currentChapterID ? "step active" : "step";
    
    return (
        <div id={id} className={classList}>
            <div className={theme}>
                { title &&
                    <h3 className="title">{title}</h3>
                }
                { image &&
                    <img src={image} alt={title}></img>
                }
                {gpx && visible &&
                    <ElevationProfile file={gpx} parentCallback = {parentCallback} />
                }
                {description && 
                    <div dangerouslySetInnerHTML={{
                        __html: description
                    }} />    
                }
                <div className="hutbuttons">
                    <ButtonToolbar className="hutlinks">
                    {huttriplink && 
                        <Button variant="outline-primary" href={huttriplink}>Huttrip.com Data</Button>
                    }
                    {hutorglink && 
                        <Button variant="outline-primary" href={hutorglink}>Tenth Mountain Hut Page</Button>
                    }
                    </ButtonToolbar>
                </div>
            </div>
        </div>
    )
}

class ElevationProfile extends Component {
   // const myLineChart;
   _isMounted = false;
    constructor(props) {
      super(props);
      this.state = {
        gpx: null,
        isLoading: true,
        elevationData: [],
        graphData:[],
        graphLabels:[],
        graphDistance:[],
        totalClimb: 0,
        totalDescent:0,
        totalDistance:0
      };
      
    }
    componentDidMount() {
        this._isMounted = true;
      if(this.props.file !== ''){
       fetch(this.props.file)
           .then(response => response.text())
           .then(data => {
            const parser = new DOMParser();
            const kmlData = parser.parseFromString(data, "application/xml");
            const elevationData = explode(gpx(kmlData)).features;
            if (this._isMounted) {
               this.setState({ gpx: data, elevationData:elevationData, isLoading: false})
            }
        });
     }
      
    }

    componentWillUnmount() {
        this._isMounted = false;
      }

    callbackFunction = (childData) => {
        this.props.parentCallback([this.state.elevationData[childData].geometry.coordinates[0],this.state.elevationData[childData].geometry.coordinates[1]]);
    }

    render() {
        //const parser = new DOMParser();
       // const kmlData = parser.parseFromString(this.state.gpx, "application/xml");
       // const elevationData = explode(kml(kmlData)).features;
        const graphData = this.state.elevationData.map((geometry) => geometry.geometry.coordinates[2]);
        const graphDistance = this.state.elevationData.map((geometry,index,elements) => {
            let fromPoint = [0,0];
            let toPoint = [0,0];
            if(geometry && elements[index+1]){
                fromPoint = [geometry.geometry.coordinates[0],geometry.geometry.coordinates[1]];
                toPoint = [elements[index+1].geometry.coordinates[0],elements[index+1].geometry.coordinates[1]];
            }  
            return distance(fromPoint, toPoint, {units: 'miles'});
        });

        const totalClimb = graphData.reduce((total, currentValue, currentIndex, arr) => {
            
           if(!isNaN(arr[currentIndex+1]) && currentValue < arr[currentIndex+1]){
            return total + (arr[currentIndex+1] - currentValue);
           } else {
               return total;
           }
        }, 0);

        const totalDescent = graphData.reduce((total, currentValue, currentIndex, arr) => {
            
            if(!isNaN(arr[currentIndex+1]) && currentValue > arr[currentIndex+1]){
             return total + (arr[currentIndex+1] - currentValue);
            } else {
                return total;
            }
         }, 0);

        const totalDistance = graphDistance.reduce((a,b) => a + b, 0);

        const graphLabels = this.state.elevationData.map((geometry) => geometry.properties.name);
   
           return(
            <div className="hutdata">
            <div className="profileitem">
                <div className="profiledata">{totalDistance.toFixed(2)}</div>
                <div className="profiletext">Distance</div>
            </div>
            <div className="profileitem">
                <div className="profiledata">{(totalClimb*3.3).toFixed(2)}</div>
                <div className="profiletext">Uphill</div>
            </div>
            <div className="profileitem">
                <div className="profiledata">{(totalDescent*3.3).toFixed(2)}</div>
                <div className="profiletext">Downhill</div>
            </div>
            <LineGraph data={graphData} labels={graphLabels} chptid={this.props.chptid} parentCallback = {this.callbackFunction} refid={this.props.chartRef}/>
            </div>
            );
        //}
        
    }
  }

  class LineGraph extends Component {
    chartRef;
    constructor(props) {
        super(props);
        //this.chartRef = this.props.refid;

       this.chartRef = React.createRef();
    }
    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.buildChart();
    }

    buildChart = () => {
        let myLineChart;
        //this.chartRef = this.props.refid;
        const myChartRef = this.chartRef.current.getContext("2d");
        const { data, labels, parentCallback} = this.props;

        if (typeof myLineChart !== "undefined") myLineChart.destroy();

        myLineChart = new Chart(myChartRef, {
            type: "line",
            data: {
                //Bring in data
                labels: labels,
                datasets: [
                    {
                        label: "Elevation",
                        data: data,
                        fill: true,
                        borderColor: "#6610f2",
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "rgba(255,0,0,1)"
                    }
                ]
            },
            options:{
                scales:{
                    xAxes: [{
                        display: false //this will remove all the x-axis grid lines
                    }]
                },
                maintainAspectRatio: false,
                spanGaps:true,
                tooltips: false,
                events: ['mousemove'],
                hover: { 
                    mode: 'index',
                    intersect: false,
                    onHover: function(evt, item) { 
                      if (item.length) {
                        parentCallback(item[0]._index);
                    }
                    
                    }
                },
            }
        });

    }

    render() {
        return (
            <div className="elevationdata">
                <canvas
                    ref={this.chartRef}
                />
            </div>
        )
    }
}

export default App;
